import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef
} from 'react';
import './style.scss';
import '../../../utils/md-toc.js'
import ReactMarkdown from 'react-markdown/with-html';
// import { Modal } from 'antd'
// import { CloseCircleOutlined } from '@ant-design/icons'
import matter from 'gray-matter';
// import Carousel, { Modal, ModalGateway } from 'react-images';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Collapse, BackTop, message, Input, Button, Modal, Rate, Form, Radio, Upload } from 'antd';
import { SectionToChinese ,reportOnLineData } from '../../../utils';
import { setVisitHistory, getCourseItem, courseGrading, courseFeedback } from '../../../utils/request';
// import { useGetHotCourse } from '../index';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight as codeStyle } from 'react-syntax-highlighter/dist/cjs/styles/prism';
// import CourseComment from '../../../components/courseComment';
import Viewer from 'viewerjs';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import moment from 'moment'
// import { marked } from 'marked';

const DPlayer = window.DPlayer;

export const CodeBlock = ({ language, value }) =>{
  return (
    language === 'mermaid' ? (
      <div className="mermaid">{value}</div>
    ) : (
      <SyntaxHighlighter language={language} style={codeStyle}>
        {value}
      </SyntaxHighlighter>
    ))
}

const CourseItem = () => {
  const { TextArea } = Input;
  const { Dragger } = Upload;
  const [scoreForm] = Form.useForm();
  const [feedbackForm] = Form.useForm();
  const userInfo = useSelector((state) => state.userInfo);
  // const isTeacher = userInfo?.user_type === 'teacher';
  const isTeacher = useSelector(state => state.isTeacher);
  const match = useRouteMatch();
  const history = useHistory();
  const { team_data } = useSelector(state => state.userInfo);
  // const [courses] = useGetCourse()
  // const [hotCourses] = useGetHotCourse();
  const [markdown, setMarkdown] = useState();
  // const [showImg, setShowImg] = useState(false)
  // const [imgSrc, setImgSrc] = useState('')
  const [defaultActiveKey, setDefaultActiveKey] = useState(0);
  const [warning, setWarning] = useState(false);
  // const [ topCourse, SetTopCourse ] = useState([])
  const [sectionCourse, setSectionCourse] = useState();
  const [aids, setAids] = useState([]);
  const [aids_2, setAids_2] = useState([]);
  const [studentVal, setStudentVal] = useState('');
  const [groupVal, setGroupVal] = useState('');
  const [scoreVisible, setScoreVisible] = useState(false);
  const [scoreDisable, setScoreDisable] = useState(true);
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [feedbackDisable, setFeedbackDisable] = useState(true);
  const [content, setContent] = useState('');
  const [feedbackContent, setFeedbackContent] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const playerRef = useRef();
  const videoRef = useRef();
  const markdownRef = useRef();
  const warningRef = useRef(false);
  const rateStyle = {
    lineHeight: '10px'
  }
  const onScoreFormChange = () => {
    const result = scoreForm.getFieldsValue(true)
    const values = Object.values(result)
    setScoreDisable(values.includes(0))
  }
  const onFeedbackChange = (val) => {
    if(val.hasOwnProperty('item') && val.item){
      setFeedbackDisable(false)
    }
  }
  const beforeUpload = (file) =>{ 
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error(`${file.name} 文件超出5M!`);
    }
    return  isLt5M;
  }
  const onUpload = (options) => {
    const { file } = options
    file.status = 'done'
    setFileList((prev) => {
      let list = [...prev, file]
      if (list.length > 5) {
        message.error('最多可上传 5 个文件。');
        list = list.slice(0, 5)
      }
      return list
    })
  }
  const onFileRemove = (file) => {
    setTimeout(() => {
      const _fileList = fileList.filter(f => f.status !== 'removed')
      setFileList(_fileList)
    }, 0);
  }
  useEffect(() => {
    if(window.Toc){
        setTimeout(() => {
          new window.Toc('post',{
              'level':3,
              "top":300,
              'class':'nav-list',
              'targetId':'toc'
          });
        }, 800);
    }
    // new Toc('post',options);
  }, [markdown])
  var now = moment().locale('zh-cn').format('YYYY/MM/DD');
  useEffect(() => {
    if (userInfo && JSON.stringify(userInfo?.organization_data) !== '{}' ) {
      const { end_date, personal_data_center, overall_organization_data } = userInfo?.organization_data;
      if (moment(now).isBefore(end_date) && (personal_data_center || overall_organization_data)) {
        reportOnLineData('course', match.params.id)
      }
    }

    // eslint-disable-next-line
  }, [match.params.id, userInfo.organization_data])
  // 当前页面只能有一个视频在播放
  useEffect(() => {
    var vidios = document.getElementsByTagName('video');
    function pauseAll() {
      var self = this;
      [].forEach.call(vidios, function (i) {
        // 将 vidios 中其他的 vidio 全部暂停
        if (i !== self) {
          i.pause();
        }
      });
    }
    // 给 play 事件绑定暂停函数
    [].forEach.call(vidios, function (i) {
      i.addEventListener('play', pauseAll.bind(i));
    });
  });
  useEffect(() => {
    localStorage.setItem('preEntry','')
    let currentCourse;
    let sectionCourse;

    getCourseItem(match.params.id)
      .then(res => {
        currentCourse = res.data;
        let courseContent;
        // if (res.data.is_series && res.data.units.length) {
        //   console.log(`多章节课程===res.data`, res.data);
        //   if (res.data.units[0].units.length) {
        //     res.data.units.forEach((section) => {
        //       if (courseContent) return;
        //       let target = section.units.find(
        //         (item) => item.id === Number(match.params.unit_id)
        //       );
        //       sectionCourse = target;
        //       courseContent = target.content;
        //       setSectionCourse(target);
        //     });
        //   } else {
        //     let target = res.data.units.find(
        //       (item) => item.id === Number(match.params.unit_id)
        //     );
        //     sectionCourse = target;
        //     // courseContent = target.content;
        //     courseContent = target ? target.content : '';
        //     setSectionCourse(target);
        //   }
        // } else {
        //   courseContent = res.data.content;
        //   sectionCourse = res.data;
        //   setSectionCourse(res.data);
        // }

        if (!res.data.is_series) {
          courseContent = res.data.content;
          sectionCourse = res.data;
          setSectionCourse(res.data);
        } else {
          //  多章节课程
          // console.log('多章节课程====res.data===', res.data);
          if (res.data.units.length && res.data.units[0].units.length) {
            res.data.units.forEach(section => {
              if (courseContent) return;
              let target = section.units.find(
                item => item.id === Number(match.params.unit_id)
              );
              sectionCourse = target;
              courseContent = target.content;
              setSectionCourse(target);
            });
          } else {
            let target = res.data.units.find(
              item => item.id === Number(match.params.unit_id)
            );
            sectionCourse = target;
            courseContent = target ? target.content : '';
            setSectionCourse(target);
          }
        }
        // console.log('fetching ', courseContent);
        return fetch(courseContent);
      })
      .then(response => response.text())
      .then(text => {
        let mattered = matter(text);
        mattered.data = {
          ...mattered.data,
          ...currentCourse,
          sectionid: Number(match.params.unit_id)
        };
        // 合并unit中的预览视频
        sectionCourse &&
          sectionCourse.previewvideo &&
          (mattered.data.previewvideo = sectionCourse.previewvideo);
        if (!team_data?.is_submit_teaching_plan) {
          let index = mattered.content.indexOf('教师部分');
          if (index !== -1) {
            mattered.content = mattered.content.substring(0, index + 5);
          }
        }
        setMarkdown(mattered);
        if (mattered.data.aids.length > 0) {
          setStudentVal(mattered.data.student_numbers || 1);
          setGroupVal(mattered.data.group_number || 1);
          setAids(mattered.data.aids);
          setAids_2(mattered.data.aids);
          handlerCalculate(
            mattered.data.student_numbers || 1,
            mattered.data.group_number || 1,
            mattered.data.aids
          );
        }
        if (
          mattered.data.is_series &&
          mattered.data.units.length &&
          mattered.data.units[0].units.length
        ) {
          setDefaultActiveKey(
            mattered.data.units
              .reduce((prev, curr, index) => {
                return [...prev, ...curr.units];
              }, [])
              .findIndex(section => section.id === mattered.data.sectionid)
          );
        }
        if (mattered.data.id) {
          return setVisitHistory({
            course: currentCourse.id,
            ...(mattered.data.sectionid
              ? { unit: mattered.data.sectionid }
              : null),
            resourcetype: 'CourseHistory'
          });
        }
        return Promise.resolve('未发现course id');
      })
      .then(res => {
        setTimeout(() => {
          const eleList = document.querySelectorAll('.toc-nav')
          eleList.forEach(el => {
            el.addEventListener('click', function () {
              const pointEle = document.querySelector(el.getAttribute('point'))
              if (pointEle) {
                const offsetTop = (pointEle.offsetTop - 100)
                setTimeout(() => {
                  window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                  })
                }, 10);
              };
            })
          })
        }, 1000);
      })
      .catch(e => {
        let needToCourse = localStorage.getItem('needToCourseDetail')
        if(needToCourse){
          localStorage.setItem('needToCourseDetail','')
        }
        console.error(e);
      });
  }, [match.params.id, match.params.unit_id, team_data]);

  const courseSelecteHandler = useCallback(
    (course, ishotCourse) => {
      if (ishotCourse) {
        history.push(`/course/${course.id}/${course.unit_id || 0}`);
      } else {
        history.push(`/course/${match.params.id}/${course.id}`);
      }
    },
    [history, match.params.id]
  );
  const handleScoreSubmit = () => {
    const errHandle = () => {
      message.error('提交失败，请重新评价。');
      // closeScore()
    }
    const data = scoreForm.getFieldsValue(true)
    courseGrading(match.params.id, data).then(res => {
      if (res.data.success) {
        message.success('提交成功！');
        closeScore()
      }else{
        errHandle()
      }
    }).catch(err => {
      errHandle()
    })
  }
  const handleFeedbackSubmit = async () => {
    try {
      const result = feedbackForm.getFieldsValue()
      const { item, content } = result
      const param = new FormData()
      param.append('item',item)
      param.append('content',content)
      fileList.forEach(f=>{
        param.append('attachments', f, f.name)
      })
      setUploadLoading(true)
      let res = await courseFeedback(match.params.id, param)
      if (res.data.success) {
        setUploadLoading(false)
        closeFeedback()
        message.success("提交成功！")
      }else{
        message.success("提交失败！")
        setUploadLoading(false)
      }
    } catch (error) {
      message.error(error)
    }
  }
  const closeFeedback = () => {
    feedbackForm.resetFields()
    setFeedbackVisible(false)
    setFeedbackDisable(true)
    setFileList([])
  }
  const closeScore = () => {
    scoreForm.resetFields()
    setScoreVisible(false)
  }

  useLayoutEffect(() => {
    if (
      sectionCourse?.ppt_furl &&
      sectionCourse?.ppt_furl.includes('http://')
    ) {
      if (!playerRef.current) {
        playerRef.current = new DPlayer({
          container: videoRef.current,
          video: {
            url: sectionCourse.ppt_furl
          }
        });
      } else {
        // playerRef.current.switchVideo({
        //   url: sectionCourse.ppt_furl,
        // });

        playerRef.current.destroy();
        playerRef.current = new DPlayer({
          container: videoRef.current,
          video: {
            url: sectionCourse.ppt_furl
          }
        });
      }
    }

    // if (markdown?.data?.previewvideo) {
    //   if (!playerRef.current) {
    //     playerRef.current = new DPlayer({
    //       container: videoRef.current,
    //       video: {
    //         url: markdown.data.previewvideo,
    //       },
    //     });
    //   } else {
    //     playerRef.current.switchVideo({
    //       url: markdown.data.previewvideo,
    //     });
    //   }
    // }
  }, [sectionCourse]);

  const findTechTitle = () => {
    const arr = document.querySelectorAll('h1');
    for (let h1 of arr) {
      if (Object.is(h1.innerText, '教师部分')) return h1;
    }
    return null;
  };
  /* bug： 部分课程的 markdownRef?.current为 undefined */
  // useEffect(() => {
  //   console.log('========', markdownRef, markdownRef?.current);

  //   if (markdownRef && markdownRef.current) {
  //     markdownRef.current.addEventListener(
  //       'click',
  //       e => {
  //         // if (e.target.nodeName === 'IMG') {
  //         const markdownCon = document.querySelector('.markdown');
  //         new Viewer(markdownCon, {
  //           url: 'src'
  //         });
  //         // }
  //       },
  //       true
  //     );
  //   }
  // }, [markdownRef?.current]); // eslint-disable-line

  useEffect(() => {
    if (markdown && markdown.content) {
      if (markdownRef?.current) {
        markdownRef.current.addEventListener(
            'click',
          e => {
            // if (e.target.nodeName === 'IMG') {
            const markdownCon = document.querySelector('.markdown');
            new Viewer(markdownCon, {
              url: 'src'
            });
            // }
          },
          true
        );
      }

    }
  }, [markdown]);
  useEffect(() => {
    let timer = null;
    let onClick = e => {
      // let getToast = simpleToast()
      // console.log('simple')
      // return (e) => {
      //     const techTitle = findTechTitle()
      //     if (Object.is(e.target, techTitle)) getToast({ type: 'warning', text: '当前登录的账户没有查看「教师部分」的权限' })
      //     // getToast = null
      // }
      clearTimeout(timer);
      const techTitle = findTechTitle();
      if (Object.is(e.target, techTitle) && !warningRef.current) {
        message.warning('当前登录的账户没有查看「教师部分」的权限', 0);
        setWarning(true);
      }
      timer = setTimeout(() => {
        message.destroy();
        clearTimeout(timer);
        setWarning(false);
      }, 3000);
    };
    if (!team_data?.is_submit_teaching_plan) {
      window.addEventListener('click', onClick);
    }
    return () => {
      if (!team_data?.is_submit_teaching_plan) {
        window.removeEventListener('click', onClick);
      }
    };
  }, [team_data, history]);

  // useEffect(() => {
  //     return () => {
  //         setWarning(false)
  //     }
  // }, [match.params.id])
  useEffect(() => {
    warningRef.current = warning;
  }, [warning]);

  // 下载
  const downLoad = furl => {
    const a = document.createElement('a');
    a.href = furl;
    a.setAttribute('download', '');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // 改变每组人数时 || 改变学生总人数时
  const handlerChange = (e, input_type, stuNum, groupNum) => {
    // console.log(
    //   `输入框改变-====`,
    //   e.target.value,
    //   input_type,
    //   stuNum,
    //   groupNum
    // );

    const { value } = e.target;
    const reg = /^[1-9]\d*$/;

    if ((!isNaN(value) && reg.test(value)) || value === '') {
      if (input_type === 2) {
        // 改变每组人数时，输入规则：大于等于1并且小于等于学生总人数
        const isTrue = Number(value) <= stuNum;
        const isMax = Number(value) > stuNum;
        if (isTrue) {
          setGroupVal(value);
          handlerCalculate(stuNum, value, aids_2);
        } else if (isMax) {
          setGroupVal(stuNum);
          handlerCalculate(stuNum, stuNum, aids_2);
        }
      } else {
        // 改变学生总人数时
        setStudentVal(value);
        handlerCalculate(value, groupNum, aids_2);
      }
    }
  };

  // 计算
  const handlerCalculate = (stu, group, aids_2_) => {
    /**
     根据教具的计算方式如下：
     * 0：按分组和学生总人数计算：=（学生总人数/每组人数）向上取整 * 数量
     * 1：只按学生总人数计算：= 学生总人数 * 数量
     * 2：不按分组不按学生总人数计算：= 数量
     */
    let real_aids = JSON.parse(JSON.stringify(aids_2_));
    // console.log(`real_aids=====`, real_aids);
    // console.log(`stu - group`, stu, '-', group);

    let new_aids = real_aids.map(item => {
      if (item.number > 0) {
        if (item.calculate_way === 0) {
          // 按分组和学生总人数计算：=（学生总人数/每组人数）向上取整 * 数量
          let new_number = Math.ceil(stu / group) * item.number;
          // console.log(`new_number1111111===`, new_number, isFinite(new_number));
          item.number = isFinite(new_number) ? new_number : 0;
        } else if (item.calculate_way === 1) {
          // 只按学生总人数计算：= 学生总人数 * 数量
          let new_number = stu * item.number;
          // console.log(`new_number2222===`, new_number);
          item.number = new_number;
        }
      }

      return item;
    });

    setAids([...new_aids]);
  };
  const openPPTFrame = async () => {
    localStorage.setItem('CoursePPT', JSON.stringify({
      [match.params.id]: sectionCourse.ppt_furl
    }))
   await window.open(`/coursePPT/${match.params.id}`)
  }

  return (
    <div id="courseItem">
      <BackTop />
      <div className="content">
        <div className="markdownWrapper">
          <div className="container">
            <div className="row">
              <div className="left col col--8">
                {/* new=============== */}
                {sectionCourse?.ppt_furl &&
                  !sectionCourse?.ppt_furl.includes('http://') ? (
                  <div className="pptBox">
                    <div style={{ position: 'relative' }}>
                        <div className="pptMark">
                          {sectionCourse.ppt_furl.includes('ow365') ?
                          <img src="/image/videoplay.png" alt="" onClick={openPPTFrame} />
                             :
                            <a
                              href={sectionCourse.ppt_furl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/image/videoplay.png" alt="" />
                            </a>
                          }
                        </div>
                      <div className="row ppt_contain">
                        <i></i>
                        <iframe
                          className="pptIframe"
                          title="pptIframe"
                          // allowfullscreen="true"
                          style={{
                            border: 0,
                            height: '100%',
                            width: '100%',
                            borderRadius: '7px'
                          }}
                          src={sectionCourse.ppt_furl}
                        ></iframe>
                        <i></i>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* ----------------------- */}

                {/* {markdown?.data?.previewvideo ? (
                  <div className="previewvideo" ref={videoRef}></div>
                ) : null} */}
                {sectionCourse?.ppt_furl &&
                  sectionCourse?.ppt_furl.includes('http://') ? (
                  <div className="previewvideo" ref={videoRef}></div>
                ) : null}

                {/* ------------ */}
                {(sectionCourse?.download_url &&
                  !sectionCourse?.download_url.includes('http://')) ||
                  markdown?.data?.subtitle ||
                  markdown?.data?.description ||
                  aids.length > 0 ? (
                  <div className="readycourse">
                    <div className="readytitle">
                      <span className="title">课程准备</span>
                      {sectionCourse?.is_down &&
                        sectionCourse?.download_url &&
                        !sectionCourse?.download_url.includes('http://') ? (
                        <span
                          onClick={() => downLoad(sectionCourse.download_url)}
                          className="download"
                        >
                          {/* <img src="/image/download.png" alt="" /> */}
                          <DownloadOutlined style={{ marginRight: '8px' }} />
                          下载 PPT
                        </span>
                      ) : null}
                    </div>
                    {/* <p>
                      {markdown?.data?.subtitle || markdown?.data?.description}
                    </p> */}
                    <p>
                      {markdown?.data?.description ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: markdown.data.description.replace(
                              /\r\n/g,
                              '<br>'
                            )
                          }}
                        ></span>
                      ) : (
                        ''
                      )}
                    </p>
                    {(!markdown?.data?.is_series ||
                      (markdown?.data?.is_series &&
                        markdown?.data?.sectionid ===
                        markdown?.data?.unit_id)) &&
                      aids.length ? (
                      <div className="readytable">
                        <div>
                          <p>
                            <span style={{ color: '#333' }}>学生总人数：</span>
                            <Input
                              onChange={e =>
                                handlerChange(e, 1, studentVal, groupVal)
                              }
                              value={studentVal}
                            />
                          </p>
                          <p>
                            <span style={{ color: '#333' }}>每组人数：</span>
                            <Input
                              onChange={e =>
                                handlerChange(e, 2, studentVal, groupVal)
                              }
                              value={groupVal}
                            />
                          </p>
                        </div>
                        <table border="1">
                          {aids.map(tool => (
                            <tr key={tool.id}>
                              <td>
                                <p className="toolname">
                                  {tool.url ? (
                                    <a
                                      href={tool.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {tool.name}
                                    </a>
                                  ) : (
                                    tool.name
                                  )}
                                </p>
                                <p>{tool.remark}</p>
                              </td>
                              <td>
                                {tool.number && tool.unit
                                  ? `${tool.number} ${tool.unit}`
                                  : '-'}
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {/* ------------- */}
                {isTeacher &&
                  markdown?.content &&
                  !markdown.content.toLowerCase().includes('<!doctype') ? (
                  <div
                    ref={markdownRef}
                    className="markdown"
                    id="post"
                  // dangerouslySetInnerHTML={{
                  //   __html: marked(markdown.content)
                  // }}
                  >
                  <div id="toc"></div>
                    <ReactMarkdown
                      escapeHtml={false}
                      linkTarget="_blank"
                      renderers={{ code: CodeBlock }}
                      source={markdown.content}
                    />
                  </div>
                ) : null}

                {/* <CourseComment courseid={match.params.id}></CourseComment> */}
              </div>
              <div className="col col--4 sidebar">
                <div className="overview">
                  <div className="title1">概述</div>
                  <div className="overviewmain">
                    {markdown?.data?.['title'] ? (
                      <p>
                        <span className="title">课程</span>
                        <span className="desc">
                          {markdown.data['title'].split('\n').join('、')}
                        </span>
                      </p>
                    ) : null}
                    {markdown?.data?.['new_course_type'].length ? (
                      <p>
                        <span className="title">课程类型</span>
                        <span className="desc">
                          {typeof markdown.data['new_course_type'] === "string" ? markdown.data['new_course_type'] : markdown.data['new_course_type'].join('、')}
                        </span>
                      </p>
                    ) : null}
                    {markdown?.data?.['new_suit_tags'] ? (
                      <p>
                        <span className="title">适合阶段</span>
                        <span className="desc">
                          {typeof markdown.data['new_suit_tags'] === "string" ? markdown.data['new_suit_tags'] : markdown.data['new_suit_tags'].join('、')}
                        </span>
                      </p>
                    ) : null}
                    {/* {markdown?.data?.['course_modules'] &&
                      markdown.data.course_modules !== '无' ? (
                      <p>
                        <span className="title">课程模块</span>
                        <span className="desc">
                          {markdown.data['course_modules']
                            .split('\n')
                            .join('、 ')}
                        </span>
                      </p>
                    ) : null} */}
                    {markdown?.data?.['new_teaching_aid'] ? (
                      <p>
                        <span className="title">课程教具</span>
                        <span className="desc">
                          {typeof markdown.data['new_teaching_aid'] === "string" ? markdown.data['new_teaching_aid'] : markdown.data['new_teaching_aid'].join('、')}
                        </span>
                      </p>
                    ) : null}
                    {markdown?.data?.['new_teaching_aid'] ? (
                      <p>
                        <span className="title">编程平台</span>
                        <span className="desc">
                          {typeof markdown.data['new_program_platform'] === "string" ? markdown.data['new_program_platform'] : markdown.data['new_program_platform'].join('、')}
                        </span>
                      </p>
                    ) : null}
                  </div>
                </div>
                {
                  // markdown?.data?.is_series ? (
                  markdown?.data?.is_series && markdown.data.units.length ? (
                    <div className="nav more">
                      <div className="title">
                        <div className="titleName">课程目录</div>
                        <div className="unit">
                          第{' '}
                          {markdown.data.units[0].units.length === 0
                            ? markdown.data.units.findIndex(
                              course => course.id === markdown.data.sectionid
                            ) + 1
                            : markdown.data.units.findIndex(section =>
                              section.units.find(
                                course =>
                                  course.id === markdown.data.sectionid
                              )
                            ) + 1}{' '}
                          单元 / 共 {markdown.data.units.length} 个单元
                        </div>
                      </div>
                      <p className="solid"></p>
                      {markdown.data.units[0].units.length > 0 ? (
                        <Collapse
                          className="courses sections"
                          accordion
                          bordered={false}
                          expandIconPosition="right"
                          activeKey={[defaultActiveKey]}
                          onChange={val => setDefaultActiveKey(val)}
                        >
                          {markdown.data.units.map((course, index) => (
                            <Collapse.Panel
                              header={
                                <>
                                  <span className="section">{`第${SectionToChinese(
                                    index + 1
                                  )}章`}</span>
                                  <span className="sectionName">
                                    {course.title}
                                  </span>
                                </>
                              }
                              key={index}
                            >
                              {course.units.map((courseItem, index) => (
                                <p
                                  key={index}
                                  className={`sectionItem ${courseItem.id === markdown.data.sectionid
                                    ? 'active'
                                    : ''
                                    }`}
                                  onClick={() =>
                                    courseSelecteHandler(courseItem)
                                  }
                                >
                                  <span className="section">{index + 1}</span>
                                  <span className="sectionName">
                                    {courseItem.title}
                                  </span>
                                </p>
                              ))}
                            </Collapse.Panel>
                          ))}
                        </Collapse>
                      ) : (
                        <div className="courses">
                          {markdown.data.units.map((courseItem, index) => (
                            <p
                              key={index}
                              className={`sectionItem ${courseItem.id === markdown.data.sectionid
                                ? 'active'
                                : ''
                                }`}
                              onClick={() => courseSelecteHandler(courseItem)}
                            >
                              <span className="section">{index + 1}</span>
                              <span className="sectionName">
                                {courseItem.title}
                              </span>
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : null
                  // <>
                  //   {hotCourses.length > 0 ? (
                  //     <div className="nav">
                  //       <div className="title">
                  //         <div className="titleName">推荐课程</div>
                  //       </div>
                  //       <p className="solid"></p>
                  //       <div className="courses hot">
                  //         {hotCourses.slice(0, 6).map((course, index) => (
                  //           <div
                  //             key={index}
                  //             onClick={() => courseSelecteHandler(course, true)}
                  //           >
                  //             <Link
                  //               className="course"
                  //               to={
                  //                 '/course/' +
                  //                 course.id +
                  //                 '/' +
                  //                 (course.unit_id || 0)
                  //               }
                  //               key={course.id}
                  //             >
                  //               <img
                  //                 src={course.cover || '/image/course.png'}
                  //                 alt={course.name}
                  //               />
                  //               <div className="name">{course.title}</div>
                  //             </Link>
                  //           </div>
                  //         ))}
                  //       </div>
                  //     </div>
                  //   ) : null}
                  // </>
                }

                {markdown?.data?.resources.length > 0 && (
                  <div className="resources">
                    <div className="title1">
                      课程资源
                      <p></p>
                    </div>
                    <div className="resmain">
                      {markdown.data.resources.map(source => (
                        <div
                          key={source.id}
                          onClick={() =>
                            source.content && downLoad(source.content)
                          }
                        >
                          <p>{source.title}</p>
                          {/* <img src="/image/download.png" alt="" /> */}
                          <DownloadOutlined />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {
                  userInfo.campus_id && !sectionCourse?.campus &&  isTeacher && 
                  <div className='btnFlex' >
                    <Button type="primary" size='large' onClick={() => {
                      setFeedbackVisible(true)
                    }}> 课程反馈 </Button>
                    <Button type="primary" size='large' onClick={() => {
                      setScoreVisible(true)
                    }}> 课程评分 </Button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={scoreVisible}
        className="scoreModal"
        footer={<div>
          <Button onClick={closeScore} > 取消 </Button>
          <Button type="primary" onClick={handleScoreSubmit} disabled={scoreDisable}> 提交 </Button>
        </div>}
        centered={true}
        getContainer={false}
        onCancel={closeScore}
        title="我要评分"
        closable={false}
        width={544}
      >
        <div className='content'>
        <Form
          layout="horizontal"
          className="scoreForm"
          form={scoreForm}
          initialValues={{
            simple: 0,
            vividness: 0,
            teaching_objective: 0,
            interest: 0,
            effective: 0,
            content: ''
          }}
          onValuesChange={onScoreFormChange}
        >
          <Form.Item label="简明扼要" name="simple">
            <Rate allowHalf style={rateStyle} />
          </Form.Item>
          <div className='score-form-item'>文字精炼，排版无误，无错别字，风格与模板统一，字体清晰且字号适当。</div>

          <Form.Item label="生动形象" name="vividness">
            <Rate allowHalf style={rateStyle} />
          </Form.Item>
          <div className='score-form-item'>使用高质量图表、图像和视频辅助说明，内容准确、时效且无版权纠纷。</div>

          <Form.Item label="教学目标" name="teaching_objective">
            <Rate allowHalf style={rateStyle} />
          </Form.Item>
          <div className='score-form-item'>目标清晰，逻辑顺畅，难度和深度适中。</div>

          <Form.Item label="创意趣味" name="interest">
            <Rate allowHalf style={rateStyle} />
          </Form.Item>
          <div className='score-form-item'>展现英荔特色，富有想象力和创造力，增加趣味性。</div>

          <Form.Item label="有效评估" name="effective">
            <Rate allowHalf style={rateStyle} />
          </Form.Item>
          <div className='score-form-item'>练习能有效检验学习成效，难度恰当。</div>
          
          <Form.Item label="评价" name="content" wrapperCol={{ span: 24 }} extra={<p className='content_length'> {content.length}/200 </p>}>
            <TextArea rows={6} placeholder="请填写评价内容"  maxLength={200} onChange={e => { setContent(e.target.value) }} />
          </Form.Item>
        </Form>
        </div>
      </Modal>
      
      <Modal
        visible={feedbackVisible}
        className="feedbackModal"
        footer={<div>
          <Button onClick={closeFeedback} disabled={uploadLoading}> 取消 </Button>
          <Button type="primary" onClick={handleFeedbackSubmit} disabled={feedbackDisable} loading={uploadLoading}> 提交 </Button>
        </div>}
        centered={true}
        getContainer={false}
        onCancel={closeFeedback}
        title="我要反馈"
        closable={false}
        width={544}
      >
        <div className='content'>  
        <Form
          layout="vertical"
          className="feedbackForm"
          form={feedbackForm}
          initialValues={{
            item: undefined,
            content: ''
          }}
          onValuesChange={onFeedbackChange}
        >
          <Form.Item label="选择反馈项" name="item" required >
            <Radio.Group disabled={uploadLoading}>
              <Radio value={1}>PPT</Radio>
              <Radio value={2}>视频</Radio>
              <Radio value={3}>教师手册</Radio>
              <Radio value={4}>概述</Radio>
              <Radio value={5}>课程资源</Radio>
              <Radio value={6}>其他</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="问题描述" name="content" extra={<p className='content_length'> {feedbackContent.length}/200 </p>}>
            <TextArea disabled={uploadLoading} rows={6} placeholder="请填写反馈的具体问题" maxLength={200} onChange={e => { setFeedbackContent(e.target.value) }} />
          </Form.Item>

          <Form.Item label="上传附件" name="attachments">
            <Dragger
              disabled={uploadLoading}
              customRequest={onUpload}
              beforeUpload={beforeUpload}
              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.zip,.rar"
              multiple={true}
              fileList={fileList}
              onRemove={onFileRemove}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">单击或拖动文件到此区域进行上传</p>
              <p className="ant-upload-hint">
                最多可上传 5 个文件，单个大小不超过 5M。
                <br />
                支持 JPG、JPEG、PNG、PDF、DOC、ZIP、RAR。
              </p>
            </Dragger>
          </Form.Item>
        </Form>
        </div>
      </Modal>
      {/* </div> */}
      {/* <Modal
                className="previewImgModal"
                closable={false}
                visible={showImg}
                width={1200}
                footer={null}
                centered={true}
                getContainer={false}
            >
                <CloseCircleOutlined className="errorIcon" onClick={() => setShowImg(false)} />
                <img style={{ width: '100%' }} src={imgSrc} alt="" />
            </Modal> */}
      {/* <ModalGateway> */}
      {/* {showImg ?
                <img id="showImg" style={{ width: '100%' }} src={imgSrc} alt="" />
                // <Modal onClose={() => setShowImg(false)}>
                //     <Carousel views={[{ source: imgSrc }]} />
                // </Modal>
                : null} */}
      {/* </ModalGateway> */}
    </div>
  );
};

export default CourseItem;
