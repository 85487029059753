import React, { useReducer, useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom'
import { Upload, Button, Table, Modal, Tooltip, message, Input } from 'antd';
import moment from 'moment'
import { UploadOutlined, InfoCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  uploadTeachPlanFile,
  deleteTeachPlanFile,
  getTeachPlanFiles,
  patchTeachPlanFile,
  handleOrg_teachplan,
  shareTeachPlan,
  updateSort,
  resetTeachPlan,
  getResetsStatus
} from '../../../utils/request';
import { deBounce } from '../../../utils';
// import { loadTeachPlan } from '../../../store/actions'
import './style.scss';
let resetTimer = null;

const DEFAULT_TEXT = '单个文件最大 5M，支持格式 .xlsx  .docx  .pdf';
const SIZE_TEXT = '单个文件不能超过 5M';
const NAME_TEXT = '不能重复上传文件';
const FORMAT_TEXT = '支持格式 .xlsx  .docx  .pdf';

const types = {
  COMMIT_FILE: 'COMMIT_FILE',
  UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE: 'UPLOAD_FILE_FAILURE',
  FETCH_FILE_SUCCESS: 'FETCH_FILE_SUCCESS',
  FETCH_FILE_FAILURE: 'FETCH_FILE_FAILURE',
  SET_SELECT_FILE: 'SET_SELECT_FILE',
  SET_DEL_FILE_ID: 'SET_DEL_FILE_ID',
  SET_TIP_TEXT: 'SET_TIP_TEXT',
  TOGGLE_DEL_MODAL: 'TOGGLE_DEL_MODAL'
};

const initialState = {
  uploading: false,
  commitFile: null,
  selectFile: null,
  fileList: [],
  tipWarning: false,
  tipText: DEFAULT_TEXT,
  toggleDelModal: false,
  delId: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.COMMIT_FILE:
      return { ...state, commitFile: action.file };
    case types.UPLOAD_FILE_REQUEST:
      return { ...state, uploading: true };
    case types.UPLOAD_FILE_SUCCESS:
      return { ...state, uploading: false };
    case types.UPLOAD_FILE_FAILURE:
      return { ...state, uploading: false };
    case types.FETCH_FILE_SUCCESS:
      return { ...state, fileList: action.list };
    case types.TOGGLE_DEL_MODAL:
      return { ...state, toggleDelModal: !state.toggleDelModal };
    case types.SET_DEL_FILE_ID:
      return { ...state, delId: action.id };
    case types.SET_SELECT_FILE:
      return { ...state, selectFile: action.file };
    case types.SET_TIP_TEXT:
      return { ...state, tipText: action.text, tipWarning: action.value };
    default:
      return state;
  }
};
const TeachPlanManage = () => {
  // const dispatch = useDispatch()
  // const planIds = useSelector(state => state.planIds)
  // const plan = useSelector(state => state.plan)
  // const fileList = planIds.map(id => plan[id])
  const userInfo = useSelector(state => state.userInfo);
  const [state, localDispatch] = useReducer(reducer, initialState);
  const {
    // uploading,
    fileList,
    delId,
    toggleDelModal,
    selectFile,
    commitFile,
    tipText,
    tipWarning
  } = state;
  const [disabled, setDisabled] = useState(false);
  const [planSort, setPlanSort] = useState('');
  const [curRecord, setCurRecord] = useState(null);
  const [showTip, setShowTip] = useState(false);
  const [createdAtOrder, setCreatedAtOrder] = useState(null);
  const [fileNameOrder, setFileNameOrder] = useState(null);
  const [enableOrder, setEnableOrder] = useState(null);
  const [bagTagOrder, setBagTagOrder] = useState(null);
  const [backupsList, setBackupsList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const columns = [
    {
      title: '文件名',
      dataIndex: 'file_name',
      key: 'file_name',
      width: 300,
      ellipsis: true,
      sorter: {
        multiple: 2
      },
      sortOrder: fileNameOrder
      
    },
    {
      title: '阶段',
      dataIndex: 'course_bag_tag',
      key: 'course_bag_tag',
      width: 180,
      sorter: {
        multiple: 4
      },
      sortOrder: bagTagOrder
    },
    {
      title: '状态',
      dataIndex: 'enable',
      key: 'enable',
      width: 100,
      render: (text, record) => <span>{transformStatus(record.enable)}</span>,
      sorter: {
        multiple: 3
      },
      sortOrder: enableOrder
    },
    {
      title: '提交时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: value => (value ? dayjs(value).format('YYYY/MM/DD HH:mm') : '-'),
      sorter: {
        multiple: 1
      },
      sortOrder: createdAtOrder
    },
    {
      title: '操作',
      render: (text, record) => {
        /**   */
        if (userInfo?.user_type.indexOf('manager') > -1) {
          if (
            record.id !== 0 &&
            record.author !== userInfo.id &&
            record.hasOwnProperty('is_share') &&
            record.is_share
          ) {
            return (
              <div className="actionBar">
                {typeof record.course_bag !== 'number' && <div className="actionItem">
                  {record.id !== 0 ? (
                    <a href={getDownloadUrl(record)} download={record.name}>
                      下载
                    </a>
                  ) : (
                    <a href={downLoad(record)} download={record.name}>
                      下载
                    </a>
                  )}
                </div>}
              </div>
            );
          } else {
            return (
              <div className="actionBar">
                {record.id !== 0 && (
                  <div className="actionItem" onClick={() => patchFile(record)}>
                    {record.enable ? '禁用' : '启用'}
                  </div>
                )}
                {
                  typeof record.course_bag !== 'number' && <div className="actionItem">
                    {record.id !== 0 ? (
                      <a href={getDownloadUrl(record)} download={record.name}>
                        下载
                      </a>
                    ) : (
                      <a href={downLoad(record)} download={record.name}>
                        下载
                      </a>
                    )}
                  </div>
                }
                {/* 非校区教学计划有共享 */}
                {!record.course_bag && record.id !== 0 && record.hasOwnProperty('is_share') && (
                  <div
                    className="actionItem"
                    onClick={() => {
                      shareHandle(record);
                    }}
                  >
                    {record.is_share ? '取消共享' : '共享'}
                  </div>
                )}
                {record.id !== 0 && (
                  <div
                    className="actionItem"
                    onClick={() => {
                      delHandle(record);
                    }}
                  >
                    删除
                  </div>
                )}
              </div>
            );
          }
        } else if (userInfo?.user_type === 'teacher') {
          return (
            <div className="actionBar">
              {/* --- 非组织教学计划 管理员共享的（is_share为true）且启用只能下载，无其他操作；教师上传的计划，其他人不可见（后端已做不返回） --- */}
              {record.id !== 0 &&
                record.hasOwnProperty('is_share') &&
                !record.is_share && (
                  // {record.id !== 0 && (
                  <div className="actionItem" onClick={() => patchFile(record)}>
                    {record.enable ? '禁用' : '启用'}
                  </div>
                )}

              {typeof record.course_bag !== 'number' && <div className="actionItem">
                {record.id !== 0 ? (
                  <a href={getDownloadUrl(record)} download={record.name}>
                    下载
                  </a>
                ) : (
                  <a href={downLoad(record)} download={record.name}>
                    下载
                  </a>
                )}
              </div>}
              {record.id !== 0 &&
                record.hasOwnProperty('is_share') &&
                !record.is_share && (
                  <div
                    className="actionItem"
                    onClick={() => {
                      delHandle(record);
                    }}
                  >
                    删除
                  </div>
                )}
            </div>
          );
        }

        // );
      }
    }
  ];

  const downLoad = file => file.file_url + `?attname=${file.file_name}`;
  // 删除/禁用组织计划
  const handleFile = useCallback(
    async (type, record) => {
      if (!window.navigator.onLine) {
        message.error('操作失败，请稍后再尝试');
        return;
      }

      let res = await handleOrg_teachplan({
        type,
        id: record.id,
        enable:
          type === 'delete'
            ? record.enable * 1
            : JSON.stringify(!record.enable * 1)
      });
      if (Object.is(res.status, 200)) {
        if (type === 'delete') {
          localDispatch({ type: types.TOGGLE_DEL_MODAL });
        }
      }
    },
    [localDispatch]
  );
  const fetchFileList = useCallback((sort) => {
    const param = {
      page_size: 9999,
    }
    if(sort){
      param.plan_sort = sort
    }
    setLoading(true)
    getTeachPlanFiles(param)
      .then(res => {
        if (res.status === 200) {
          setBackupsList(res.data.results)
          localDispatch({
            type: types.FETCH_FILE_SUCCESS,
            list: res.data.results
          });
          setLoading(false)
        }
      })
      .catch(err => {});
  }, [localDispatch]);
  const updateSortStatus = (val) => {
    let field = val
    let order = "ascend"
    const map = {
      course_bag_tag: setBagTagOrder,
      file_name: setFileNameOrder,
      enable: setEnableOrder,
      created_at: setCreatedAtOrder
    }
    if (val.includes('-')) {
      order = "descend"
      field = val.substr(1)
    } 
    map[field](order)
  }
  useEffect(() => {

    updateSort('get').then(sortRes => {
      setPlanSort(sortRes.data.plan_sort)
      if(sortRes.data.plan_sort){
        updateSortStatus(sortRes.data.plan_sort)
      }
      fetchFileList(sortRes.data.plan_sort)
    })
  }, [fetchFileList]);

  const onUpload = options => {
    localDispatch({ type: types.COMMIT_FILE, file: null });
    localDispatch({
      type: types.SET_SELECT_FILE,
      file: null
    });
    const fileRegExp = /\.(docx|pdf|xlsx)$/;
    const maxSize = 5 * 1024 * 1024;
    const { name, size, uid } = options.file;
    if (fileList.filter(file => Object.is(file.file_name, name)).length) {
      localDispatch({
        type: types.SET_TIP_TEXT,
        text: NAME_TEXT,
        value: true
      });
      return;
    }
    if (!fileRegExp.test(name)) {
      // todo
      localDispatch({
        type: types.SET_TIP_TEXT,
        text: FORMAT_TEXT,
        value: true
      });
      return;
    }
    if (size > maxSize) {
      // todo
      localDispatch({
        type: types.SET_TIP_TEXT,
        text: SIZE_TEXT,
        value: true
      });
      return;
    }
    localDispatch({ type: types.SET_SELECT_FILE, file: options.file });
    localDispatch({
      type: types.COMMIT_FILE,
      file: {
        uid, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
        name, // 文件名
        status: 'done', // 状态有：uploading done error removed
        response: '{"status": "success"}' // 服务端响应内容
      }
    });
  };

  const uploadFile = useCallback(() => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再试');
      return;
    }

    setDisabled(true);

    const formData = new FormData();
    let reader = new FileReader();
    reader.readAsDataURL(selectFile);
    formData.append('file', selectFile);
    uploadTeachPlanFile(formData)
      .then(res => {
        localDispatch({ type: types.COMMIT_FILE, file: null });
        localDispatch({
          type: types.SET_TIP_TEXT,
          text: DEFAULT_TEXT,
          value: false
        });
        localDispatch({
          type: types.SET_SELECT_FILE,
          file: null
        });
        setDisabled(false);
        fetchFileList();
      })
      .catch(err => {
        localDispatch({ type: types.UPLOAD_FILE_FAILURE });
        message.error('操作失败，请稍后再试');
        setDisabled(false);
      });
  }, [fetchFileList, selectFile]);

  const deleteFile = useCallback(() => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试');
      return;
    }
    if (!curRecord.from_campus) {
      deleteTeachPlanFile(delId)
        .then(res => {
          if (Object.is(res.status, 204)) {
            localDispatch({ type: types.TOGGLE_DEL_MODAL });
            fetchFileList();
          }
        })
        .catch(err => {
          message.error('操作失败，请稍后尝试');
        });
    } else {
      handleFile('delete', curRecord);
    }
  }, [delId, curRecord, handleFile, fetchFileList]);
  const delHandle = record => {
    localDispatch({ type: types.TOGGLE_DEL_MODAL });
    localDispatch({
      type: types.SET_DEL_FILE_ID,
      id: record.id
    });
    setCurRecord(record);
  };

  const patchFile = async record => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试');
      return;
    }
    const { id, enable } = record;
    // 禁用非组织教学计划
    if (!record.from_campus) {
      let res = await patchTeachPlanFile(id, { enable: !enable });
      if (Object.is(res.status, 200)) fetchFileList();
    } else {
      handleFile('modify', record);
    }
  };
  // 分享/取消自己上传的计划
  const shareHandle = record => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试');
      return;
    }
    let type = record?.is_share ? 'cancel_share' : 'share';
    shareTeachPlan({ type, id: record?.id })
      .then(res => {
        if (res.data.success) {
          message.success(res.data.msg);
          if (Object.is(res.status, 200)) fetchFileList();
        } else {
          message.warn(res.data.msg);
        }
      })
      .catch(e => {
        if (typeof e.response.data === 'string') return;
        if (e.response.status === 400) {
          message.warning(e.response.data.msg);
        } else {
          message.error('操作失败，请稍后再尝试');
        }
      });
  };
  const getFileMsg = file =>
    file ? (
      <div className="file" key={file.uid}>
        {file.name}
      </div>
    ) : (
      <p className="tip" style={tipWarning ? { color: 'red' } : null}>
        {tipText}
      </p>
    );

  const getDownloadUrl = file => {
    if (file.file_url.indexOf('http') > -1) {
      return file.file_url + `?attname=${file.file_name}`;
    } else {
      return (
        'https://' +
        window.location.host +
        file.file_url +
        `?attname=${file.file_name}`
      );
    }
  };
  const getUpDisStatus = () => fileList.filter(file => file.enable).length >= 50;
  const transformStatus = state => (state ? ' 已启用' : '未启用');
  const onChange = (pagination, filters, sorter) => {
    setSearch(null)
    let order = ''
    console.log('sorter',sorter);
    if (Array.isArray(sorter)) {
      const final = sorter[sorter.length - 1]
      if (final.field === "course_bag_tag") {
        order = (final.order === 'descend' ? '-' : '') + final.field
        setBagTagOrder(final.order)
        setFileNameOrder(null)
        setCreatedAtOrder(null)
        setEnableOrder(null)
      } else {
        setBagTagOrder(null)
        const condition = item => item.field === "created_at" || item.field === "file_name"
        if (sorter.length > 1 && sorter.some(s => condition(s))) {
          let otherIndex = 0;
          sorter.forEach((el, index) => {
            if (condition(el) && index > otherIndex) {
              otherIndex = index
            }
          })
          const enableIndex = sorter.findIndex(f => f.field === "enable")
          if (sorter[otherIndex].field === 'created_at') {
            setCreatedAtOrder(sorter[otherIndex].order)
            setFileNameOrder(null)
          } else {
            setFileNameOrder(sorter[otherIndex].order)
            setCreatedAtOrder(null)
          }
          if (enableIndex !== -1) {
            setEnableOrder(sorter[enableIndex].order)
            if (enableIndex < otherIndex) {
              order = (sorter[otherIndex].order === 'descend' ? '-' : '') + sorter[otherIndex].field
              setPlanSort(order)
              fetchFileList(order)
            }else{
              order = `${(sorter[otherIndex].order === 'descend' ? '-' : '') + sorter[otherIndex].field},${(sorter[enableIndex].order === 'descend' ? '-' : '') + sorter[enableIndex].field}`
              setPlanSort(order)
              fetchFileList(order)
            }
          } else {
            setEnableOrder(null)
            order = (sorter[otherIndex].order === 'descend' ? '-' : '') + sorter[otherIndex].field
            setPlanSort(order)
            fetchFileList(order)
          }
        }

      }
    } else {
      if (sorter.field === 'enable') {
        setEnableOrder(sorter.order)
        setCreatedAtOrder(null)
        setFileNameOrder(null)
        setBagTagOrder(null)
      } else if (sorter.field === 'created_at') {
        setCreatedAtOrder(sorter.order)
        setFileNameOrder(null)
        setEnableOrder(null)
        setBagTagOrder(null)
      } else if (sorter.field === 'file_name') {
        setFileNameOrder(sorter.order)
        setCreatedAtOrder(null)
        setEnableOrder(null)
        setBagTagOrder(null)
      } else{
        setBagTagOrder(sorter.order)
        setFileNameOrder(null)
        setCreatedAtOrder(null)
        setEnableOrder(null)
      }
      order = (sorter.order == null ? '' : (sorter.order === 'descend' ? '-' : '') + sorter.field)
      setPlanSort(order)
      fetchFileList(order)
    }
    updateSort('post', { plan_sort: order })
  }
  const makeTeachPlan = () => {
    const successDeal = () => {
      const now = new Date()
      message.success(`已根据您在 ${moment(new Date(now)).format('YYYY-MM-DD HH:mm')} 的课程包权限，生成了教学计划`);
      fetchFileList()
      setShowTip(false)
      clearInterval(resetTimer)
    }
    setShowTip(true)
    resetTeachPlan().then(res => {
      clearInterval(resetTimer)
      resetTimer = setInterval(() => {
        getResetsStatus().then((res) => {
          if (res.data.status === 'success') {
            successDeal()
          }
        })
      }, 10000);
    }).catch(err => {
      // 状态码为400？
      if(err?.response?.data?.message){
        message.error(err.response.data.message);
      }
    })
  }
  const changeSearch = useCallback((val) => {
    let timer;
    setSearch(val)
    clearTimeout(timer)
    let list = backupsList
    setLoading(true)
    timer = setTimeout(() => {
      if (val) {
        list = backupsList.filter(f => f.course_bag_tag.includes(val) || f.file_name.includes(val))
      } else {
        list = backupsList
      }
      localDispatch({
        type: types.FETCH_FILE_SUCCESS,
        list
      });
      setLoading(false)
    }, 300);
  }, [localDispatch,backupsList]);
  return (
    <div className="teachplan-manage">
      <header className="header">
        <div className="container">
          <h1 className="title">教学计划管理</h1>
        </div>
      </header>
      <div className="container">
        <div className="wrapper">
          <section className="teachplan-descr">
            <h2 className="title">定制教学计划</h2>
            <p className="text">
              可以根据教学工作需要，在已授权的
              <a href="/course" style={{ color: '#0084ff' }}>
                教学课程
              </a>
              中选择合适的课程纳入你的教学计划中。
              <br />
              找到合适的内容后，可以将标题、链接复制下来，整理到教学计划中，方便取用。
              <a
                href="https://e-course.oss-cn-shenzhen.aliyuncs.com/2020_2021%E5%AD%A6%E5%B9%B4%E4%B8%8A%E5%AD%A6%E6%9C%9F%E6%95%99%E5%AD%A6%E8%AE%A1%E5%88%92%E8%A1%A8.docx"
                style={{ color: '#0084ff' }}
              >
                下载教学计划模板
              </a>
              {/* 若你需要开展 PBL 教学或是寻找一些小项目，作为课堂的素材，可以进入
              <a href="/project" style={{ color: '#0084ff' }}>
                探索项目
              </a>
              ，这里的每份内容都将指导学生去做出一个作品。
              <br />
              若你需要进行一些基础概念的细致讲解或是希望学生熟悉一些设备的入门操作，可以进入
              <a href="/course" style={{ color: '#0084ff' }}>
                教学课程
              </a>
              ，这里的每份内容都按认知顺序编辑成组。
              <br />
              找到合适的内容后，可以将标题、链接复制下来，整理到教学计划中，方便取用。
              <a
                href="https://oss.longan.link/download/2020%7E2021%E5%AD%A6%E5%B9%B4%E4%B8%8A%E5%AD%A6%E6%9C%9F%E6%95%99%E5%AD%A6%E8%AE%A1%E5%88%92%E8%A1%A8.docx"
                style={{ color: '#0084ff' }}
              >
                下载教学计划模板
              </a> */}
              {
                userInfo.organization_data.auto_plan && userInfo.organization_data.allow_course_bag && <>
                  <p className="text">当前校区被上级主管单位设为了以课程包为单位分配课程，并同步生成教学计划。若教学计划没有显示或内容不准，可以在此<span style={{ color: '#0084ff', cursor: 'pointer' }} onClick={makeTeachPlan}>手动重新生成教学计划</span>。</p>
                  {showTip && <p style={{ color: '#faad14'}} >现有课程包权限所关联的教学计划已清除，正在重新生成，预计需要一分钟，请在此等候。</p>}</>
              }
            </p>
          </section>
          <section className="teachplan-file">
            <section className="teachplan-upload">
              <h2 className="title">
                提交教学计划</h2>
              <Upload
                accept=".docx,.xlsx,.xls,.doc,.pdf"
                customRequest={onUpload}
                showUploadList={false}
              >
                <Button disabled={getUpDisStatus()}>
                  <UploadOutlined /> 选择文件
                </Button>
              </Upload>
              {commitFile && selectFile ? (
                <Button
                  style={{ marginLeft: 10 }}
                  type="primary"
                  // loading={uploading}
                  disabled={disabled}
                  onClick={deBounce(uploadFile, 0)}
                >
                  提交
                </Button>
              ) : null}
              <div className="filelist">{getFileMsg(commitFile)}</div>
            </section>
            <section className="teachplan-record">
              <div className='tool'>
                <div className="title">
                  <h2>教学计划列表</h2>
                  <Tooltip
                    className="tooltip"
                    getPopupContainer={() =>
                      document.querySelector('.teachplan-record')
                    }
                    style={{ minWidth: 'auto' }}
                    placement="top"
                    title={`平台只存储你最近上传或校区其他教职工共享给你的 50 个教学计划，数量达到上限时你将无法继续上传教学计划（系统根据课程包自动生成的教学计划不受此数量限制）`}
                  >
                    <img
                      src="/image/icon_question.png"
                      alt="question"
                      className="tip"
                    />
                  </Tooltip>
                </div>
                <div className='search'>
                  <Input placeholder='请输入文件或阶段名称' value={search} onChange={(e)=>changeSearch(e.target.value)}></Input>
                </div>
              </div>
              <Table
                columns={columns}
                loading={loading}
                dataSource={ fileList }
                onChange={onChange}
                rowKey={'id'}
                pagination={false}
                defaultSortOrder={planSort}
              />
            </section>
          </section>
        </div>
      </div>
      <Modal
        closable={false}
        className="deleteConfirmModal"
        visible={toggleDelModal}
        footer={null}
        width={480}
        centered={true}
        getContainer={false}
      >
        <div className="title">
          <InfoCircleFilled className="errorIcon" />
          <span className="detail">是否确定删除该文件？</span>
        </div>
        <p className="content">删除后不可复原</p>
        <div className="action">
          <Button
            className="actionOk"
            onClick={() => localDispatch({ type: types.TOGGLE_DEL_MODAL })}
          >
            取消
          </Button>
          <Button danger onClick={deleteFile}>
            确定
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TeachPlanManage;
