import React, {
  useState,
  useEffect
} from 'react';
import './style.scss';
import '../../../utils/md-toc.js'
import { useRouteMatch, Link } from 'react-router-dom';
import { Card, BackTop, List, Empty } from 'antd';
import { getCourseBag } from '../../../utils/request';

const CourseBagItem = () => {
  const match = useRouteMatch();
  const [courseBag, setCourseBag] = useState({
    courses:[]
  });
  useEffect(() => {
    getCourseBag(match.params.id).then(res => {
      setCourseBag(res.data)
    })
  }, [match.params.id])
  

  return (
    <div id="courseBagItem">
      <BackTop />
      <div className="detail">
        <div className='container'>
          <h3>
            {courseBag.title}
          </h3>
          <p className='line-border'>
            <span>
              <span className='field'>适合阶段：</span>{courseBag.new_suit_tags}
            </span>
            <span>
              <span className='field'>编程平台：</span>{courseBag.new_program_platform}
            </span>
            <span>
              <span className='field'>版本：</span>V{courseBag.version}
            </span>
          </p>
          <p>
            <span className='teaching_aid'>
              <span className='field'>课程教具：</span><span className='teaching_aid_val'>{courseBag.new_teaching_aid}</span>
            </span>
          </p>
        </div>
      </div>
      <div className='courses'>
        <div className="container">
          <div className='course'>
            {
              courseBag.courses.length > 0 ? <List
              grid={{
                gutter: 30,
                xs: 1,
                sm: 1,
                md: 4,
                lg: 4,
                xl: 4,
                xxl: 4
              }}
              dataSource={courseBag.courses}
              renderItem={item => (
                <List.Item key={item.id}>
                  <Link
                    target="_blank"
                    to={'/course/' + item.id + '/' + (item.unit_id || 0)}
                  >
                    <Card
                      hoverable
                      style={{
                        height:'302px'
                      }}
                      cover={
                        <img
                          src={item.cover || '/image/course.png'}
                          alt={item.title}
                        />
                      }
                    >
                      <div className="title">{item.title}</div>
                      <div className="content">{`${item.description}`}</div>
                    </Card>
                  </Link>
                </List.Item>
              )}
            />:
              <Empty
                image='/image/empty-page_course.png'
                description={<div> <p className='title'>暂无课程</p> </div>}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseBagItem;
