import React, {
  useState,
  useEffect
} from 'react';
import './style.scss';
import '../../../utils/md-toc.js'
import { useRouteMatch } from 'react-router-dom';

const CoursePPT = () => {
  const match = useRouteMatch();
  
  const [pptFurl, setPptFurl] = useState('')
  useEffect(() => {
    const id = match.params.id
    const video = JSON.parse(localStorage.getItem('CoursePPT'))
    if (video && video[id]) {
      setPptFurl(video[id])
    }
  }, [match.params.id])


  return (
    <div id="CoursePPT">
      <iframe
        title="pptIframe"
        className="iframe"
        src={pptFurl}
        style={
          {
            borderRadius: '7px'
          }
        }
      >
      </iframe>
    </div>
  )

};

export default CoursePPT;
