import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import './style.scss'
import {
  Button,
  Input,
  Table,
  Modal,
  Steps,
  Form,
  message,
  Select,
  Tooltip,
  Divider,
  Radio
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { createTeam, getTeam } from './../../../utils/request'
import Icon from './../../../components/icon'
import qs from 'query-string'
import { secretImageUrl, deBounce } from './../../../utils'
import {
  setTeamCount,
  getUser,
} from '../../../store/actions'
import { PlusOutlined } from '@ant-design/icons'
import { getEmployeeList, fetchName } from './../../../utils/api/manageCenter'
import ModifyTeamInfo from './modifyTeamInfo'

const Team2 = (props, ref) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const modifyTeamInfoRef = useRef()
  const userInfo = useSelector((state) => state.userInfo)
  // const teamCount = useSelector((state) => state.teamCount);
  const orgNum = useSelector((state) => state.orgNum)
  const [data, setData] = useState([])
  const [pageSize, setPageSize] = useState(8)
  const [showCreateTeam, setShowCreateTeam] = useState(
    qs.parse(history.location.search).create === 'new',
  )
  const [nowStep, setNowStep] = useState(0)
  const formRef = useRef()
  const [formData, setFormData] = useState({
    name: '',
    team_teacher: [userInfo?.id],
    description: '',
    exam_platform: 2,
    oj_platform: 1
  })
  const [imageFile, setImageFile] = useState(null)
  const [loginType, setLoginType] = useState()
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(
    Number(qs.parse(history.location.search).page) || 1,
  )
  const [curRecord, setCurRecord] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const searchRef = useRef()
  const fetchFlag = useRef()
  const [isdisabled, setIsdisabled] = useState(true)
  
  const [orderStr, setOrderStr] = useState('');
  const [teacherList, setTeacherList] = useState([])
  const [loading, setLoading] = useState(false);
  const [submitErr, setSubmitErr] = useState({ name: null }) // eslint-disable-line
  const service_validity = useSelector((state) => state.service_validity)

  const isValidityAbled = service_validity === 3 || service_validity === 4

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      sorter: true,
      render: (text, record) => (
        <span
          // className="name"
          className={isValidityAbled ? 'name' : 'num_studios name'}
          onClick={() => {
            if (!isValidityAbled) {
              history.push({
                pathname: `/manageCenter/team/${record.code}/detail`,
                state: {
                  prevSearch: history.location.search,
                  code: record.code,
                },
              })
            }
          }}
        >
          <img src={record.cover} alt={text} />
          {text}
        </span>
      ),
    },
    {
      title: '团队编号',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '授课教师',
      dataIndex: 'team_teacher',
      align: 'left',
      sorter: true,
      render: (value, record) => (
        <>
          {record.team_teacher.length === 0
            ? '-'
            : record.team_teacher
                .map((item) => {
                  return item.regional_username || item.name || item.username
                })
                .join(' | ')}
        </>
      ),
      key: 'team_teacher',
    },
    {
      title: '作品集',
      sorter: true,
      dataIndex: 'num_studios',
      align: 'left',
      render: (value, record) => value,
      key: 'num_studios',
    },
    {
      title: '学员数',
      sorter: true,
      dataIndex: 'num_student',
      align: 'left',
      render: (value, record) => value,
      key: 'num_student',
    },
    {
      title: '创建时间',
      sorter: true,
      key: 'created_at',
      align: 'left',
      render: (time) => new Date(time).toLocaleDateString(),
      dataIndex: 'created_at',
    },
    {
      title: '操作',
      key: 'operate',
      align: 'left',
      width: 320,
      render: (value, record) => (
        <div className="operate">
          <Button
            size="small"
            type="link"
            disabled={isValidityAbled}
            onClick={() => handleTeamInfo(record)}
          >
            修改
          </Button>
          <Divider type="vertical" />
          <Button
            size="small"
            type="link"
            disabled={isValidityAbled}
            onClick={() =>
              history.push({
                pathname: `/manageCenter/team/${record.code}/detail`,
                state: {
                  prevSearch: history.location.search,
                  code: record.code,
                },
              })
            }
          >
            学员管理
          </Button>
          <Divider type="vertical" />
          <Button
            size="small"
            type="link"
            disabled={isValidityAbled}
            onClick={() =>
              history.push({
                pathname: `/manageCenter/team/courseManage/${record.code}`,
                state: {
                  prevSearch: history.location.search,
                  code: record.code,
                },
              })
            }
          >
            课程管理
          </Button>
          {JSON.stringify(userInfo)?.organization_data !==
              '{}' && userInfo?.organization_data?.overall_organization_data ? (
                isValidityAbled ? 
                <>
                  <Divider type="vertical" />
                <span style={{cursor: 'not-allowed',color: '#bfc6d0'}}>数据报表</span> 
                </>
                : 
            <>
              <Divider type="vertical" />
              <Link
                target="_blank"
                to={`/manage/team/${record.code}/datacenter?id=${record.id}&isManagerCenter=${true}`}
              >
                数据报表
              </Link>
            </>
          ) : null}
        </div>
      ),
      fixed: 'right',
      dataIndex: 'operate',
    },
  ]

  useImperativeHandle(ref, () => ({
    createTeamHandler: () => setShowCreateTeam(true),
  }))

  const handleTeamInfo = (record) => {
    setCurRecord(record)
    modifyTeamInfoRef.current.modifyTeamInfoHandler()
  }
  useLayoutEffect(() => {
    document.querySelector(
      '.ant-table-pagination .ant-select-selection-item',
    ) &&
      (document.querySelector(
        '.ant-table-pagination .ant-select-selection-item',
      ).innerText = `${pageSize} 条/页`)
  }, [data]) // eslint-disable-line
  const getAllTeam = useCallback(() => {
    setLoading(true)
    return getTeam({
      from_type: '管理中心',
      page: current,
      page_size: pageSize,
      ...(searchValue ? { q: searchValue } : null),
      ...(orderStr ? { order_str: orderStr } : null)
    })
      .then((res) => {
        setData(
          res.data.results.map((item, index) => ({
            ...item,
            key: index,
            cover: secretImageUrl + item.cover.replace('covers/', ''),
          })),
        )
        setTotal(res.data.count)
        setLoading(false)
        dispatch(
          setTeamCount(
            res.data.count >= userInfo?.team_data?.max_team ||
              userInfo?.team_data?.max_team === 0,
          ),
        )
      })
      .catch((e) => {
        console.error(e)
      })
  }, [current, pageSize, searchValue, userInfo, dispatch, orderStr])

  const onSearchHandler = useCallback(
    (value) => {
      history.push({
        path: history.location.pathname,
        search: qs.stringify({ page: 1 }),
      })
      setCurrent(1)
      setSearchValue(value)
    },
    [history],
  )

  const verifyName = (value) => {
    fetchName({ name: value.name })
      .then((res) => {
        setFormData(value)
        setNowStep(nowStep + 1)
        setSubmitErr({ name: null })
      })
      .catch((e) => {
        if (e.response.data && typeof e.response.data === 'object') {
          setSubmitErr({
            name: {
              help: e.response.data.message,
              validateStatus: 'error',
            },
          })
        }
      })
  }

  const createTeamHandler = useCallback(() => {
    if (nowStep === 0) {
      formRef.current.submit()
    } else {
      if (fetchFlag.current) return
      fetchFlag.current = true

      let config = {
        ...formData,
        key_type: loginType,
        cover: imageFile,
      }

      createTeam(config)
        .then((res) => {
          if (res.data.created_at) {
            setNowStep(0)
            setShowCreateTeam(false)
            message.success('新建团队成功')
            getAllTeam()
            // userInfo.getSupBigTeamCount()
            // userInfo.name = '111'
            history.replace('/manageCenter/team')
            dispatch(getUser())
          }
        })
        .catch((e) => {
          if (typeof e.response.data === 'string') return
          message.error(e.response.data.message || '新建团队失败，请稍后再试')
        })
        .finally(() => {
          const timer = setTimeout(() => {
            fetchFlag.current = false
            clearTimeout(timer)
          }, 800)
        })
    }
  }, [nowStep, formData, loginType, imageFile, getAllTeam, dispatch, history])

  useLayoutEffect(() => {
    if (nowStep === 0 && showCreateTeam && formRef.current) {
      formRef.current.setFieldsValue({
        name: '',
        team_teacher: [userInfo?.id],
        description: '',
        exam_platform: 2,
        oj_platform: 1
      })
      setFormData({ name: '', team_teacher: [userInfo?.id], description: '', exam_platform: 2, oj_platform: 1 })
      setLoginType()
    }
  }, [showCreateTeam, nowStep, userInfo])

  useEffect(() => {
    getAllTeam()
  }, [getAllTeam, current])

  const handlerModal = () => {
    setShowCreateTeam(true)
    getEmployeeList().then((res) => {
      setTeacherList(res?.data?.results)
    })
  }
  const onChange = (pagination, filters, sorter, extra) => {
    if(sorter.field){
      if(sorter.order && sorter.order === 'descend'){
        setOrderStr(`-${sorter.field}`)
      }else{
        setOrderStr(`${sorter.field}`)
      }
    }
};

  const isDisabled = orgNum?.remaining_team <= 0

  return (
    <div id="team">
      <div className="container">
        <div className="actionBar">
          <div className="btn">
            <div>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={handlerModal}
                disabled={isDisabled || isValidityAbled}
              >
                新建团队
              </Button>
              {isValidityAbled && (
                <Tooltip
                  placement="right"
                  title={
                    service_validity === 3
                      ? '英荔教学云服务还没开始，请耐心等待'
                      : '英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118'
                  }
                  overlayClassName="teamFloatingTooltip"
                >
                  <img
                    src="/image/icon_question.png"
                    alt="question"
                    className="tip"
                  />
                </Tooltip>
              )}
              {!isValidityAbled && isDisabled && (
                <Tooltip
                  placement="right"
                  title="当前新建团队的数量已达上限，如需增加团队数量，请致电 400-931-8118"
                  overlayClassName="teamFloatingTooltip"
                >
                  <img
                    src="/image/icon_question.png"
                    alt="question"
                    className="tipImg"
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <div className="search">
            <Input.Search
              ref={searchRef}
              disabled={loading}
              placeholder="搜索团队名称/授课教师"
              onSearch={onSearchHandler}
              style={{ width: 280, borderRadius: '4px' }}
            />
          </div>
        </div>
        <div className="table">
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: 1220 }}
            loading={loading}
            pagination={{
              current: current,
              total,
              showQuickJumper: true,
              defaultPageSize: pageSize,
              onChange: (val) => {
                history.push({
                  path: history.location.pathname,
                  search: qs.stringify({
                    ...qs.parse(history.location.search),
                    page: val,
                  }),
                })
                setCurrent(val)
              },
              onShowSizeChange: (cur, size) => {
                history.push({
                  path: history.location.pathname,
                  search: qs.stringify({
                    ...qs.parse(history.location.search),
                    page: 1,
                  }),
                })
                setCurrent(1)
                setPageSize(size)
              },
              showTotal: (total, range) =>
                `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`,
            }}
            onChange={onChange}
          />
          <div className="pagination2">
            <div className="teamAction">
              <a
                href={`https://${window.location.host}/course/38/0`}
                rel="noopener noreferrer"
                target="_blank"
              >
                不知道团队要怎么用？
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal
        closeIcon={
          <img src="/image/close.png" alt="close" width="14px" height="14px" />
        }
        className="createTeam"
        visible={showCreateTeam}
        centered={true}
        getContainer={false}
        width={505}
        onOk={() => setShowCreateTeam(false)}
        onCancel={() => {
          history.replace('/manageCenter/team')
          setShowCreateTeam(false)
        }}
        afterClose={() => {
          setNowStep(0)
          setSubmitErr({ name: null })
        }}
        footer={
          <Button
            type="primary"
            disabled={
              (nowStep > 0 && !loginType) || (nowStep === 0 && isdisabled)
            }
            onClick={deBounce(createTeamHandler, 200)}
          >
            {nowStep ? '完成' : '下一步'}
          </Button>
        }
        destroyOnClose={true}
        maskClosable={false}
      >
        <div className="content">
          <Steps
            direction="vertical"
            size="small"
            current={nowStep}
            progressDot={true}
          >
            <Steps.Step title="第一步" description="填写团队信息" />
            <Steps.Step title="第二步" description="团队登录方式" />
            <Steps.Step title="第三步" description="新建团队" />
          </Steps>
          {nowStep === 0 ? (
            <div className="teamInfo">
              <div className="icon">
                <Icon
                  onLoad={setImageFile}
                  random={true}
                  onChange={setImageFile}
                  type="team"
                />
              </div>
              <div>
                <Form
                  ref={formRef}
                  initialValues={formData}
                  layout="vertical"
                  onValuesChange={(value, allValue) => {
                    if (allValue.name && allValue.team_teacher.length > 0) {
                      setIsdisabled(false)
                    } else {
                      setIsdisabled(true)
                    }

                    setFormData(allValue)
                  }}
                  onFinish={(value) => {
                    verifyName(value)
                  }}
                >
                  <Form.Item
                    label={<span>团队名称</span>}
                    colon={false}
                    name="name"
                    rules={[
                      { required: true, message: '请输入团队名称' },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || (value && !/[,|，]/g.test(value))) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            '团队名称不能输入逗号「，」「,」',
                          )
                        },
                      }),
                    ]}
                    {...submitErr.name}
                  >
                    <Input
                      placeholder="请输入"
                      maxLength="15"
                      suffix={
                        <span className="suffix">{`${Math.min(
                          formData.name.length,
                          15,
                        )}/15`}</span>
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="授课教师"
                    name="team_teacher"
                    rules={[
                      { required: true, message: '请选择授课教师' },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || value.length <= 3) {
                            return Promise.resolve()
                          }
                          return Promise.reject('最多可选择 3 位授课教师')
                        },
                      }),
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement || document.body
                      }
                    >
                      {teacherList.map((list) => (
                        <Select.Option
                          key={list.user}
                          value={list.user}
                          label={list.name || list.username}
                        >
                          {list.name || list.username}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    label="一句话描述"
                    style={{marginBottom:'0px'}}
                    name="description"
                    rules={[{ required: false }]}
                  >
                    <Input.TextArea
                      maxLength="100"
                      onChange={(e) => {
                        // if (e.target.value.length > 100) {
                        //     formRef.current.setFieldsValue({description: e.target.value.slice(0, 100)})
                        // }
                      }}
                      style={{ height: '120px', resize: 'none' }}
                    />
                  </Form.Item>
                  <div className="textAreaSuffix">{`${
                    formData.description.length > 100
                      ? 100
                      : formData.description.length
                  }/100`}</div>
                  {
                    // eslint-disable-next-line
                    userInfo.organization_data.oj_platform == 2 && <Form.Item
                      colon={false}
                      label="编程题库："
                      name="oj_platform"
                      className='questionType'
                      rules={[{ required: false }]}
                    >
                      <Radio.Group >
                        <Radio value={2}>开启</Radio>
                        <Radio value={1}>不开启</Radio>
                      </Radio.Group>
                    </Form.Item>
                  }
                  {
                    // eslint-disable-next-line
                    userInfo.organization_data.exam_platform == 2 && <Form.Item
                      colon={false}
                      label="客观题库："
                      className='questionType'
                      name="exam_platform"
                      style={{ display: 'flex' }}
                      rules={[{ required: false }]}
                    >
                      <Radio.Group >
                        <Radio value={2}>开启</Radio>
                        <Radio value={1}>不开启</Radio>
                      </Radio.Group>
                    </Form.Item>
                  }
                </Form>
              </div>
            </div>
          ) : (
            <div className="loginType">
              <div className="typeItem">
                <div
                  className={`optional img ${
                    loginType === 'image' ? 'active' : ''
                  }`}
                  onClick={() => setLoginType('image')}
                >
                  {/* <img src="" alt="loginType"/> */}
                </div>
                <div className="tips">图片密钥：更适合低年龄学员</div>
              </div>
              <div className="typeItem">
                <div
                  className={`optional word ${
                    loginType === 'word' ? 'active' : ''
                  }`}
                  onClick={() => setLoginType('word')}
                >
                  dress high
                </div>
                <div className="tips">词组密钥：更适合高年龄学员 </div>
              </div>
              <div className="faq">
                <p>
                  请为您的团队学员选择一种密钥类型，团队中所有新创建的学员都将使用该类密码登录平台。
                </p>
                <p>注意：新建团队成功后将不可更改团队的密钥类型。</p>
              </div>
            </div>
          )}
        </div>
      </Modal>

      {/* 修改团队信息 */}
      <ModifyTeamInfo
        ref={modifyTeamInfoRef}
        fetchList={getAllTeam}
        patchItem={curRecord}
      />
    </div>
  )
}

export default forwardRef(Team2)
