import React from 'react';
import { Modal, Button } from 'antd';
import './index.scss';

const DownloadModal = props => {
  // console.log(`DownloadModal===`, props.modalType);
  const { isModalVisible, showModal, modalType } = props;

  const renderCozmo = () => {
    return (
      <>
        <h3>Cozmo</h3>
        <div className="content">
          <p>
            Android 用户请前往 “Google Play” 下载。或者下载下方的 APKPure 和
            Cozmo，并通过数据线传输到设备存储。
          </p>
          <p>在设备中先安装 APKPure，再在 APKPure 里直接安装 Cozmo。</p>
        </div>

        <div>
          <a href="https://e-course.oss-cn-shenzhen.aliyuncs.com/%E5%AE%89%E5%8D%93%E5%B9%B3%E6%9D%BF%E8%BD%AF%E4%BB%B6%E5%AE%89%E8%A3%85%E5%8C%85/APKPure.apk">
            <Button type="primary">APKPure 下载</Button>
          </a>
          <a href="https://e-course.oss-cn-shenzhen.aliyuncs.com/%E5%AE%89%E5%8D%93%E5%B9%B3%E6%9D%BF%E8%BD%AF%E4%BB%B6%E5%AE%89%E8%A3%85%E5%8C%85/Cozmo.xapk">
            <Button type="primary">Cozmo 下载</Button>
          </a>
        </div>
      </>
    );
  };

  const renderAnki = () => {
    return (
      <>
        <h3>Anki Overdrive</h3>
        <div className="content">
          <p style={{ marginBottom: '24px' }}>
            下载下方的 APKPure 和
            Overdrive，并通过数据线传输到设备存储。在设备中先安装 APKPure，再在
            APKPure 里直接安装 Overdrive。
          </p>
        </div>

        <div>
          <a href="https://e-course.oss-cn-shenzhen.aliyuncs.com/%E5%AE%89%E5%8D%93%E5%B9%B3%E6%9D%BF%E8%BD%AF%E4%BB%B6%E5%AE%89%E8%A3%85%E5%8C%85/APKPure.apk">
            <Button type="primary">APKPure 下载</Button>
          </a>
          <a href="https://e-course.oss-cn-shenzhen.aliyuncs.com/%E5%AE%89%E5%8D%93%E5%B9%B3%E6%9D%BF%E8%BD%AF%E4%BB%B6%E5%AE%89%E8%A3%85%E5%8C%85/Anki%20OVERDRIVE.xapk">
            <Button type="primary">Overdrive 下载</Button>
          </a>
        </div>
      </>
    );
  };

  const renderScratchLink = () => {
    return (
      <>
        <h3>Scratch Link</h3>
        <div className="content">
          <p>在国内使用，需要修改本机 hosts 文件。</p>
          <p>
            添加如下规则：
            <br />
            127.0.0.1 device-manager.scratch.mit.edu
          </p>
          <p>
            以下是不同系统的 hosts 文件所在位置：
            <br />
            <span>Windows: </span>C:\Windows\System32\drivers\etc\hosts
            <br />
            <span>macOS: </span>/etc/hosts
            <br />
            <span>Android: </span>/system/etc/hosts
          </p>
        </div>
      </>
    );
  };
  const renderAimakerAdapter = () => {
   return (
    <>
      <h3>英荔 Adapter 教程</h3>
      <div className="content">
        <p>下载英荔 Adapter 后，点击下方按钮观看安装教程视频。</p>
      </div>

      <div className='adapter-course'>
        <a href="https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/03b9a41f1397757896759347405/v.f40672.mp4" target='blank'>
          <Button type="primary">Windows 教程</Button>
        </a>
        <a href="https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/b159b16a387702307244023818/v.f39758.mp4" target='blank'>
          <Button type="primary">macOS 教程</Button>
        </a>
      </div>
    </>
   ) 
  }

  return (
    <Modal
      visible={isModalVisible}
      closeIcon={
        <img src="/image/close.png" alt="close" width="14px" height="14px" />
      }
      onCancel={() => showModal(false)}
      footer={null}
      destroyOnClose={true}
      centered
      width={450}
      wrapClassName="software_modal"
    >
      {modalType === 'cozmo'
        ? renderCozmo()
        : modalType === 'overdrive'
        ? renderAnki()
        : modalType === 'scratchlink'
        ? renderScratchLink()
        : modalType === 'aimaker adapter'
        ? renderAimakerAdapter()
        : null}
    </Modal>
  );
};

export default DownloadModal;
