import React, {
  useRef,
  useCallback,
  forwardRef,
  useState,
  useEffect,
  useLayoutEffect
} from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, Table, message, Tooltip, Divider, Modal } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons'
import './style.scss';
import qs from 'query-string';
import CreateEmployee from './create';
import ModifyEmployee from './modify';
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import {
  getEmployeeList,
  deleteEmployee,
  resetPassword
} from './../../../utils/api/manageCenter';
import { getTeam, getCourse, getCourseBags, removeToCampus, removeUser} from './../../../utils/request';
import ConfirmModal from './../../../components/confirmModal';
import { getUser } from './../../../store/actions';

const Employee = (props, ref) => {
  const history = useHistory();
  const searchRef = useRef();
  const childRef = useRef();
  const childRef2 = useRef();
  const userInfo = useSelector(state => state.userInfo);
  // const teamCount = useSelector((state) => state.teamCount);
  const [data, setData] = useState([]);
  const [modifyItem, setModifyItem] = useState(null);
  const [allTeam, setAllTeam] = useState([]);
  const [allCourse, setAllCourse] = useState([]);
  const [allCourseBag, setAllCourseBag] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(
    Number(qs.parse(history.location.search).page) || 1
  );
  const [pageSize, setPageSize] = useState(15);
  const [orderStr, setOrderStr] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const service_validity = useSelector(state => state.service_validity);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isValidityAbled = service_validity === 3 || service_validity === 4;

  useLayoutEffect(() => {
    document.querySelector(
      '.ant-table-pagination .ant-select-selection-item'
    ) &&
      (document.querySelector(
        '.ant-table-pagination .ant-select-selection-item'
      ).innerText = `${pageSize} 条/页`);
  }, [data]); // eslint-disable-line

  const onSearchHandler = useCallback(
    value => {
      history.push({
        path: history.location.pathname,
        search: qs.stringify({ page: 1 })
      });
      setCurrent(1);
      setSearchValue(value);
    },
    [history]
  );

  useEffect(() => {
    fetchEmployeeList()
    // eslint-disable-next-line
  }, [current, pageSize, searchValue, userInfo, orderStr]);

  const fetchEmployeeList = () => {
    setLoading(true)
    getEmployeeList({
      page: current,
      page_size: pageSize,
      ...(searchValue ? { seach_str: searchValue } : null),
      ...(orderStr ? { order_str: orderStr } : null)
      
    }).then(res => {
        setData(res?.data?.results);
        setTotal(res?.data?.results?.count);
        setLoading(false)
      })
  }

  const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      width: 170,
      fixed: 'left',
      sorter: true,
      render: (value, record) => {
        return (
          <Tooltip placement="topLeft" title={record.status==="0" ? "已发出邀请，待对方确认" : null}>
            <span
              className='num_studios'
              onClick={() => {
                if (userInfo.username === record.username) {
                  history.push({
                    pathname: `/account/home`
                  })
                } else {
                  history.push({
                    pathname: `/account/home/${record.username}`
                  })
                }
              }
              }
            >{value}</span>
          </Tooltip>
        )
      }
    },
    {
      title: '用户名',
      key: 'username',
      align: 'left',
      dataIndex: 'username',
      width: 175
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'left',
      width: 145,
      key: 'phone'
    },
    {
      title: '所在团队',
      key: 'team',
      // width: 145,
      align: 'left',
      render: (value, record) => (
        <>
          {record.team.length === 0 ? (
            '-'
          ) : record.team.length > 1 ? (
            <p>
              共{' '}
              <Tooltip
                placement="top"
                title={record.team
                  .map(item => {
                    return item.name;
                  })
                  .join(' | ')}
                overlayClassName="teamTooltip"
              >
                <span className="hoverText">{record.team.length}</span>
              </Tooltip>{' '}
              个
            </p>
          ) : (
            record.team[0].name
          )}
        </>
      ),
      dataIndex: 'team'
    },
    {
      title: '学员权限',
      key: 'student_nums',
      align: 'left',
      width: 110,
      dataIndex: 'student_nums'
    },
    {
      title: '团队权限',
      key: 'team_nums',
      align: 'left',
      width: 110,
      dataIndex: 'team_nums'
    },
    {
      title: '作品集权限',
      key: 'studio_nums',
      align: 'left',
      width: 110,
      dataIndex: 'studio_nums'
    },
    {
      title: '加入日期',
      key: 'created_at',
      align: 'left',
      render: time => new Date(time).toLocaleDateString(),
      dataIndex: 'created_at',
      sorter: true,
  
    },
    {
      title: '操作',
      key: 'operate',
      align: 'left',
      width: 190,
      fixed: 'right',
      render: (value, record) => (
        <div className="operate">
          <Button
            size="small"
            disabled={isValidityAbled}
            type="link"
            onClick={() => modify(record)}
          >
            修改
          </Button>
          <Divider type="vertical" />
          <Button
            size="small"
            className="delete"
            type="link"
            disabled={isValidityAbled}
            onClick={() => {
              setDeleteModal([true, 'reset', record]);
            }}
          >
            重置密码
          </Button>
          <Divider type="vertical" />
          <Button
            size="small"
            className="delete"
            type="link"
            disabled={isValidityAbled}
            onClick={() => {
              const flag = record.team.length > 0 && record.team_only_teacher ? 'cannot_delete' : 'delete'
              setDeleteModal([true, flag, record]);
            }}
          >
            删除
          </Button>
        </div>
      ),
      dataIndex: 'operate'
    }
  ];

  const getAllData = () => {
    return new Promise((resolve, reject) => {
      Promise.all([getTeam({ from_type: '管理中心', page: 1, page_size: 9999 }), getCourse({ from_type: '管理中心', page: 1, page_size: 9999 }), getCourseBags({ from_type: '管理中心', page: 1, page_size: 9999 })]).then(res => {
        setAllTeam(res[0].data.results || []);
        setAllCourse(res[1].data || []);
        setAllCourseBag(res[2].data || []);
        resolve()
      })
    })
  }
  const modify = item => {
    childRef.current.modifyEmployeeHandler();
    childRef.current.loadingEmployee(true);
    setModifyItem(item);
    getAllData().then(res => {
      childRef.current.loadingEmployee(false);
    })
  };
  const create = () => {
    getAllData().then(res => {
      childRef2.current.createEmployeeHandler();
    })
  };

  // 删除员工
  const deleteTeamHandler = useCallback(
    id => {
      const initList = () => {
        history.replace('/manageCenter');
        dispatch(getUser());
      }
      deleteEmployee(id)
        .then(res => {
          message.success('删除员工成功');
          // is_campus_manager true  下
          setDeleteModal(false);
          if(res.data.check_again){
            if (showDeleteModal[2].is_campus_manager) {
              console.log('is_campus_manager');
              initList()
              return
            }
            const multipleCampus = res.data.campus_count > 1;
            const meanwhileManager = showDeleteModal[2].is_manager && showDeleteModal[2].user === userInfo.id
            Modal.confirm({
              title: multipleCampus ? '是否将此账户移出校区？' : '是否申请删除此账户？',
              centered: true,
              icon: <InfoCircleFilled style={{ color: '#0084FF' }} />,
              width: 535,
              content:
                <div className='text'>
                  {
                    showDeleteModal[2].is_manager && <span>{showDeleteModal[2].user === userInfo.id ? '你同时具有当前校区的教学云管理员身份。' : '当前教师同时具有当前校区的教学云管理员身份。'}
                  <br /></span>
                  }
                  {
                    meanwhileManager 
                      ? (<>移除教师身份后，你仍然可以以非教师身份登录教学云，<span>其仍会占用校区的教职工人数限额</span>，导致新员工难以注册教学云账户。<br />{multipleCampus ? '你不可以将自己移出校区' : '你不可以申请删除自己的账户'}，请联系其他教学云管理员处理。</>
                      )
                    : <>
                        移除教师身份后其账户仍然可以以非教师身份登录教学云，<span>其仍会占用校区的教职工人数限额</span>，导致新员工难以注册教学云账户。<br />
                        {
                          !multipleCampus ? <> 如存在员工离职的情况，建议直接向英荔教育总部申请<span>删除此账户来释放校区的教职工人数限额</span>。</> : <> 如存在员工离职的情况，建议将其移出当前校区<span>来释放校区的教职工人数限额</span>。
                          </>
                        }
                      </>
                  }
                  
                </div>,
              cancelText: meanwhileManager ? '取消' : `暂不${multipleCampus ? '移出' : '申请'}`,
              okText: meanwhileManager ? `暂不${multipleCampus ? '移出' : '申请'}` : `${multipleCampus ? '移出' : '申请'}`,
              onOk: () => {
                if (!meanwhileManager) {
                  if (multipleCampus) {
                    removeToCampus(showDeleteModal[2].id).then(res => {
                      message.success('移出成功');
                    })
                  } else {
                    removeUser(showDeleteModal[2].id).then(res => {
                      message.success('申请成功');
                    })
                  }
                }
                setTimeout(() => {
                  initList()
                }, 200);
              },
              onCancel: () => {
                initList()
              }
            })
          }else{
            initList()
          }
        })
        .catch(e => {
          if (typeof e.response.data === 'string') return;
          if (e.response.status === 400) {
            message.warning(e.response.data.message);
          } else {
            message.error('操作失败，请稍后再尝试');
          }
        });
    },
    [history, dispatch, showDeleteModal, userInfo]
  );

  const handlerReset = employee_id => {
    resetPassword(employee_id)
      .then(() => {
        message.success('重置密码成功');
        setDeleteModal(false);
      })
      .catch(() => {
        message.error('重置密码失败');
      });
  };

  const modalRender = useCallback(() => {
    if (!showDeleteModal) {
      return;
    }
    const { is_new, status } = showDeleteModal[2]
    let title = '', content = ''
    if(showDeleteModal[1] === 'delete'){
      if (status === '1') {
          //非唯一授课教师
          title = "是否确定移除此教职工在本校区的教师身份？"
          content = <><span>当前用户是本校区的在职教师，</span>删除后，<span>此教职工将失去教师身份，</span>无法管理本校区的团队和学员。他在本校区所拥有的其他角色不受影响。<br />删除后，<span>他所创建的团队、团队作品集、学员都将转移到你的名下</span>（你会被记录为这些项目的创建者）。<br />区域作品集属于区域组织的资产，<span>此用户仍然对其创建的区域作品集拥有最高权限。</span></>
      }
      if ((status === '0' && !is_new) || is_new) { //其他校区或者新用户
        title = "是否确定移除此用户？"
        content = <><span>当前用户还未激活教学云新账户或是还未接受邀请加入当前校区。</span>删除后，会<span>直接将当前用户从本校区的教职工名单中移除，</span>你邀请过此用户的记录将会从他的账户中擦除。<br />未激活且不属于任何校区的教学云账户，会被系统自动收回。</>
      }
    }else if(showDeleteModal[1] === 'cannot_delete'){ //唯一授课教师
      title = "暂时无法移除此教职工的教师身份"
      content = <>当前用户是本校区的在职教师，其当前有正在授课的团队，且他是团队中唯一的授课教师。<span>请先将这些团队移交给其他教师去授课，再移除其教师身份。</span></>
    }
    else{
      title = "是否确定重置密码？"
      content="重置密码成功后，将以短信方式把系统生成的新密码发到该员工的手机号里。"
    }
    const confirmConf = {
      title,
      content,
      handleCancel: () => setDeleteModal(false),
      handleConfirm: () =>
        showDeleteModal[1] === 'delete'
          ? deleteTeamHandler(showDeleteModal[2].id)
          : (showDeleteModal[1] === 'cannot_delete' ? setDeleteModal(false) : handlerReset(showDeleteModal[2].id))
    };

    return (
      <ConfirmModal
        title={confirmConf.title}
        visible={showDeleteModal}
        iconType={showDeleteModal[1] === 'delete' ? "error" : "warning"}
        onCancel={confirmConf.handleCancel}
        contentNode={<>{confirmConf.content}</>}
        bottomNode={
          <>
            {showDeleteModal[1] !== 'cannot_delete' && <Button onClick={confirmConf.handleCancel}>取消</Button>}
            <Button type={showDeleteModal[1] === 'cannot_delete' ? 'primary' : 'danger'} onClick={confirmConf.handleConfirm}>
              确定
            </Button>
          </>
        }
      />
    );
  }, [showDeleteModal, deleteTeamHandler]);

  const isDisabled = userInfo?.organization_data?.max_staff - userInfo?.organization_data?.staff_nums <= 0;
  const onChange = (pagination, filters, sorter, extra) => {
    if(sorter.field){
      if(sorter.field === 'name'){
        sorter.field = 'user__name'
      }
      if(sorter.order && sorter.order === 'descend'){
        setOrderStr(`-${sorter.field}`)
      }else{
        setOrderStr(`${sorter.field}`)
      }
    }
  };
  return (
    <div id="employee">
      <div className="container">
        <div className="con_top">
          <div className="btn">
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={create}
              disabled={(isDisabled && data.length === Number(userInfo?.organization_data?.max_staff)) || isValidityAbled}
            >
              新增教师
            </Button>
            {isValidityAbled && (
              <Tooltip
                placement="right"
                title={
                  service_validity === 3
                    ? '英荔教学云服务还没开始，请耐心等待'
                    : '英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118'
                }
                overlayClassName="teamFloatingTooltip"
              >
                <img
                  src="/image/icon_question.png"
                  alt="question"
                  className="tip"
                />
              </Tooltip>
            )}
            {!isValidityAbled && isDisabled && (
              <Tooltip
                placement="right"
                title="当前新建员工的数量已达上限，如需增加员工数量，请致电 400-931-8118"
                overlayClassName="teamFloatingTooltip"
              >
                <img
                  src="/image/icon_question.png"
                  alt="question"
                  className="tip"
                />
              </Tooltip>
            )}
          </div>
          <div className="search">
            <Input.Search
              ref={searchRef}
              disabled={loading}
              placeholder="搜索姓名/用户名/手机号"
              onSearch={onSearchHandler}
              style={{ width: 280, borderRadius: '4px' }}
            />
          </div>
        </div>

        <div className="table">
          <Table
            columns={columns}
            dataSource={data}
            rowKey={record => record.id}
            scroll={{ x: 1400 }}
            loading={loading}
            pagination={{
              current: current,
              total,
              showQuickJumper: true,
              defaultPageSize: pageSize,
              onChange: val => {
                history.push({
                  path: history.location.pathname,
                  search: qs.stringify({
                    ...qs.parse(history.location.search),
                    page: val
                  })
                });
                setCurrent(val);
              },
              onShowSizeChange: (cur, size) => {
                history.push({
                  path: history.location.pathname,
                  search: qs.stringify({
                    ...qs.parse(history.location.search),
                    page: 1
                  })
                });
                setCurrent(1);
                setPageSize(size);
              },
              showTotal: (total, range) =>
                `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`
            }}
            onChange={onChange}
          />
        </div>

        {modalRender(showDeleteModal)}

        <CreateEmployee
          ref={childRef2}
          allTeam={allTeam}
          allCourse={allCourse}
          allCourseBag={allCourseBag}
          fetchEmployeeList={fetchEmployeeList} />
        <ModifyEmployee
          ref={childRef}
          modifyItem={modifyItem}
          allTeam={allTeam}
          allCourse={allCourse} 
          allCourseBag={allCourseBag}
        />
      </div>
    </div>
  );
};

export default forwardRef(Employee);
