import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import './style.scss'
import {
  Button,
  Input,
  Table,
  Modal,
  Steps,
  Form,
  message,
  Tooltip,
  Select,
  Divider,
  Radio
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  createTeam,
  getTeam,
  getTeachersList,
  fetchName,
} from './../../../utils/request'
import Icon from './../../../components/icon'
import qs from 'query-string'
import { PlusOutlined } from '@ant-design/icons'
import { secretImageUrl, deBounce } from './../../../utils'
import {
  setPortfolioFlag,
  setStudentsFlag,
  getUser,
  setTeamCount,
} from '../../../store/actions'

const { Option } = Select

const Team = (props, ref) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state.userInfo)
  const isNoRole = userInfo.user_type.includes('null');
  // const portfolioFlag = useSelector((state) => state.portfolioFlag);
  // const studentsFlag = useSelector((state) => state.studentsFlag);

  const [data, setData] = useState([])
  const [pageSize, setPageSize] = useState(8)
  const [showCreateTeam, setShowCreateTeam] = useState(
    qs.parse(history.location.search).create === 'new',
  )
  const [nowStep, setNowStep] = useState(0)
  const formRef = useRef()
  const [formData, setFormData] = useState({
    name: '',
    team_teacher: [userInfo.id],
    description: '',
    exam_platform: 2,
    oj_platform: 1
  })
  // const teamCount = useSelector((state) => state.teamCount);
  // 可能为负数
  const manager_CanCreateTeam = useSelector(
    (state) => state.manager_CanCreateTeam,
  )
  const teacher_CanCreateTeam = useSelector(
    (state) => state.teacher_CanCreateTeam,
  )

  const manager_CanCreateStudios = useSelector(
    (state) => state.manager_CanCreateStudios,
  )
  const teacher_CanCreateStudios = useSelector(
    (state) => state.teacher_CanCreateStudios,
  )
  const manager_CanCreateStudent = useSelector(
    (state) => state.manager_CanCreateStudent,
  )
  const teacher_CanCreateStudent = useSelector(
    (state) => state.teacher_CanCreateStudent,
  )
  const [imageFile, setImageFile] = useState(null)
  const [loginType, setLoginType] = useState()
  const [employee, setEmployee] = useState([])

  const [orderStr, setOrderStr] = useState('');
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(
    Number(qs.parse(history.location.search).page) || 1,
  )
  const [submitErr, setSubmitErr] = useState({ name: null }) // eslint-disable-line
  const [searchValue, setSearchValue] = useState('')
  const searchRef = useRef()
  const fetchFlag = useRef()

  const service_validity = useSelector((state) => state.service_validity)
  const isValidityAbled = service_validity === 3 || service_validity === 4
  // 校区管理员或非校区教师
  const Manager_Or_NonOrgTeacher =
    userInfo?.user_type.indexOf('manager') !== -1 ||
    (userInfo?.user_type.indexOf('teacher') !== -1 &&
      JSON.stringify(userInfo?.organization_data) === '{}')

  const columns_teacher = {
    title: '授课教师',
    dataIndex: 'team_teacher',
    key: 'team_teacher',
    width: 200,
    render: (text, record) => {
      let str = record?.team_teacher
        .map((item, idx) => {
          return item.regional_username || item.name || item.username
        })
        .join(' | ')

      return <span>{record?.team_teacher.length ? str : '-'}</span>
    },
  }

  let columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      sorter:true,
      // width: 200,
      render: (text, record) => (
        <span
          className={isValidityAbled ? 'name' : 'num_studios name'}
          onClick={() => {
            if (!isValidityAbled) {
              history.push({
                pathname: `/manage/team/${record.code}`,
                state: { prevSearch: history.location.search },
              })
            }
          }}
        >
          <img src={record.cover} alt={text} />
          {
            record.campus_id !== userInfo.organization_data.id ? 
            (<Tooltip
              placement="top"
              title="此团队不属于当前学校，你在当前学校就职期间，不可管理此团队的课程、查看此团队的数据报表"
              overlayStyle={{
                maxWidth:'283px',
                width:'283px'
              }}
            >
              {text}
            </Tooltip>):text
          }
        </span>
      ),
    },
    {
      title: '团队编号',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '作品集',
      dataIndex: 'num_studios',
      align: 'left',
      sorter: true,
      render: (value, record) => {
        /*--- 校区教师、管理员 判断 其 is_now_create, false只可以查看和删除 --- */
        if (
          // userInfo?.user_type.indexOf("teacher") > -1 &&
          JSON.stringify(userInfo?.organization_data) !== '{}' &&
          !record.is_now_create
        ) {
          return (
            <span
            // className={isValidityAbled ? '' : 'num_studios'}
            // className="num_studios"
            // onClick={() => {
            //   if (isValidityAbled) return;
            //   history.push(
            //     `/manage/portfolio?create=new&code=${record.code}`
            //   );
            // }}
            >
              {value}
            </span>
          )
        }
        /*--- 校区教师、管理员 其 is_now_create 为 true 和 非校区教师 ---*/
        // 1. 有作品集数量则显示
        if (record.num_studios > 0) {
          return (
            <span
            // className={isValidityAbled ? '' : 'num_studios'}
            // onClick={() => {
            //   if (isValidityAbled) return;
            //   history.push(
            //     `/manage/portfolio?create=new&code=${record.code}`
            //   );
            // }}
            >
              {value}
            </span>
          )
        }

        if (isValidityAbled)
          return (
            <Tooltip
              placement="top"
              title={
                service_validity === 3
                  ? '英荔教学云服务还没开始，请耐心等待'
                  : userInfo?.user_type.indexOf('manager') > -1 ||
                    (userInfo?.user_type?.indexOf('teacher') > -1 &&
                      JSON.stringify(userInfo?.organization_data) === '{}')
                  ? '英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118'
                  : '英荔教学云服务已到期，如需继续使用，请告知校区管理员'
              }
            >
              <Button type="primary" disabled>
                添加团队作品集
              </Button>
            </Tooltip>
          )

        // 2. 作品集数量为0 ，可能达到上限按钮置灰  或  显示可添加按钮
        if (
          !isValidityAbled &&
          record.num_studios === 0 &&
          ((userInfo?.user_type.indexOf('manager') > -1 &&
            !(manager_CanCreateStudios > 0)) ||
            (userInfo?.user_type === 'teacher' &&
              !(teacher_CanCreateStudios > 0)))
        ) {
          return (
            <Tooltip
              placement="top"
              title={
                Manager_Or_NonOrgTeacher
                  ? '当前添加作品集的数量已达上限，如需增加作品集数量，请致电 400-931-8118'
                  : '当前添加作品集的数量已达上限，如需增加作品集数量，请联系校区管理员'
              }
            >
              <Button
                type="primary"
                onClick={() =>
                  history.push(
                    `/manage/portfolio?create=new&code=${record.code}`,
                  )
                }
                disabled={
                  // Boolean(-1) 为 true, 注意xx_CanCreateStudios的值可能为负数
                  userInfo?.user_type.indexOf('manager') > -1
                    ? !(manager_CanCreateStudios > 0)
                    : !(teacher_CanCreateStudios > 0)
                }
              >
                添加团队作品集
              </Button>
            </Tooltip>
          )
        } else if (!isValidityAbled) {
          return (
            <Button
              className="btn"
              onClick={() =>
                history.push(`/manage/portfolio?create=new&code=${record.code}`)
              }
              disabled={!userInfo?.team_data?.max_team_studios_num}
            >
              添加团队作品集
            </Button>
          )
        }
      },

      key: 'num_studios',
    },
    {
      title: '学员数',
      dataIndex: 'num_student',
      key: 'num_student',
      align: 'left',
      sorter: true,
      render: (value, record) => {
        /*--- 校区教师、管理员 判断 其 is_now_create, false只可以查看和删除 --- */
        if (
          // userInfo?.user_type.indexOf("teacher") > -1 &&
          JSON.stringify(userInfo?.organization_data) !== '{}' &&
          !record.is_now_create
        ) {
          return (
            <span
            // className={isValidityAbled ? '' : 'num_student'}
            // onClick={() => {
            //   if (isValidityAbled) return;
            //   history.push(`/manage/team/${record.code}`);
            // }}
            >
              {value}
            </span>
          )
        }

        /*--- 校区教师、管理员 其 is_now_create 为 true 和 非校区教师 ---*/
        // 1. 有学员数量则显示
        if (value > 0) {
          return (
            <span
            // className={isValidityAbled ? '' : 'num_student'}
            // onClick={() => {
            //   if (isValidityAbled) return;
            //   history.push(`/manage/team/${record.code}`);
            // }}
            >
              {value}
            </span>
          )
        }
        if (isValidityAbled)
          return (
            <Tooltip
              placement="top"
              title={
                service_validity === 3
                  ? '英荔教学云服务还没开始，请耐心等待'
                  : userInfo?.user_type.indexOf('manager') > -1 ||
                    (userInfo?.user_type?.indexOf('teacher') > -1 &&
                      JSON.stringify(userInfo?.organization_data) === '{}')
                  ? '英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118'
                  : '英荔教学云服务已到期，如需继续使用，请告知校区管理员'
              }
            >
              <Button type="primary" disabled>
                添加团队学员
              </Button>
            </Tooltip>
          )
        // 2. 学员数量为0 ，可能达到上限按钮置灰  或  显示可添加按钮
        if (
          record.num_student === 0 &&
          ((userInfo?.user_type.indexOf('manager') > -1 &&
            !(manager_CanCreateStudent > 0)) ||
            (userInfo?.user_type === 'teacher' &&
              JSON.stringify(userInfo?.organization_data) !== '{}' &&
              !(teacher_CanCreateStudent > 0)) ||
            (userInfo?.user_type === 'teacher' &&
              JSON.stringify(userInfo?.organization_data) === '{}' &&
              userInfo?.team_data?.max_team_people === 0))
        ) {
          return (
            <Tooltip
              placement="top"
              title={
                Manager_Or_NonOrgTeacher
                  ? '当前添加学员账号的数量已达上限，如需增加学员数量，请致电 400-931-8118'
                  : '当前添加学员账号的数量已达上限，如需增加学员数量，请联系校区管理员'
              }
            >
              <Button
                type="primary"
                onClick={() => history.push(`/manage/team/${record.code}`)}
                disabled={
                  // Boolean(-1) 为 true, 注意xx_CanCreateStudios的值可能为负数
                  userInfo?.user_type.indexOf('manager') > -1
                    ? !(manager_CanCreateStudent > 0)
                    : userInfo?.user_type === 'teacher' &&
                      JSON.stringify(userInfo?.organization_data) !== '{}'
                    ? !(teacher_CanCreateStudent > 0)
                    : !userInfo?.team_data?.max_team_people
                }
              >
                添加团队学员
              </Button>
            </Tooltip>
          )
        } else {
          return (
            <Button
              className="btn"
              onClick={() => history.push(`/manage/team/${record.code}`)}
              disabled={!userInfo?.team_data?.max_team_people}
            >
              添加团队学员
            </Button>
          )
        }
      },
    },
    {
      title: '创建时间',
      key: 'created_at',
      align: 'left',
      sorter: true,
      render: (time) => new Date(time).toLocaleDateString(),
      dataIndex: 'created_at',
    },
    {
      title: '操作',
      key: 'operate',
      align: 'left',
      render: (value, record) => (
        <div className="operate">
          <Button
            size="small"
            type="link"
            disabled={isValidityAbled}
            onClick={() =>
              history.push({
                pathname: `/manage/team/${record.code}`,
                state: { prevSearch: history.location.search },
              })
            }
          >
            学员管理
          </Button>
          {JSON.stringify(userInfo?.organization_data) !== '{}' &&
            record.is_now_create && (
              <>
                <Divider type="vertical" />
                <Button
                  size="small"
                  type="link"
                  disabled={isValidityAbled}
                  onClick={() =>
                    history.push({
                      pathname: `/manage/team/${record.code}/?type=courseManage`,
                      state: {
                        prevSearch: history.location.search,
                      },
                    })
                  }
                >
                  课程管理
                </Button>
              </>
            )}
          {JSON.stringify(userInfo)?.organization_data !==
              '{}' && userInfo?.user_type !== 'student' && userInfo?.organization_data?.overall_organization_data && record.is_now_create ? (
                isValidityAbled ? 
                <>
                  <Divider type="vertical" />
                <span style={{cursor: 'not-allowed',color: '#bfc6d0'}}>数据报表</span> 
                </>
                : 
            <>
              <Divider type="vertical" />
              <Link
                target="_blank"
                to={`/manage/team/${record.code}/datacenter?id=${record.id}`}
              >
                数据报表
              </Link>
            </>
          ) : null}
        </div>
      ),
      dataIndex: 'operate',
    },
  ]
  const onChange = (pagination, filters, sorter, extra) => {
    if(sorter.field){
      if(sorter.order && sorter.order === 'descend'){
        setOrderStr(`-${sorter.field}`)
      }else{
        setOrderStr(`${sorter.field}`)
      }
    }
};
  // useImperativeHandle 在使用 ref 时自定义暴露给父组件的实例值
  useImperativeHandle(ref, () => ({
    createTeamHandler: () => setShowCreateTeam(true),
    // fn: () => setShowCreateTeam(true),
  }))

  useLayoutEffect(() => {
    document.querySelector(
      '.ant-table-pagination .ant-select-selection-item',
    ) &&
      (document.querySelector(
        '.ant-table-pagination .ant-select-selection-item',
      ).innerText = `${pageSize} 条/页`)
  }, [data]) // eslint-disable-line

  const getAllTeam = useCallback(() => {
    return getTeam({
      page: current,
      page_size: pageSize,
      ...(searchValue ? { q: searchValue } : null),
      ...(orderStr?{order_str: orderStr}:null)
    })
      .then((res) => {
        setData(
          res.data.results.map((item, index) => ({
            ...item,
            key: index,
            cover: secretImageUrl + item.cover.replace('covers/', ''),
          })),
        )
        let num_studios = res.data.results.reduce((res, cur) => {
          return (res += cur.num_studios)
        }, 0)
        let num_student = res.data.results.reduce((res, cur) => {
          return (res += cur.num_student)
        }, 0)
        dispatch(
          setPortfolioFlag(
            num_studios >= userInfo?.team_data?.max_team_studios_num ||
              userInfo?.team_data?.max_team_studios_num === 0,
          ),
        )
        dispatch(
          setStudentsFlag(
            num_student >= userInfo?.team_data?.max_team_people ||
              userInfo?.team_data?.max_team_people === 0,
          ),
        )

        setTotal(res.data.count)

        dispatch(
          setTeamCount(
            res.data.count >= userInfo?.team_data?.max_team ||
              userInfo?.team_data?.max_team === 0,
          ),
        )
      })
      .catch((e) => {
        console.error(e)
      })
  }, [current, pageSize, searchValue, userInfo, dispatch, orderStr])
  const handleChange = (value) => {
    // console.log(`selected ${value}`);
  }
  const onSearchHandler = useCallback(
    (value) => {
      history.push({
        path: history.location.pathname,
        search: qs.stringify({ page: 1 }),
      })
      setCurrent(1)
      setSearchValue(value)
    },
    [history],
  )
  const checkTeacher = (rule, value) => {
    if (value && value.length && value.length <= 3) {
      return Promise.resolve()
    }
    if (value && value.length > 3) {
      return Promise.reject(new Error('最多可选择 3 位授课教师'))
    }
    return Promise.reject(new Error('请选择授课教师'))
  }
  const verifyName = (value) => {
    fetchName({ name: value.name.trim() })
      .then((res) => {
        setFormData(value)
        setNowStep(nowStep + 1)
        setSubmitErr({ name: null })
      })
      .catch((e) => {
        if (e.response.data && typeof e.response.data === 'object') {
          setSubmitErr({
            name: {
              help: e.response.data.message,
              validateStatus: 'error',
            },
          })
        }
      })
  }

  const createTeamHandler = useCallback(() => {
    if (nowStep === 0) {
      formRef.current.submit()
    } else {
      if (fetchFlag.current) return
      fetchFlag.current = true

      let config = {
        team_teacher: [userInfo.id],
        ...formData,
        key_type: loginType,
        cover: imageFile,
      }
      createTeam(config)
        .then((res) => {
          if (res.data.created_at) {
            setNowStep(0)
            setShowCreateTeam(false)
            message.success('新建团队成功')
            getAllTeam()
            dispatch(getUser())
            // userInfo.getSupBigTeamCount()
            // userInfo.name = '111'
            history.replace('/manage/team')
          }
        })
        .catch((e) => {
          if (typeof e.response.data === 'string') return
          if (e.response.status === 400) {
            message.error(e.response.data.message || '新建团队失败，请稍后再试')
            return
          }
          message.error('新建团队失败，请稍后再试')
        })
        .finally(() => {
          const timer = setTimeout(() => {
            fetchFlag.current = false
            clearTimeout(timer)
          }, 800)
        })
    }
  }, [
    nowStep,
    formData,
    userInfo.id,
    loginType,
    imageFile,
    getAllTeam,
    dispatch,
    history,
  ])

  useLayoutEffect(() => {
    if (nowStep === 0 && showCreateTeam && formRef.current) {
      formRef.current.setFieldsValue({
        name: '',
        team_teacher: [userInfo.id],
        description: '',
        exam_platform: 2,
        oj_platform: 1
      })
      if (userInfo?.user_type.indexOf('manager') > -1) {
        getTeachersList()
          .then((res) => {
            setEmployee(res.data.results)
          })
          .catch((err) => {
            console.log(err)
          })
      }

      setFormData({ name: '', team_teacher: [userInfo.id], description: '',exam_platform: 2,
      oj_platform: 1 })
      setLoginType()
    }
  }, [showCreateTeam, nowStep, userInfo.id, userInfo.user_type])

  useEffect(() => {
    getAllTeam()
  }, [getAllTeam, current])
  useEffect(() => {
    dispatch(getUser())
  }, [dispatch, history.location.pathname])
  if (
    userInfo?.user_type.indexOf('manager') !== -1 ||
    (userInfo?.user_type.indexOf('teacher') !== -1 &&
      JSON.stringify(userInfo?.organization_data) !== '{}')
  ) {
    columns.splice(2, 0, columns_teacher)
  }
  return (
    <div id="team">
      <div className="container">
        <div className="actionBar">
          <div className="btn">
            {
              !isNoRole ? <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => setShowCreateTeam(true)}
                disabled={
                  isValidityAbled ||
                  (userInfo?.user_type.indexOf('manager') > -1
                    ? !(manager_CanCreateTeam > 0)
                    : !(teacher_CanCreateTeam > 0))
                }
              >
                新建团队
              </Button> : null
            }
            {isValidityAbled && (
              <Tooltip
                placement="right"
                title={
                  service_validity === 3
                    ? '英荔教学云服务还没开始，请耐心等待'
                    : userInfo?.user_type.indexOf('manager') > -1 ||
                      (userInfo?.user_type?.indexOf('teacher') > -1 &&
                        JSON.stringify(userInfo?.organization_data) === '{}')
                    ? '英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118'
                    : '英荔教学云服务已到期，如需继续使用，请告知校区管理员'
                }
                overlayClassName="teamFloatingTooltip"
              >
                <img
                  src="/image/icon_question.png"
                  alt="question"
                  className="tip"
                />
              </Tooltip>
            )}
            {!isValidityAbled && (
              <Tooltip
                placement="right"
                // ====== before the change ====
                // title={() => {
                //   if (userInfo?.team_data?.max_team === 0) {
                //     return "当前账户无法新建团队，如需使用此功能，请致电 400-931-8118";
                //   } else if (teamCount) {
                //     return "当前账户新建团队的数量已达上限，如需增加团队数量，请致电 400-931-8118";
                //   } else {
                //     return `当前账户可以创建 ${userInfo?.team_data?.max_team} 个 ${userInfo?.team_data?.max_team_people} 人以下的团队`;
                //   }
                // }}
                // ======= end ========
                title={() => {
                  if (userInfo?.user_type.indexOf('manager') > -1) {
                    if (manager_CanCreateTeam > 0) {
                      return `当前还可以创建 ${manager_CanCreateTeam} 个团队`
                    } else {
                      return '当前新建团队的数量已达上限，如需增加团队数量，请致电 400-931-8118'
                    }
                  }
                  // 教师身份
                  if (userInfo?.user_type === 'teacher') {
                    if (teacher_CanCreateTeam > 0) {
                      return `当前还可以创建 ${teacher_CanCreateTeam} 个团队`
                    } else {
                      return JSON.stringify(userInfo?.organization_data) ===
                        '{}'
                        ? '当前新建团队的数量已达上限，如需增加团队数量，请致电 400-931-8118'
                        : '当前新建团队的数量已达上限，如需增加团队数量，请联系校区管理员'
                    }
                  }
                }}
                overlayClassName="teamFloatingTooltip"
              >
                <img
                  src="/image/icon_question.png"
                  alt="question"
                  className="tip"
                />
              </Tooltip>
            )}
          </div>
          {/* <div className="btn">
            {/* <Button
              type="primary"
              className="btn-margin"
              onClick={(e) => {
                if (current === 1) {
                  getAllTeam();
                } else {
                  setCurrent(1);
                }
                setSearchValue("");
                searchRef.current.input.handleReset(e);
                history.push({
                  path: history.location.pathname,
                  search: "",
                });
              }}
            >
              全部
            </Button> 
            <p className="tip">
              <a
                href={`https://${window.location.host}/course/38/0`}
                rel="noopener noreferrer"
                target="_blank"
              >
                不知道团队要怎么用？
              </a>
            </p>
          </div> */}
          <div className="search">
            <Input.Search
              ref={searchRef}
              placeholder="搜索团队名称"
              onSearch={onSearchHandler}
              style={{ width: 280, borderRadius: '4px' }}
            />
          </div>
        </div>
        <div className="table">
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              current: current,
              total,
              showQuickJumper: true,
              defaultPageSize: pageSize,
              onChange: (val) => {
                history.push({
                  path: history.location.pathname,
                  search: qs.stringify({
                    ...qs.parse(history.location.search),
                    page: val,
                  }),
                })
                setCurrent(val)
              },
              onShowSizeChange: (cur, size) => {
                history.push({
                  path: history.location.pathname,
                  search: qs.stringify({
                    ...qs.parse(history.location.search),
                    page: 1,
                  }),
                })
                setCurrent(1)
                setPageSize(size)
              },
              showTotal: (total, range) =>
                `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`,
            }}
            onChange={onChange}
          />
          <div className="pagination_linkCss">
            <a
              href={`https://${window.location.host}/course/38/0`}
              rel="noopener noreferrer"
              target="_blank"
            >
              不知道团队要怎么用？
            </a>
          </div>
        </div>
      </div>
      <Modal
        closeIcon={
          <img src="/image/close.png" alt="close" width="14px" height="14px" />
        }
        className="createTeam"
        visible={showCreateTeam}
        centered={true}
        getContainer={false}
        width={500}
        onOk={() => setShowCreateTeam(false)}
        onCancel={() => {
          history.replace('/manage/team')
          setSubmitErr({ name: null })
          setShowCreateTeam(false)
        }}
        afterClose={() => {
          setNowStep(0)
        }}
        footer={
          <Button
            type="primary"
            disabled={
              (nowStep > 0 && !loginType) || (nowStep === 0 && !formData.name)
            }
            onClick={deBounce(createTeamHandler, 200)}
          >
            {nowStep ? '完成' : '下一步'}
          </Button>
        }
        maskClosable={false}
      >
        <div className="content">
          <Steps
            direction="vertical"
            size="small"
            current={nowStep}
            progressDot={true}
          >
            <Steps.Step title="第一步" description="填写团队信息" />
            <Steps.Step title="第二步" description="团队登录方式" />
            <Steps.Step title="第三步" description="新建团队" />
          </Steps>
          {nowStep === 0 ? (
            <div className="teamInfo">
              <div className="icon">
                <Icon
                  type="team"
                  onLoad={setImageFile}
                  random={true}
                  onChange={setImageFile}
                />
              </div>
              <div>
                <Form
                  ref={formRef}
                  initialValues={formData}
                  layout="vertical"
                  onValuesChange={(value, allValue) => setFormData(allValue)}
                  onFinish={(value) => {
                    // setFormData(value);
                    // setNowStep(nowStep + 1);
                    verifyName(value)
                  }}
                >
                  <Form.Item
                    label={<span>团队名称</span>}
                    colon={false}
                    name="name"
                    rules={[
                      { required: true, message: '请输入团队名称' },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || (value && !/[,|，]/g.test(value))) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            '团队名称不能输入逗号「，」「,」',
                          )
                        },
                      }),
                    ]}
                    {...submitErr.name}
                  >
                    <Input
                      maxLength="15"
                      suffix={
                        <span className="suffix">{`${Math.min(
                          formData.name.length,
                          15,
                        )}/15`}</span>
                      }
                    />
                  </Form.Item>
                  {/* 校区管理员显示授课教师 */}
                  {userInfo?.user_type.indexOf('manager') > -1 ? (
                    <Form.Item
                      label="授课教师"
                      name="team_teacher"
                      required
                      rules={[{ validator: checkTeacher }]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="请选择"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement || document.body
                        }
                        onChange={handleChange}
                      >
                        {employee.map((item) => (
                          <Option value={item.user} key={item.user}>
                            {item.name || item.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null}
                  <Form.Item
                    colon={false}
                    label="一句话描述"
                    name="description"
                    rules={[{ required: false }]}
                  >
                    <Input.TextArea
                      maxLength="100"
                      onChange={(e) => {
                        // if (e.target.value.length > 100) {
                        //     formRef.current.setFieldsValue({description: e.target.value.slice(0, 100)})
                        // }
                      }}
                      style={{ height: '120px', resize: 'none' }}
                    />
                  </Form.Item>
                  <div className="textAreaSuffix">{`${
                    formData.description.length > 100
                      ? 100
                      : formData.description.length
                  }/100`}</div>
                  {
                    // eslint-disable-next-line
                    userInfo.organization_data.oj_platform == 2 && <Form.Item
                      colon={false}
                      label="编程题库："
                      name="oj_platform"
                      className='questionType'
                      rules={[{ required: false }]}
                    >
                      <Radio.Group >
                        <Radio value={2}>开启</Radio>
                        <Radio value={1}>不开启</Radio>
                      </Radio.Group>
                    </Form.Item>
                  }
                  {
                    // eslint-disable-next-line
                    userInfo.organization_data.exam_platform == 2 && <Form.Item
                      colon={false}
                      label="客观题库："
                      name="exam_platform"
                      className='questionType'
                      rules={[{ required: false }]}
                    >
                      <Radio.Group
                        className='questionType'>
                        <Radio value={2}>开启</Radio>
                        <Radio value={1}>不开启</Radio>
                      </Radio.Group>
                    </Form.Item>
                  }
                </Form>
              </div>
            </div>
          ) : (
            <div className="loginType">
              <div className="typeItem">
                <div
                  className={`optional img ${
                    loginType === 'image' ? 'active' : ''
                  }`}
                  onClick={() => setLoginType('image')}
                >
                  {/* <img src="" alt="loginType"/> */}
                </div>
                <div className="tips">图片密钥：更适合低年龄学员</div>
              </div>
              <div className="typeItem">
                <div
                  className={`optional word ${
                    loginType === 'word' ? 'active' : ''
                  }`}
                  onClick={() => setLoginType('word')}
                >
                  dress high
                </div>
                <div className="tips">词组密钥：更适合高年龄学员 </div>
              </div>
              <div className="faq">
                <p>
                  请为您的团队学员选择一种密钥类型，团队中所有新创建的学员都将使用该类密码登录平台。
                </p>
                <p>注意：新建团队成功后将不可更改团队的密钥类型。</p>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default forwardRef(Team)
