import React, { useState, forwardRef, useImperativeHandle } from 'react';
import CommonModal from '../../../../components/commonModal';
import { Select, Button, message } from 'antd';
import { changeTeamTeacher } from '../../../../utils/request';

const ChangeTeacher = (props, ref) => {
  const { teacherList, teamData, getAllMember, match } = props;
  const [changTeacher, setChangeTeacher] = useState(false);
  const [selectTeacher, setSelectTeacher] = useState(null);

  useImperativeHandle(ref, () => ({
    changeTeacherHandler: () => setChangeTeacher(true)
  }));

  const fetchChangeTeacher = () => {
    if (!selectTeacher) {
      message.success('更换授课教师成功');
      setChangeTeacher(false);
    } else {
      if (selectTeacher.length === 0) {
        message.warn('请选择授课教师');
        return;
      } else if (selectTeacher.length > 3) {
        message.warn('最多可选择 3 位授课教师');
        return;
      } else {
        changeTeamTeacher(match.params.id, { team_teacher: selectTeacher, from_type: '管理中心' }).then(res => {
          if (res.status === 200) {
            message.success('更换授课教师成功');
            setChangeTeacher(false);
            getAllMember();
          }
        });
      }
    }
  };

  return (
    <CommonModal
      title="更换授课教师"
      width={374}
      visible={changTeacher}
      onCancel={() => {
        setChangeTeacher(false);
        setSelectTeacher(null);
      }}
      contentNode={
        <div className="changeModal">
          <p>授课教师</p>
          <Select
            mode="multiple"
            allowClear
            showSearch
            style={{ width: '100%' }}
            placeholder="请选择"
            onChange={value => {
              setSelectTeacher(value);
            }}
            defaultValue={teamData.team_teacher.map(element => {
              return element.id;
            })}
            getPopupContainer={triggerNode =>
              triggerNode.parentElement || document.body
            }
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          >
            {teacherList.map(list => (
              <Select.Option
                key={list.user}
                value={list.user}
                label={list.name || list.username}
              >
                {list.name || list.username}
              </Select.Option>
            ))}
          </Select>
        </div>
      }
      bottomNode={
        <>
          <Button
            onClick={() => {
              setChangeTeacher(false);
              setSelectTeacher(null);
            }}
          >
            取消
          </Button>
          <Button type="primary" onClick={fetchChangeTeacher}>
            保存
          </Button>
        </>
      }
    />
  );
};

export default forwardRef(ChangeTeacher);
