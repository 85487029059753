import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  Layout,
  Menu,
  Dropdown,
  Badge,
  message,
  Button,
  Popconfirm
} from 'antd';
import { CaretDownOutlined, CheckOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './style.scss';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from './../../store/actions';
import { withRouter, useLocation } from 'react-router-dom';
import { scratchURL, snapURL, cPlusURL } from '../../utils';
// import { useGuide } from '../../utils/guide';
import FeedBack from './../feedback';
import GuideModal from '../guideModal';
// import FeatureGuide from '../featureGuide';
import qs from 'query-string';
import classNames from 'classnames';
import OfflineNotification from '../offlineNotification';
import { setOfflineNotification_show, getUserCampus, getUser, getUnRead } from '../../store/actions';
import { campusSelect } from '../../utils/request'
import {
  loginAndSetToken
} from './../../store/actions';
const Header = props => {
  const userInfo = useSelector(state => state.userInfo);
  const token = useSelector(state => state.token);
  // const isTeacher = userInfo?.user_type === 'teacher';
  const isTeacher = useSelector(state => state.isTeacher);
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const [isAuthorize, setIsAuthorize] = useState(false);
  const unread = useSelector(state => state.unread);
  const allCampus = useSelector(state => state.allCampus);
  const [openCampus, setOpenCampus] = useState(false)
  const [notJoined, setNotJoined] = useState(false)
  const [curCampusId, setCurCampusId] = useState(false)
  const [showFeedBack, setShowFeedBack] = useState(
    qs.parse(props.location.search).feedback
  );
  const headerRef = useRef();
  const popBoxRef = useRef();

  const dispatch = useDispatch();

  const search = window.location.search;
  const pathname = window.location.pathname;
  const service_validity = useSelector(state => state.service_validity);
  // const isValidityAbled = service_validity === 3 || service_validity === 4;
  const service_before10 = useSelector(state => state.service_before10);
  const service_tip = useSelector(state => state.service_tip);
  const isStudent = userInfo?.user_type === 'student';
  const isNullType = userInfo?.user_type === 'null';
  const isManager =
    (userInfo?.user_type === 'manager' ||
      userInfo?.user_type === 'manager|teacher') &&
    Object.keys(userInfo?.organization_data).length > 0;
  // const {
  //   visible: guideVisible,
  //   setVisible: setGuideVisible,
  //   guideList,
  //   guideTotal,
  //   guide,
  //   setGuide
  // } = useGuide();
  useEffect(()=>{

    if(search.includes('token')){
      let loginConfig = {
        login_type:'token',
        token:search.split('=')[1],
        remember:true
      };

      dispatch(loginAndSetToken(loginConfig)).then(res=>{
        localStorage.setItem('needToCourseDetail',pathname)
        setTimeout(() => {
          localStorage.setItem('needToCourseDetail','')
        }, 1000);
      })
      
    }
    // eslint-disable-next-line
  },[search])
  // 连接websocket
  useEffect(() => {
    if (!(token && userInfo)) {
      if (
        document.domain.indexOf('aimaker.space') > -1 ||
        document.domain.indexOf('longan.link') > -1
      ) {
        window.websocket.webSocketClose('wss://socket.aimaker.space/ws/login/');
      } else {
        window.websocket.webSocketClose(
          'wss://socket.dev.eliteu.xyz/ws/login/'
        );
      }

      // localStorage.removeItem('offshow');
      dispatch(setOfflineNotification_show(false));
      return;
    }
    let wsparams = {
      user_id: userInfo?.id,
      token: token
    };

    if (
      document.domain.indexOf('aimaker.space') > -1 ||
      document.domain.indexOf('longan.link') > -1
    ) {
      window.websocket.initWebSocket('wss://socket.aimaker.space/ws/login/');
    } else {
      window.websocket.initWebSocket('wss://socket.dev.eliteu.xyz/ws/login/');
    }

    if(token) {
      // setTimeout(() => {
        window.websocket.sendSock(wsparams, wsres => {
          if (userInfo.id !== wsres.user_id) return;
          if (wsres.message && wsres.token !== token) {
            dispatch(setOfflineNotification_show(true));
            // localStorage.setItem('offshow', 1);
          }
        }); 
      // }, 1000)
    }
  }, [token, userInfo]); // eslint-disable-line

  // 监听页面切换，指导动画

  useEffect(() => {
    setIsAuthorize(
      location?.pathname.search(/^\/(authorize)|(binding)/) !== -1
    );

    // setGuide(location?.pathname);
  }, [location /*, setGuide*/]);

  useEffect(()=>{
    let timer;
    timer = setInterval(() => {
      if(userInfo){
        dispatch(getUserCampus(userInfo.id));
      }
    }, 30 * 1000);
    return () => {
      timer && clearInterval(timer);
    };
  },[userInfo, dispatch])

  useEffect(() => {
    if (allCampus.length > 0) {
      const selectCampus = allCampus.find(f => f.is_select);
      if (selectCampus) {
        setNotJoined(!isStudent && !isNullType && selectCampus.status !== '1')
      }
      selectCampus && setCurCampusId(selectCampus.id)
    }
  }, [allCampus, userInfo]) // eslint-disable-line

  const handleJump = url => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试');
      return null;
    }
    window.location.href = url;
  };
  const toggleVisible = useCallback(bol => {
    setVisible(bol);
  }, []);
  const confirm = () => {
    message.info('Clicked on Yes.');
  };
  const reloadPage = (pathname) => {
    // if(window.location.pathname.includes(pathname)){
    //   window.location.reload()
    // }else{
      window.location.href = pathname
      if (pathname === '/course') {
        props.history.push({
          search: qs.stringify({
            "page": "1",
            "type": "all",
            "编程平台": "all",
            "课程教具": "all",
            "课程类型": "all",
            "适合阶段": "all"
          })
        });
      }
    // }
  }
  const handleSelect = (item) => {
    console.log('handleSelect??????????')
    if(item.id === curCampusId){
      return
    }
    campusSelect({
      user_id: userInfo?.id,
      campus_id: item.id
    }).then(res => {
      localStorage.setItem('curCampus', item.id)
      // eslint-disable-next-line
      if (item.status == '1') {
        if (item.org_type === "0") { // 非机构和通用
          console.log('非机构和通用--');
          reloadPage('/course');
        } else {
          if (item.user_type === 'teacher') {
            console.log('teacher--');
            reloadPage('/teachplan');
          } else {
            console.log('no-teacher--');
            reloadPage('/course');
          }
        }
      } else {
        dispatch(getUnRead());
        reloadPage('/notification');
      }
      dispatch(getUserCampus(userInfo.id))
      dispatch(getUser());
      setOpenCampus(false)
    })
  }
  const text =
    service_validity === 2
      ? '管理员你好，英荔教学云服务即将到期，到期后部分教学功能将无法使用。请联系英荔销售或致电 400-931-8118。'
      : '管理员你好，英荔教学云服务已到期，如需继续使用请联系英荔销售或致电 400-931-8118。';
  const menu = (
    <Menu>
      <Menu.Item className={notJoined ? 'disable-link' : ''}>
        {
          notJoined ? <span>个人中心</span> : <div onClick={() => handleJump('/account')}>个人中心</div>
        }
      </Menu.Item>
      <Menu.Item className={notJoined ? 'disable-link' : ''}>
        {
          notJoined ? <span>我的管理</span> : <div onClick={() => handleJump('/manage')}>我的管理</div>
        }
        
      </Menu.Item>
      {/* {isTeacher && (
        <Menu.Item>
          //<div onClick={() => handleJump('/resetpassword')}>修改密码</div> 
          <div onClick={() => handleJump('/accountsetting')}>账户设置</div>
        </Menu.Item>
      )} */}
      {isTeacher ? (
        <Menu.Item className={notJoined ? 'disable-link' : ''}>
          {
            notJoined ? <span>账户设置</span> : <div onClick={() => handleJump('/accountsetting')}>账户设置
              {!userInfo?.changed_username && userInfo?.username.startsWith('aim_') && <div className='point'></div>}
            </div>
          }
        </Menu.Item>
      ) : isStudent && JSON.stringify(userInfo?.organization_data) !== '{}' ? (
        <Menu.Item className={notJoined ? 'disable-link' : ''}>
            {
              notJoined ? <span>账户设置</span> : <div onClick={() => handleJump('/studentAccountsetting')}> 账户设置
                {!userInfo?.changed_username && userInfo?.username.startsWith('aim_') && <div className='point'></div>}</div>
            }
        </Menu.Item>
      ) : null}
      <Menu.Item>
        <span
          onClick={() => {
            setShowFeedBack(true);
          }}
        >
          我要反馈
        </span>
      </Menu.Item>
      <Menu.Item>
        <span onClick={() => toggleVisible(true)}>作品社区</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        onClick={() => {
          if (!window.navigator.onLine) {
            message.error('操作失败，请稍后再尝试');
            return null;
          }
          dispatch(logout());
          setNotJoined(false)
        }}
      >
        <span>退出登录</span>
      </Menu.Item>
    </Menu>
  );
  const campusMenu = (
    <Menu className='campus_list'>
      {allCampus.map(el => (
        <Menu.Item key={el.id} onClick={()=>{handleSelect(el)}}>
          <div>
            <div className={`flex-b-sbc campus_item ${el.is_select?'select':''}`}>
                <div className='flex-b-sbc'>
                  <img src={el.image_mark} alt=''/>
                  <div className='single-ellipsis w_180'> {el.name} </div>
                </div>
                <div>
                  {el.is_select&&<CheckOutlined style={{color:'#1990FF',fontSize:'12px',fontWeight:600}}/>}
                  {
                    el.unread_message>0 && !el.is_select && <div className='unread_message'> {el.unread_message>99?'99+':el.unread_message} </div>
                  }
                </div>
            </div>
          </div>
        </Menu.Item>
      ))}
      
    </Menu>
  )
  const creationPlatformMenu = (
    <Menu>
      <Menu.Item>
        <a
          href={scratchURL + '/projects/editor/'}
          style={{'textAlign':'center'}}
          target="_blank"
          rel="noopener noreferrer"
        >
          互联创作平台
        </a>
      </Menu.Item>
      {userInfo && userInfo.organization_data && userInfo.organization_data.snap_platform === 2 && <Menu.Item>
        <a
          href={snapURL + '/snap.html'}
          style={{'textAlign':'center'}}
          target="_blank"
          rel="noopener noreferrer"
        >
          Snap 互联创作平台
        </a>
      </Menu.Item>}
      <Menu.Item>
        <a
          href={'https://blocks.aimaker.space/get-started/'}
          style={{'textAlign':'center'}}
          target="_blank"
          rel="noopener noreferrer"
        >
          硬件编程平台
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          href={'https://app.aimaker.space/'}
          target="_blank"
          rel="noopener noreferrer"
          style={{'textAlign':'center'}}
        >
          AI 应用平台
        </a>
        {/* <div onClick={() => handleJump(scratchURL + '/projects/editor/')}>
          互联创作平台
        </div> */}
      </Menu.Item>
      <Menu.Item>
        <a 
          href={cPlusURL}
          target="_blank"
          rel="noopener noreferrer"
          style={{'textAlign':'center'}}
        >
        C++ 平台
        </a>
      </Menu.Item>
    </Menu>
  );
  
  const notJoinedMenu = (
    <Menu>
      <Menu.Item>
        <a
          href={'https://app.aimaker.space/'}
          target="_blank"
          rel="noopener noreferrer"
          style={{'textAlign':'center'}}
        >
          AI 应用平台
        </a>
        {/* <div onClick={() => handleJump(scratchURL + '/projects/editor/')}>
          互联创作平台
        </div> */}
      </Menu.Item>
    </Menu>
  );
  const isPreview =
    location.pathname.includes('/coursePreview') ||
    location.pathname.includes('/projectPreview');

  return (
    <Layout.Header>
      <GuideModal
        visible={visible}
        onCancel={() => toggleVisible(false)}
        onOk={() => toggleVisible(true)}
        isTeacher={isTeacher}
      />
      {/* Todo: 操作指导 */}
      {/* <FeatureGuide
        visible={guideVisible}
        list={guideList}
        total={guideTotal}
        guide={guide}
        onCancel={() => setGuideVisible(false)}
      /> */}
      <div
        id="header"
        className="container"
        ref={headerRef}
        data-testid="test-header"
      >
        <div className="left">
          <div className="logo">
            {isPreview || notJoined ? (
              <img src="/image/logo@2x.png" alt="longan" />
            ) : (
              <Link
                  to={isStudent ? '/account/home' : userInfo?.user_type === 'teacher' && userInfo.organization_data.org_type !== "0" ? '/teachplan' : '/course'}
              >
                <img src="/image/logo@2x.png" alt="longan" />
              </Link>
            )}
          </div>
          {isPreview ? null : (
            <>
              {userInfo && !isAuthorize ? (
                <div className="scratch">
                  {/* {isStudent ? null : (
                    <>
                      {!isTeacher ? (
                        <NavLink exact to="/">
                          功能介绍
                        </NavLink>
                      ) : null}
                    </>
                  )} */}
                  {notJoined  ? <span className='disable-link'>教学课程</span> : <Link to="/course">教学课程</Link>}
                  {(isTeacher || isNullType) ? (notJoined ? <span className='disable-link'>教学计划</span> : <Link to="/teachplan">教学计划</Link>) : null}
                  <Dropdown
                    overlayClassName="platformMenuDropdown"
                    overlay={notJoined ? notJoinedMenu : creationPlatformMenu}
                    placement="bottomCenter"
                    getPopupContainer={() => document.getElementById('header')}
                  >
                    {/* <a
                      // href={scratchURL + '/projects/editor/'}
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      创作平台
                    </a> */}
                    <span style={{ cursor: 'pointer' }}>创作平台</span>
                  </Dropdown>
                  
                  <Link to="/downloadsoftware">软件下载</Link>
                  {isStudent && <a href="https://competition.aimaker.space/" target="_blank" rel="noopener noreferrer">
                    赛事活动
                  </a>}
                  {/* <a href={scratchURL} target="_blank" rel="noopener noreferrer">
                作品社区
              </a> */}
                  {/* {!isTeacher && (
                    <>
                      <NavLink to="/project">探索项目</NavLink>
                      <NavLink to="/course">教学课程</NavLink>
                    </>
                  )} */}
                </div>
              ) : null}
            </>
          )}
        </div>
        {isPreview ? null : (
          <>
            {isAuthorize ? null : (
              <div className={`user ${userInfo ? 'login' : ''}`}>
                {userInfo ? (
                  <>
                    {/* 操作指引 */}
                    {/* <div onClick={() => setGuideVisible(true)}>操作指引</div> */}
                    <div className="message">
                      <Badge
                        count={unread}
                        className={`${unread > 9 ? 'more' : ''}`}
                      >
                        <Link to="/notification">
                          <img src="/image/icon-message.png" alt="message" />
                        </Link>
                      </Badge>
                    </div>
                    {
                      allCampus.length > 1 && <Dropdown
                      getPopupContainer={() => headerRef.current}
                      overlayClassName="campus_dropdown"
                      overlay={campusMenu}
                      placement="bottomRight"
                      trigger={['click']}
                      onVisibleChange={()=>{
                        setOpenCampus(!openCampus)
                      }}
                      visible={(allCampus.length > 1 && !allCampus.some(el => el.is_select)) || openCampus}
                    >
                        <div className="campus">
                          {
                            allCampus.length > 1 && !allCampus.some(el => el.is_select) && <div className='mask'></div>
                          }
                          {/* campus.length>1&&!campus.some(el=>el.is_select)&& */}
                          <Badge
                            dot={allCampus.some(s => s.unread_message > 0)}
                            offset={[-12, 5]}
                          >
                            {openCampus ? <img src="/image/icon-campus.png" alt="message" /> : <img src="/image/campus_not_active.png" alt="message" />}
                          </Badge>
                          {allCampus.some(el => el.is_select) && <div className={['cumpusName', 'single-ellipsis', openCampus ? 'active' : ''].join(' ')}>{allCampus.find(f => f.is_select).name}</div>}
                        </div>
                    </Dropdown>
                    }
                    <Dropdown
                      getPopupContainer={() => headerRef.current}
                      overlayClassName="headerDropdown"
                      overlay={menu}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <span className="user single-ellipsis">
                        <span
                          className="avatar"
                          style={{
                            backgroundImage: `url(${userInfo.avatar_url})`
                          }}
                        ></span>
                        <span style={{ marginTop:'2px'}}>
                        {userInfo.student_name ||
                          userInfo.name ||
                          userInfo.username}{' '}
                        </span>
                        <CaretDownOutlined />
                      </span>
                    </Dropdown>
                    {isManager && !notJoined &&(
                      <div
                        className="msgbox"
                        // style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Link
                          to="/manageCenter"
                          style={{
                            paddingLeft: 20
                          }}
                        >
                          <Button
                            type="primary"
                            className={classNames(
                              service_validity === 1
                                ? 'manageCenter_btn'
                                : service_validity === 2
                                ? 'manageCenter_btn_orange'
                                : 'manageCenter_btn_red'
                            )}
                            style={{ position: 'relative' }}
                          >
                            管理中心
                          </Button>
                        </Link>
                        {/*  弹窗 */}

                        <div className="popBox" ref={popBoxRef}></div>

                        {popBoxRef.current &&
                          service_tip &&
                          (service_validity === 4 || service_before10) && (
                            <Popconfirm
                              okText={null}
                              cancelText={null}
                              placement="bottomRight"
                              title={text}
                              visible={true}
                              onConfirm={confirm}
                              disabled={true}
                              overlayClassName={classNames(
                                'emptyBox',
                                service_validity === 4
                                  ? 'emptyBoxR'
                                  : 'emptyBoxY'
                              )}
                              getPopupContainer={() => popBoxRef.current}
                            ></Popconfirm>
                          )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {/* <Link className="action" to="/register">
                注册
              </Link> */}
                    {/* <Divider type="vertical" /> */}
                    <Link
                      className="action"
                      to={{
                        pathname: '/login',
                        state: { prevPath: props.location.pathname }
                      }}
                    >
                      登录
                    </Link>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <OfflineNotification />
      <FeedBack
        show={showFeedBack}
        requestclose={() => {
          props.history.replace(props.location);
          setShowFeedBack(false);
        }}
      />
    </Layout.Header>
  );
};

export default withRouter(Header);
