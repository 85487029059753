import React, { useState, useEffect, useCallback, useReducer, useRef } from 'react'
import { useSelector } from 'react-redux'
import * as echarts from 'echarts'
import { Button, Input, message, Modal, Select, Spin, DatePicker  } from 'antd'
import moment from 'moment'
import qs from 'query-string';
import DownLoadBar from '../../../../components/downloadBar'
import SubHeader from '../../../../components/subHeader'
import Icon from '../../../../components/icon'
import {
  getTeamDetail,
  getTeamBaseData,
  getTeamLearnTime,
  getTeamProjectsNum,
  getTeamProjectsRank,
  patchTeamDetail,
  changeTeamTeacher,
  getTeachersAll,
  editTeamDescr,
} from './../../../../utils/request'
import { secretImageUrl, deBounce, scratchURL, getRealDateRange, disabledDate  } from './../../../../utils'


import './style.scss'

const { TextArea } = Input
const { Option } = Select
const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD'

const formatDate = (date) => moment(date).format('YYYYMMDD')

const types = {
  EDIT_TEAM_DESCR: 'EDIT_TEAM_DESCR',
  ON_DESCR_CHANGE: 'ON_DESCR_CHANGE',
}

const initialState = {
  idEdit: false,
  teamDescr: '',
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.EDIT_TEAM_DESCR:
      return { ...state, isEdit: !state.isEdit }
    case types.ON_DESCR_CHANGE:
      return { ...state, teamDescr: action.text }
    default:
      return state
  }
}

const DataCenter = ({ history, match, ...props }) => {
  const userInfo = useSelector((state) => state.userInfo)
  const teamId = qs.parse(history.location.search).id
  const isManagerCenterStr = qs.parse(history.location.search)?.isManagerCenter
  const isManagerCenter = isManagerCenterStr ? JSON.parse(isManagerCenterStr) : false
  const [state, dispatch] = useReducer(reducer, initialState)

  const [changeTeachersModal, setChangeTeachersModal] = useState(false)

  const [selectedValue, setSelectedValue] = useState([]) // 已保存的授课教师
  const [changeTeacherValue, setChangeTeacherValue] = useState(selectedValue) // 未保存的授课教师
  // const studentsFlag = useSelector((state) => state.studentsFlag); // 学员数是否达到上限
  const [teamDetail, setTeamDetail] = useState()
  const [teamData, setTeamData] = useState({})
  const [learnTimeData, setLearnTimeData] = useState([])
  const [projectsNumData, setProjectsNumData] = useState({ date_list: [] })
  const [projectsRankData, setProjectsRankData] = useState([])
  const [sort, setSort] = useState('views')
  const [timeInterval, setTimeInterval] = useState('last7days')
  const [dateRange, setDateRange] = useState(getRealDateRange(timeInterval))
  const [employee, setEmployee] = useState([])
  const [loading, setLoading] = useState({
    team: false,
    time: false,
    nums: false,
    rank: false,
  })

  const orgOpenDate = userInfo.organization_data?.open_date
  const orgStartDate = userInfo.organization_data?.start_date

  const learnTimeChart = useRef(null)
  const projectsRankChart = useRef(null)
  const projectsNumChart = useRef(null)

  const patchTeamInfo = useCallback(
    (config) => {
      return patchTeamDetail(match.params.code, config)
    },
    [match.params.code],
  )
  const handleTeachersList = async () => {
    setChangeTeachersModal(true)
    let res = await getTeachersAll()
    setEmployee(res?.data)
  }

  const initPageDetail = useCallback(async () => {
    let { data } = await getTeamDetail(match.params.code, isManagerCenter ? '管理中心' : null)
    if (data) {
      setTeamDetail({
        ...data,
        cover: secretImageUrl + data.cover.replace('covers/', ''),
      })
      let ids = data?.team_teacher?.map((element) => {
        return element.id
      })
      setSelectedValue(ids)
      setChangeTeacherValue(ids)
      dispatch({ type: types.ON_DESCR_CHANGE, text: data.description || '' })
    } else {
      message.info('未找到该团队')
    }
  }, [match.params.code, isManagerCenter])

  const initTeamData = useCallback(async () => {
    setLoading((prev) => ({ ...prev, team: true, nums: true, time: true }))
    if (teamId) {
      getTeamBaseData(teamId).then((res) => {
        setTeamData(res.data)
        setLoading((prev) => ({ ...prev, team: false }))
      })
      getTeamLearnTime(teamId).then((res) => {
        const maxVal = Math.max(...res.data.map((item) => item.total))
        const data = res.data.map((item) => ({
          ...item,
          width: 800 * (item.total / maxVal),
        }))
        setLearnTimeData(data)
        setLoading((prev) => ({ ...prev, time: false }))
      })
      getTeamProjectsNum(teamId).then((res) => {
        setProjectsNumData(res.data)
        setLoading((prev) => ({ ...prev, nums: false }))
      })
    }
  }, [teamId])

  useEffect(() => {
    if (teamId) {
      setLoading((prev) => ({ ...prev, rank: true }))
      getTeamProjectsRank(teamId, {
        start_date: dateRange.start,
        end_date: dateRange.end,
      }).then((res) => {
        setProjectsRankData(res.data)
        setLoading((prev) => ({ ...prev, rank: false }))
      })
    }
  }, [teamId, dateRange, userInfo])
  // 初始化数据
  useEffect(() => {
    initPageDetail()
  }, [match.params.code]) // eslint-disable-line

  useEffect(() => {
    initTeamData()
  }, [history.location.state?.id]) // eslint-disable-line

  const editTeamIcon = useCallback(
    (file) => {
      let ids = teamDetail?.team_teacher?.map((item) => {
        return item.id
      })
      return patchTeamInfo({ cover: file, team_teacher: ids, ...(isManagerCenter ? { from_type: '管理中心' } : null) })
        .then((res) => {
          setTeamDetail(res.data)
        })
        .catch((err) => console.log(err))

      // === before the change
      // patchTeamInfo({ cover: file })
      //   .then(res => {
      //     setTeamDetail(res.data);
      //   })
      //   .catch(err => console.log(err));
    },
    [patchTeamInfo, teamDetail, isManagerCenter],
  )
  const handleTeachers = () => {
    if (!changeTeacherValue.length) {
      message.warn('请选择授课教师')
      return
    }
    if (changeTeacherValue && changeTeacherValue.length > 3) {
      message.warn('最多可选择 3 位授课教师')
      return
    }
    changeTeamTeacher(match.params.code, { team_teacher: changeTeacherValue, ...(isManagerCenter ? { from_type: '管理中心' } : null) }).then((res) => {
      if (res.status === 200) {
        message.success('更换授课教师成功')
        initPageDetail()
      }
    })

    setChangeTeachersModal(false)
  }

  const handleEditTeamDescr = useCallback(() => {
    if (!window.navigator.onLine) {
      message.error('操作失败，请稍后再尝试')
      return
    }
    dispatch({ type: types.EDIT_TEAM_DESCR })
    if (state.isEdit) {
      let ids = teamDetail.team_teacher.map((item) => {
        return item.id
      })
      editTeamDescr(match.params.code, {
        description: state.teamDescr,
        team_teacher: ids,
        ...(isManagerCenter ? { from_type: '管理中心' } : null) 
      }).then((res) => {
        if (res.status === 200) {
          message.success('保存成功')
        }
      })
    }
  }, [match.params.code, state.isEdit, state.teamDescr, teamDetail, isManagerCenter])

  const setLearnTimeChart = useCallback(() => {
    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter:
          '<span style="display:inline-block; width: 10px; height: 10px; background-color: #0084ff; border-radius: 50%; margin: 0 6px 0 0"></span>总看课时长: {c0} h',
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      color: ['#0084ff'],
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: function (value, index) {
            return value + ' h'
          },
        },
        // show: false,
        // boundaryGap: [0, 0.01],
      },
      xAxis: {
        type: 'category',
        data: learnTimeData.map((item) => item.course_title),
        axisLabel: {
          interval: 0,
          rotate: 20,
        },
      },
      series: [
        {
          type: 'bar',
          data: learnTimeData.map((item) => (item.total / 3600).toFixed(1)),
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 19,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!learnTimeChart.current) {
      learnTimeChart.current = echarts.init(
        document.getElementById('learnTimeChart'),
      )
    }
    if (learnTimeChart.current && learnTimeData.length) {
      learnTimeChart.current.on('click', (params) => {
        const course = learnTimeData[params.dataIndex]
        window.open(`/course/${course.course}/${course.unit_id}/`)
      })
    }
    learnTimeChart.current.setOption(option, true)
    learnTimeChart.current.resize()
  }, [learnTimeData])

  useEffect(() => {
    if (learnTimeData.length) {
      setLearnTimeChart()
    }
  }, [learnTimeData, setLearnTimeChart])

  const setProjectNumsChart = useCallback(() => {
    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter:
          '{b}<br/><span style="display:inline-block; width: 10px; height: 10px; background-color: #0084ff; border-radius: 50%; margin: 0 6px 0 0"></span>每日提交作品数: {c0}',
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      color: ['#0084ff'],
      yAxis: {
        splitNumber: 5,
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
        max: function (value) {
          return Math.max(5, Math.ceil(value.max / 5) * 5)
        },
      },
      xAxis: {
        type: 'category',
        data: projectsNumData?.date_list?.map((date) =>
          moment(date).format('YYYY/MM/DD'),
        ),
        /**
         * 1.开通日期大于服务日期的前提下，数据首次开通时间≤团队创建时间，从数据首次开通时间开始算起。例如首次开通日期8月2日，团队创建时间8月1日。数据从8月2日开始算起。
         * 2.开通日期大于服务日期的前提下，团队创建时间＜数据开通时间，数据从团队创建时间开始算起。例如首次开通日期8月1日，团队创建时间8月2日。数据从8月2日开始算起。
         * 3.开通日期小于服务日期的前提下，旧团队从数据首次开通日期开始算起，新团队从团队创建时间开始算起。
         */
         axisLabel: {
          interval: 0,
          textStyle: {
            color: function (value, index) {
              return (formatDate(orgOpenDate) >= formatDate(orgStartDate) &&
                formatDate(orgOpenDate) >= formatDate(teamData?.created_at) &&
                formatDate(value) >= formatDate(orgOpenDate)) ||
                (formatDate(orgOpenDate) >= formatDate(orgStartDate) &&
                  formatDate(orgOpenDate) < formatDate(teamData?.created_at) &&
                  formatDate(value) >= formatDate(teamData?.created_at)) ||
                (formatDate(orgOpenDate) < formatDate(orgStartDate) &&
                  formatDate(teamData?.created_at) >= formatDate(orgOpenDate) &&
                  formatDate(value) >= formatDate(teamData?.created_at)) ||
                (formatDate(orgOpenDate) < formatDate(orgStartDate) &&
                  formatDate(teamData?.created_at) < formatDate(orgOpenDate) &&
                  formatDate(value) >= formatDate(orgOpenDate))
                ? '#666666'
                : '#cccccc'
            },
          },
          rotate: 60,
        },
      },
      series: [
        {
          type: 'bar',
          data: projectsNumData.result,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: projectsNumData?.date_list.length - 31,
          endValue: projectsNumData?.date_list.length - 1,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!projectsNumChart.current) {
      projectsNumChart.current = echarts.init(
        document.getElementById('projectsNumChart'),
      )
    }
    projectsNumChart.current.setOption(option, true)
    projectsNumChart.current.resize()
  }, [projectsNumData, orgOpenDate, orgStartDate, teamData.created_at])

  useEffect(() => {
    if (projectsNumData?.date_list.length) {
      setTimeout(setProjectNumsChart, 300)
      // setProjectNumsChart()
    }
  }, [projectsNumData, setProjectNumsChart])

  const setProjectsRankChart = useCallback(() => {
    console.log('render')
    const res = projectsRankData.sort((a, b) => b[sort] - a[sort])
    const views = res.map((item) => item.views)
    const loves = res.map((item) => item.loves)
    const shared_views = res.map((item) => item.shared_views)
    const favorites = res.map((item) => item.favorites)
    const option = {
      title: {
        // text: 'World Population'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      toolbox: {
        right: 44,
        top: -5,
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          // saveAsImage: {}
        },
      },
      legend: {
        // show: false
        itemWidth: 12,
        itemHeight: 12,
      },
      color: ['#0084ff', '#0bd295', '#f55d00', '#fec015'],
      grid: {
        // left: '3%',
        // right: '4%',
        // width: '800',
        // bottom: '15%',
        containLabel: true,
      },
      yAxis: {
        splitNumber: 5,
        type: 'value',
        // show: false,
        // boundaryGap: [0, 0.01],
        max: function (value) {
          return Math.max(5, Math.ceil(value.max / 5) * 5)
        },
      },
      xAxis: {
        type: 'category',
        data: res.map((item) => item.title),
        axisLabel: {
          interval: 0,
          rotate: 20,
        },
      },
      series: [
        {
          name: '查看次数',
          type: 'bar',
          data: views,
        },
        {
          name: '分享后被观看次数',
          type: 'bar',
          data: shared_views,
        },
        {
          name: '被点赞数',
          type: 'bar',
          data: loves,
        },
        {
          name: '被收藏数',
          type: 'bar',
          data: favorites,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          startValue: 0,
          endValue: 9,
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          show: true,
          // startValue:res.length - 1,
          // endValue: res.length - 10,
          xAxisIndex: [0, 1],
        },
      ],
    }
    if (!projectsRankChart.current) {
      projectsRankChart.current = echarts.init(
        document.getElementById('projectsRankChart'),
      )
    }
    if (projectsRankChart.current && projectsRankData.length) {
      projectsRankChart.current.on('click', (params) => {
        window.open(
          `${scratchURL}/projects/${projectsRankData[params.dataIndex].id}/`,
        )
      })
    }
    projectsRankChart.current.setOption(option, true)
    projectsRankChart.current.resize()
  }, [projectsRankData, sort, projectsRankChart])

  useEffect(() => {
    if (document.getElementById('projectsRankChart')) {
      // setTimeout(setProjectsRankChart, 500)
      setProjectsRankChart()
    } else if (projectsRankChart.current) {
      projectsRankChart.current.resize()
    }
  }, [sort, teamId, projectsRankData, setProjectsRankChart])

  const onDateRangeChange = (e) => {
    const start = moment(e[0]).format('YYYYMMDD')
    const end = moment(e[1]).format('YYYYMMDD')
    setDateRange({ start, end })
    setTimeInterval('datepicker')
  }

  useEffect(() => {
    if (timeInterval !== 'datepicker') {
      setDateRange(getRealDateRange(timeInterval))
    }
  }, [timeInterval])

  const { isEdit, teamDescr } = state
  return (
    <div id="teamDataCenter">
      {teamDetail ? (
        <>
          <div className="headerContent">
            <div className="container">
            <div className="teamTitle">
              <Icon
                type="team"
                className="image"
                defaultImg={teamDetail.cover}
                random={true}
                onChange={(file) => editTeamIcon(file)}
              />
              <div className="message">
                <div style={{ verticalAlign: 'top' }}>
                  <span className="title">{teamDetail?.name} · 数据报表</span>
                  {userInfo?.user_type.indexOf('manager') !== -1 ||
                  (userInfo?.user_type.indexOf('teacher') !== -1 &&
                    JSON.stringify(userInfo?.organization_data) !== '{}')
                    ? (() => {
                        if (
                          // 校区管理员 | 校区教师，判断 其 is_now_create , false 隐藏授课教师、批量创建按钮和问号
                          (userInfo?.user_type.indexOf('manager') > -1 ||
                            (userInfo?.user_type === 'teacher' &&
                              JSON.stringify(userInfo?.organization_data) !==
                                '{}')) &&
                          !teamDetail.is_now_create
                        ) {
                          return null
                        } else {
                          return (
                            <span className="showTeachers">
                              授课教师：{' '}
                              {teamDetail.team_teacher
                                .map((item) => {
                                  return item.regional_username || item.name || item.username
                                })
                                .join(' | ')}
                              {/* 校区教师不显示更改按钮 */}
                              {userInfo?.user_type === 'teacher' &&
                              JSON.stringify(userInfo?.organization_data) !==
                                '{}' ? null : (
                                <span
                                  className="btn"
                                  onClick={() => {
                                    handleTeachersList()
                                  }}
                                >
                                  (更换)
                                </span>
                              )}
                            </span>
                          )
                        }
                      })()
                    : null}
                </div>
                <div className="editarea">
                  {!!!isEdit ? (
                    <React.Fragment>
                      <div className="textArea">
                        {teamDescr || '请输入团队简介...'}
                        <span className="btn" onClick={handleEditTeamDescr}>
                          (编辑)
                        </span>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TextArea
                        onChange={(e) => {
                          dispatch({
                            type: types.ON_DESCR_CHANGE,
                            text: e.target.value,
                          })
                        }}
                        placeholder="请输入团队简介..."
                        className="textInput"
                        maxLength={100}
                        row={4}
                        value={state.teamDescr}
                      />
                      <span className="textlen">
                        {state.teamDescr.length > 100
                          ? 100
                          : state.teamDescr.length}
                        /100
                      </span>
                      <Button className="saveBtn" onClick={handleEditTeamDescr}>
                        保存
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            </div>
          </div>
          <main className="dataContent">
            <div className="mainItem" style={{ padding: '30px' }}>
              {/* {team} */}
              <div className="mainHeader">
                <p className="time">本校区首次开通校区数据时间：{orgOpenDate} ｜ 团队创建时间：{moment(teamData?.created_at).format('YYYY/MM/DD')}</p>
              </div>
              <Spin spinning={loading.team}>
                <div className="dataList">
                  <div className="dataItem">
                    <img
                      className="icon"
                      alt="day"
                      src="/image/datacenter/data_icon1.png"
                    />
                    <div className="content">
                      <span className="item_title">当前学员</span>
                      <span className="num">{teamData?.num_student}</span>
                    </div>
                  </div>
                  <div className="dataItem">
                    <img
                      className="icon"
                      alt="day"
                      src="/image/datacenter/data_icon2.png"
                    />
                    <div className="content">
                      <span className="item_title">当前课程</span>
                      <span className="num">{teamData?.course_num}</span>
                    </div>
                  </div>
                  <div className="dataItem">
                    <img
                      className="icon"
                      alt="day"
                      src="/image/datacenter/data_icon3.png"
                    />
                    <div className="content">
                      <span className="item_title">作品集</span>
                      <span className="num">{teamData?.num_studios}</span>
                    </div>
                  </div>
                  <div className="dataItem">
                    <img
                      className="icon"
                      alt="day"
                      src="/image/datacenter/data_icon4.png"
                    />
                    <div className="content">
                      <span className="item_title">提交作品</span>
                      <span className="num">{teamData?.project_num}</span>
                    </div>
                  </div>
                </div>
              </Spin>
            </div>
            <div className="mainItem">
            <div className="bottomLine"></div>
            {learnTimeData.length ? (
              <DownLoadBar
                imageId="learnTimeChartBox"
                title="总看课时长"
                style={{ top: '93px' }}
                excelParams={{
                  name: ['课程名称', '看课时长/h'],
                  key: ['name', 'time'],
                  data: learnTimeData.map((item) => ({
                    name: item.course_title,
                    time: (item.total / 3600).toFixed(1),
                  })),
                  filename: `${teamData?.name}团队总看课时长-${moment().format(
                    'YYYY/MM/DD',
                  )}`,
                }}
              />
            ) : null}
            <div id="learnTimeChartBox" className="imageBox">
              <SubHeader
                title="总看课时长"
                smallTitle={
                  Object.is(
                    moment().format('YYYY/MM/DD'),
                    moment(teamData?.created_at).format('YYYY/MM/DD'),
                  )
                    ? null
                    : `（${moment(teamData?.created_at).format(
                        'YYYY/MM/DD',
                      )} ~ ${moment()
                        .subtract(1, 'day')
                        .format('YYYY/MM/DD')}）`
                }
                tips="每日凌晨更新"
                width={100}
                style={{ marginBottom: '30px' }}
              />
              <Spin spinning={loading.time}>
                <div
                  id="learnTimeChart"
                  style={{ height: learnTimeData.length ? '400px' : 0 }}
                ></div>
                {!learnTimeData.length ? (
                  <div className="empty">
                    <img src="/image/empty-page.png" alt="empty" />
                    <p className="text">本团队未关联课程</p>
                  </div>
                ) : null}
              </Spin>
            </div>
            <div className="bottomLine"></div>
          </div>

          <div className="mainItem">
            {!(
              Object.is(
                moment().format('YYYY/MM/DD'),
                moment(teamData?.created_at).format('YYYY/MM/DD'),
              ) ||
              Object.is(
                moment().format('YYYY/MM/DD'),
                moment(orgOpenDate).format('YYYY/MM/DD'),
              )
            ) ? (
              <DownLoadBar
                imageId="projectsNumChartBox"
                title="每日提交作品数"
                style={{ top: '90px' }}
                excelParams={{
                  name: ['日期', '提交作品数量'],
                  key: ['date', 'num'],
                  data: projectsNumData?.date_list?.map((item, idx) => ({
                    date: item,
                    num: projectsNumData?.result[idx],
                  })),
                  filename: `${
                    teamData?.name
                  }团队每日提交作品数-${moment().format('YYYY/MM/DD')}`,
                }}
              />
            ) : null}
            <div id="projectsNumChartBox" className="imageBox">
              <SubHeader
                title="每日提交作品数"
                tips="每日凌晨更新"
                width={100}
                style={{ marginBottom: '30px' }}
              />
              <Spin spinning={loading.nums}>
                <div
                  id="projectsNumChart"
                  style={{
                    height: !(
                      Object.is(
                        moment().format('YYYY/MM/DD'),
                        moment(teamData?.created_at).format('YYYY/MM/DD'),
                      ) ||
                      Object.is(
                        moment().format('YYYY/MM/DD'),
                        moment(orgOpenDate).format('YYYY/MM/DD'),
                      )
                    )
                      ? '400px'
                      : 0,
                  }}
                ></div>
                {Object.is(
                  moment().format('YYYY/MM/DD'),
                  moment(teamData?.created_at).format('YYYY/MM/DD'),
                ) ||
                Object.is(
                  moment().format('YYYY/MM/DD'),
                  moment(orgOpenDate).format('YYYY/MM/DD'),
                ) ? (
                  <div className="empty">
                    <img src="/image/empty-page.png" alt="empty" />
                    <p className="text">暂无数据</p>
                  </div>
                ) : null}
              </Spin>
            </div>
            <div className="bottomLine"></div>
          </div>
          <div className="mainItem lastItem">
            {projectsRankData.length ? (
              <>
                <DownLoadBar
                  imageId="projectsRankChartBox"
                  title="作品排行榜"
                  style={{ top: '150px' }}
                  excelParams={{
                    name: [
                      '作品名',
                      '查看次数',
                      '分享后被观看次数',
                      '被点赞数',
                      '被收藏数',
                    ],
                    key: [
                      'name',
                      'views',
                      'shared_views',
                      'loves',
                      'favorites',
                    ],
                    data: projectsRankData
                      ?.sort((a, b) => b[sort] - a[sort])
                      .map((item) => ({
                        name: item.title,
                        views: item.views,
                        shared_views: item.shared_views,
                        loves: item.loves,
                        favorites: item.favorites,
                      })),
                    filename: `${
                      teamData?.name
                    }团队作品排行榜-${moment().format('YYYY/MM/DD')}`,
                  }}
                />
                <div className="sortSelect">
                  <span className="text">排序：</span>
                  <Select
                    placeholder="请选择"
                    style={{ width: 160 }}
                    onChange={(e) => setSort(e)}
                  >
                    <Option value="views">查看次数</Option>
                    <Option value="shared_views">分享后被观看次数</Option>
                    <Option value="loves">被点赞数</Option>
                    <Option value="favorites">被收藏数</Option>
                  </Select>
                </div>
              </>
            ) : null}
            {moment(orgOpenDate).format('YYYYMMDD') <=
            moment().format('YYYYMMDD') ? (
              <div className="timeSelect">
                <div className="tabs">
                  <div
                    className="text"
                    style={{
                      color:
                        timeInterval === 'last7days' ? '#0084ff' : '#333333',
                    }}
                    onClick={() => setTimeInterval('last7days')}
                  >
                    近7天
                  </div>
                  <div
                    className="text"
                    style={{
                      color:
                        timeInterval === 'last30days' ? '#0084ff' : '#333333',
                    }}
                    onClick={() => setTimeInterval('last30days')}
                  >
                    近30天
                  </div>
                  {moment()
                    .subtract(1, 'weeks')
                    .endOf('week')
                    .format('YYYYMMDD') <
                  moment(orgOpenDate).format('YYYYMMDD') ? (
                    <div className="text disabled">上周</div>
                  ) : (
                    <div
                      className="text"
                      style={{
                        color:
                          timeInterval === 'lastweek' ? '#0084ff' : '#333333',
                      }}
                      onClick={() => setTimeInterval('lastweek')}
                    >
                      上周
                    </div>
                  )}
                  {moment()
                    .subtract(1, 'months')
                    .endOf('month')
                    .format('YYYYMMDD') <
                  moment(orgOpenDate).format('YYYYMMDD') ? (
                    <div className="text disabled">上月</div>
                  ) : (
                    <div
                      className="text"
                      style={{
                        color:
                          timeInterval === 'lastmonth' ? '#0084ff' : '#333333',
                      }}
                      onClick={() => setTimeInterval('lastmonth')}
                    >
                      上月
                    </div>
                  )}
                </div>
                <RangePicker
                  onChange={onDateRangeChange}
                  value={[moment(dateRange.start), moment(dateRange.end)]}
                  format={dateFormat}
                  disabledDate={(current) =>
                    disabledDate(current, orgOpenDate, true)
                  }
                  allowClear={false}
                />
              </div>
            ) : null}
            <div id="projectsRankChartBox" className="imageBox">
              <SubHeader
                title="作品排行榜"
                smallTitle={`（${moment(dateRange.start).format(
                  'YYYY/MM/DD',
                )} ~ ${moment(dateRange.end).format('YYYY/MM/DD')}）`}
                style={{ marginBottom: '87px' }}
              />
              <Spin spinning={loading.rank}>
                <div
                  id="projectsRankChart"
                  style={{ height: projectsRankData.length ? '400px' : 0 }}
                ></div>
                {!projectsRankData.length ? (
                  <div className="empty">
                    <img src="/image/empty-page.png" alt="empty" />
                    <p className="text">学员还没有提交作品</p>
                  </div>
                ) : null}
              </Spin>
            </div>
          </div>
          </main>
          <Modal
            className="createModalCss"
            title="更换授课教师"
            closeIcon={
              <img
                src="/image/close.png"
                alt="close"
                width="14px"
                height="14px"
              />
            }
            centered={true}
            bodyStyle={{ borderRadius: '1em' }}
            destroyOnClose={true}
            maskClosable={false}
            width="374px"
            visible={changeTeachersModal}
            onOk={deBounce(handleTeachers, 500)}
            okText="保存"
            onCancel={() => {
              setChangeTeachersModal(false)
              setChangeTeacherValue(
                teamDetail.team_teacher?.map((element) => {
                  return element.id
                }),
              )
            }}
            getContainer={true}
          >
            <div className="formLabelCss">授课教师</div>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="请选择"
              onChange={(value) => {
                setChangeTeacherValue(value)
              }}
              // value={changeTeacherValue}
              defaultValue={teamDetail.team_teacher.map((element) => {
                return element.id
              })}
              // defaultValue={selectedValue}
            >
              {employee.map((item) => (
                <Option value={item.user} key={item.user}>
                  {item.name || item.username}
                </Option>
              ))}
            </Select>
          </Modal>
        </>
      ) : null}
    </div>
  )
}

export default DataCenter
