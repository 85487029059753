import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef
} from 'react';
import {
  Button,
  Input,
  Form,
  Modal,
  Select,
  InputNumber,
  Transfer,
  message,
} from 'antd';
import '../style.scss';

import { testPhone, deBounce } from './../../../../utils';
import { getUser } from './../../../../store/actions';
import { ExclamationCircleFilled,DownOutlined } from '@ant-design/icons';
import {
  createEmployee,
  getCampusOtherStaff,
  checkUserInfo,
  checkTeamCourse
} from './../../../../utils/api/manageCenter';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const { Option } = Select;
const CreateEmployee = (props, ref) => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfo);
  const isDisabled = userInfo?.organization_data?.max_staff - userInfo?.organization_data?.staff_nums <= 0;
  const orgNum = useSelector(state => state.orgNum);
  const [visible, setVisible] = useState(false);
  const [nowStep, setNowStep] = useState(0);
  const [checkCourseList, setCheckCourseList] = useState([]);
  const [firstFormData, setFirstFormData] = useState({});
  const [secondFormData, setSecondFormData] = useState({});
  const step1FormRef = useRef();
  const step2FormRef = useRef();
  const [showNumber, setShowNumber] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);
  const [targetCourse, setTargetCourse] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [curIdentity, setCurIdentity] = useState('')
  const [expanded, setExpanded] = useState(false); 
  const [allowCourseBag, setAllowCourseBag] = useState(false);
  const [targetCourseBag, setTargetCourseBag] = useState([]);
  const [employeeMode, setEmployeeMode] = useState(undefined);
  const [curEmployee, setCurEmployee] = useState({
    name: '',
    value: null,
    phone:''
  });
  const [otherStaffOption, setOtherStaffOption] = useState([]);
  const [tips, setTips] = useState('请选择当前校区中还不是教师的教职工。');
  const [inputDisable, setInputDisable] = useState(false);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    createEmployeeHandler: () => {
      setEmployeeMode(undefined)
      setCurEmployee({
        name: '',
        value: null,
        phone:''
      })
      setVisible(true)
      let allowCourseBag = userInfo.organization_data.allow_course_bag
      setAllowCourseBag(allowCourseBag)
    }
  }));
  useEffect(() => {
    // 管理员 || 非课程包分配 || 有课程
    setExpanded(curIdentity === 'manager' || !allowCourseBag)
    // eslint-disable-next-line
  }, [curIdentity])

  const handlerClick = useCallback(() => {
    if (nowStep === 0) {
      step1FormRef.current.submit();
    } else if(nowStep === 1) {
      step2FormRef.current.submit();
    } else {
      submitCreate(secondFormData)
    }
    // eslint-disable-next-line
  }, [nowStep, secondFormData]);

  // 下一步
  const fetchNext = (values, step) => {
    if (step === 0) {
      setNowStep(step + 1);
      setTimeout(() => {
        step2FormRef.current.setFieldsValue({
          is_full: "true"
        })
      }, 500);
    } else if (step === 1 && curIdentity !== 'manager') {
      setLoading(true);
      const { courses, team_list } = values
      checkTeamCourse({ courses, team_list }).then(res => {
        setCheckCourseList(res.data.course)
        setNowStep(step + 1);
        setLoading(false);
      })
    }

  };
  useEffect(()=>{
    getCampusOtherStaff().then(res=>{
      res.data.unshift({
        id:'new_staff',
        phone:null,
        regional_username:'',
        username:'邀请新用户',
        disabled: isDisabled
      })
      const options = res.data.map(m=>(
        <Option value={m.id} key={m.id} disabled={m.disabled}>
          {m.regional_username} {m.username} {m.phone}
        </Option>
      ))
      setOtherStaffOption(options)
    })
  },[isDisabled])
  useEffect(() => {
    let filterData = props?.allTeam?.map(item => {
      if (item.team_teacher.length >= 3) {
        item.disabled = true;
      }
      return item;
    });
    setDataSource(filterData);
  }, [props.allTeam]);

  useEffect(() => {
    if (nowStep === 0 && JSON.stringify(firstFormData) !== '{}' && step1FormRef?.current) {
      step1FormRef.current.setFieldsValue(firstFormData)
    }
    if (nowStep === 1 && JSON.stringify(secondFormData) !== '{}' && step2FormRef?.current) {
      step2FormRef.current.setFieldsValue(secondFormData)
    }
  }, [nowStep, firstFormData, secondFormData])


  // 提交至后端
const submitCreate = useCallback((values) => {
  const parmas = { ...firstFormData, ...values };
  parmas.is_full = JSON.parse(parmas.is_full)
  if(curIdentity !=='manager'){
    if (allowCourseBag) {
      parmas.courses = targetCourse;
    } else {
      parmas.courses = [...targetCourse, ...checkCourseList.map(m => m.id)];
    }
    parmas.course_bags = targetCourseBag
  }
  createEmployee(parmas)
      .then(res => {
        setLoading(false);
        handlerClose();
        if(res.status===201){
          props.fetchEmployeeList()
          Modal.warning({
            icon:<ExclamationCircleFilled />,
            title: '提示信息',
            content: res?.data?.message,
            centered: true
          });
        }else{
          message.success('新建员工成功');
          history.replace('/manageCenter');
        }
        dispatch(getUser());
      })
      .catch(err => {
        message.error(
          err.response.data.message ||
            err.response.data.invalid ||
            '新建员工失败'
        );
        setLoading(false);
      });
}, [targetCourseBag, targetCourse, curIdentity, firstFormData, dispatch, props, history, checkCourseList, allowCourseBag])

  // 保存
  const fetchCreate = async (values) => {
    setLoading(true);
    values.courses = targetCourse
    setSecondFormData(values);
    if (curIdentity === 'manager') {
      await submitCreate(values)
    } else {
      fetchNext(values, 1);
    }
  };

  // 关闭弹窗时做的处理
  const handlerClose = () => {
    setVisible(false);
    setNowStep(0);
    setShowNumber(false);
    setFirstFormData({});
    setCheckCourseList([])
    setSecondFormData({});
    setTargetCourse([])
    setTargetKeys([])
    setTargetCourseBag([])
    setInputDisable(false)
    setTips('请选择当前校区中还不是教师的教职工。')
  };

  const filterOption = (inputValue, option) => {
    return option.name.indexOf(inputValue) > -1;
  };

  const handleChange = targetKeys => {
    setTargetKeys(targetKeys);
  };

  const handleSelectCourse = targetKeys => {
    setTargetCourse(targetKeys);
  };
  const handleSelectCourseBag = targetKeys => {
    setTargetCourseBag(targetKeys);
  };
  const textRegExp = text => {
    return /^[A-Za-z0-9_\u4e00-\u9fa5]+$/.test(text);
  };
  const handleSelectStaff = (val, option) => {
    setEmployeeMode(val)
    setCurEmployee({
      name: option.children[0],
      value: option.value,
      phone:option.children[4],
    })
    step1FormRef.current.resetFields(['phone'])
    if (val === 'new_staff') {
      setTips(`请为新用户设置姓名，并填写对方用于登录的手机号。
      </br>若所填手机号还没有教学云账户，则会自动为其创建账户并加入当前校区。`)
      setInputDisable(false)
      step1FormRef.current.setFieldsValue({
        employee:val,
        name:'',
        phone:''
      })
    }else{
      setTips('若现有教职工的个人信息有误，请校区管理员到区域管理平台中修订。')
      setInputDisable(true)
      checkUserInfo({
        phone:option.children[4]
      }).then(res=>{
        setCurIdentity(res.data.type)
      })
      step1FormRef.current.setFieldsValue({
        employee: val,
        name: option.children[0],
        phone: option.children[4]
      })
    }

  }
  const previousStep = () => {
    setNowStep(nowStep-1)
    setExpanded(curIdentity === 'manager' || !allowCourseBag || (allowCourseBag && targetCourse.length > 0))
  }
  return (
    <Modal
      title="新增教师"
      width={1000}
      centered={true}
      getContainer={false}
      closeIcon={
        <img src="/image/close.png" alt="close" width="14px" height="14px" />
      }
      className="createModal"
      destroyOnClose={true}
      visible={visible}
      onCancel={handlerClose}
      maskClosable={false}
      footer={
        <div className='handle_btn'>
        {nowStep!==0 && <Button
          onClick={previousStep}
        >
          上一步
        </Button>}
        <Button
            type="primary"
            onClick={deBounce(handlerClick, 200)}
            loading={loading}
          >
            {
              nowStep === 0 ? '下一步' : (nowStep === 1 ? (curIdentity === 'manager' ? '完成' : '下一步') : '完成')
            }
          </Button>
        </div>
      }
    >
      <div className="content">
        {nowStep === 0 ? (
          <Form
            name="step1"
            layout="vertical"
            ref={step1FormRef}
            onFinish={values => {
              setFirstFormData(values);
              fetchNext(values, 0);
            }}
            autoComplete="off"
          >
            {/* <Form.Item
              label="登录用户名"
              name="username"
              rules={[
                { required: true, message: '请输入登录用户名' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || (value && testUsername(value))) {
                      return Promise.resolve();
                    }
                    return Promise.reject('用户名仅支持数字、英文字母、下划线');
                  }
                })
              ]}
            >
              <Input maxLength="20" placeholder="请输入" />
            </Form.Item> */}
            <Form.Item
              label="选择教职工"
              name="employee"
              rules={[
                { required: true, message: '请选择教职工' },
              ]}
            >
              <Select
                placeholder="请选择"
                showSearch
                defaultValue={employeeMode}
                onChange={handleSelectStaff}
                filterOption={(input, option) => {
                  return option?.children.some(m => m && m !== " " && m.includes(input))
                }}
              >
                {otherStaffOption}
              </Select>
              <div className='tips' dangerouslySetInnerHTML={{ __html: tips }}></div>
            </Form.Item>
            <Form.Item
              label="姓名"
              name="name"
              rules={[
                { required: true, message: '请输入姓名' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || (value && textRegExp(value))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      '姓名仅支持输入中文、英文大小写、下划线、数字'
                    );
                  }
                })
              ]}
            >
              <Input placeholder="请输入姓名" maxLength="20" onBlur={(e) => {
                setCurEmployee({
                  ...curEmployee,
                  name: e.target.value
                })
              }} />
            </Form.Item>
            <Form.Item
              label="手机号"
              name="phone"
              rules={[
                { required: true, message: '请输入手机号' },
                ({ getFieldValue }) => ({
                  async validator(rule, value) {
                    if (!value || (value && testPhone(value))) {
                      if (value && employeeMode === 'new_staff') {
                        const res = await checkUserInfo({ phone: value })
                        setCurIdentity(res.data.type)
                        if (res.data.type === 'teacher') {
                          return Promise.reject('此手机号所绑定账户已是当前校区的教师');
                        } else if (res.data.type === 'staff' || res.data.type === 'manager') {
                          return Promise.reject('此手机号所绑定账户属于当前校区的教职工，请在上方的菜单中选择');
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.resolve();
                      }
                    } else {
                      return Promise.reject('请输入正确的手机号');
                    }
                  }
                })
              ]}
            >
              <Input placeholder="请输入手机号" disabled={inputDisable} />
            </Form.Item>
          </Form>
        ) : (
          nowStep===1?
            <Form
              name="step2"
              layout="vertical"
              ref={step2FormRef}
              autoComplete="off"
              onFinish={values => {
                fetchCreate(values);
              }}
            >
                <div className='next_tips distribution'>
                  {
                    curIdentity === 'manager' && <p>{curEmployee.name}当前已经拥有校区的教学云管理员身份，您正在给他额外赋予教师身份，以便他可以成为某团队的授课教师。
                    </p>
                  }
                  <p>请为{curEmployee.name}分配权限。分配完成后上一步的操作才生效。</p>
                </div>
              <Form.Item
                label="分配使用量"
                name="is_full"
                rules={[{ required: true, message: '请选择分配使用量' }]}
              >
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  onChange={value => {
                    setShowNumber(`${value}` === 'false');
                  }}
                  getPopupContainer={triggerNode =>
                    triggerNode.parentElement || document.body
                  }
                  disabled={curIdentity === 'manager'}
                >
                  <Select.Option value="true" label="和其他教师共享全校区的使用量">
                    和其他教师共享全校区的使用量
                  </Select.Option>
                  <Select.Option value="false" label="分配专属额度">
                    分配专属额度
                  </Select.Option>
                </Select>
              </Form.Item>
                {curIdentity !== 'manager' ? <div className='next_tips'>
                  对于正式编制的教师，可以选择「和其他教师共享全校区的使用量」，这样既能避免浪费，也方便管理。<br></br>对于临时教师，建议选择「分配专属额度」，虽然会导致校区的部分使用量被预先冻结，但可以避免此教师失误用完所有额度而影响全校区的使用。
                </div> : <div className='next_tips'>
                  校区管理员默认和其他教师共享全校区的使用量，无需分配。
                </div>}

              {showNumber ? (
                <div>
                  <Form.Item
                    label={
                      <>
                        可创建学员数量
                        <span className="help">
                          （当前剩余学员数量：{orgNum?.remaining_students}
                          ，请按需分配）
                        </span>
                      </>
                    }
                    name="student_num"
                    rules={[
                      { required: true, message: '请输入学员数量' }
                      // ({ getFieldValue }) => ({
                      //   validator(rule, value) {
                      //     if (
                      //       !value ||
                      //       (value && value <= orgNum?.remaining_students)
                      //     ) {
                      //       return Promise.resolve();
                      //     }
                      //     return Promise.reject('学员数量已超出当前剩余数量');
                      //   }
                      // })
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={orgNum?.remaining_students}
                      placeholder="请输入"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        可创建团队数量
                        <span className="help">
                          （当前剩余团队数量：{orgNum?.remaining_team}
                          ，请按需分配）
                        </span>
                      </>
                    }
                    name="team_num"
                    rules={[
                      { required: true, message: '请输入团队数量' }
                      // ({ getFieldValue }) => ({
                      //   validator(rule, value) {
                      //     if (
                      //       !value ||
                      //       (value && value <= orgNum?.remaining_team)
                      //     ) {
                      //       return Promise.resolve();
                      //     }
                      //     return Promise.reject('团队数量已超出当前剩余数量');
                      //   }
                      // })
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={orgNum?.remaining_team}
                      placeholder="请输入"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        可创建作品集数量
                        <span className="help">
                          （当前剩余作品集数量：{orgNum?.remaining_portfolio}
                          ，请按需分配）
                        </span>
                      </>
                    }
                    name="sample_reels"
                    rules={[
                      { required: true, message: '请输入作品集数量' }
                      // ({ getFieldValue }) => ({
                      //   validator(rule, value) {
                      //     if (
                      //       !value ||
                      //       (value && value <= orgNum?.remaining_portfolio)
                      //     ) {
                      //       return Promise.resolve();
                      //     }
                      //     return Promise.reject('作品集数量已超出当前剩余数量');
                      //   }
                      // })
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={orgNum?.remaining_portfolio}
                      placeholder="请输入"
                    />
                  </Form.Item>
                </div>
              ) : null}
              <Form.Item label="分配团队" name="team_list"  className='courses-form-item'>
                <Transfer
                  dataSource={dataSource}
                  // dataSource={props.allTeam}
                  showSearch
                  filterOption={filterOption}
                  targetKeys={targetKeys}
                  onChange={handleChange}
                  render={item => item.name}
                  rowKey={record => record.id}
                  listStyle={{
                    width: '47.5%',
                    height: '500px'
                  }}
                />
              </Form.Item>
                {
                  userInfo.user_type.includes('manager') && curIdentity !== 'manager' && allowCourseBag && <Form.Item label="给教师关联课程包，并接受上级主管部门直接动态分配课程：" name="courses"  className='courses-form-item'>
                    <>
                      <Transfer
                        titles={['校区全部课程包', '此教师可用课程包']}
                        dataSource={props.allCourseBag}
                        showSearch
                        filterOption={(inputValue, option) => {
                          return option.title.indexOf(inputValue) > -1;
                        }}
                        targetKeys={targetCourseBag}
                        onChange={handleSelectCourseBag}
                        render={item => <span>{item.title}</span>}
                        rowKey={record => record.id}
                        listStyle={{
                          width: '47.5%',
                          height: '500px'
                        }}
                      />
                    </>
                  </Form.Item>
                }
                <Form.Item style={{ height: expanded ? 'auto' : '20px', marginTop: '16px' }} label={allowCourseBag && curIdentity !== 'manager' ? <span onClick={() => setExpanded(true)} style={{ color: expanded ? '#1e1e1e' : '#0084ff', cursor: expanded ? 'auto' : 'pointer' }}>从校区的全部课程权限中，给教师分配固定的课程{expanded ? '：' : <DownOutlined style={{ marginLeft: '5px', fontSize: '14px' }} />}</span> : "分配课程"} name="courses"  className='courses-form-item'>
                  <>
                    {
                      ((allowCourseBag && expanded) || !allowCourseBag) && <>
                        {
                          curIdentity !== 'manager' ?
                            <>
                              <Transfer
                                titles={['校区全部可见课程', '此教师可见课程']}
                                dataSource={props.allCourse}
                                showSearch
                                filterOption={(inputValue, option) => {
                                  return option.title.indexOf(inputValue) > -1;
                                }}
                                targetKeys={targetCourse}
                                onChange={handleSelectCourse}
                                render={item => <span>{item.title}</span>}
                                rowKey={record => record.id}
                                listStyle={{
                                  width: '47.5%',
                                  height: '500px',
                                  marginBottom: 6
                                }}
                              />
                              <div className='tips'>
                                {allowCourseBag ? "给教师分配固定课程一般用于在不方便给当前教师分配一整个课程包的权限时，额外给他分配几节课的权限。" : "分配课程时，建议和此教师管理的团队对应。"}
                              </div>
                            </> : <div className='course_tip'>
                              管理员拥有校区的全部课程，无需分配。
                            </div>
                        }
                      </>
                    }
                  </>
          </Form.Item>
            </Form> :<div className='course_check'>
                {checkCourseList.length === 0 ?
                  <div className='tips pass'>
                    经过校验，你给{curEmployee.name}分配的资源权限符合规范，点击【完成】即给此教职工赋予教师身份。
                  </div> :
                  <div>
                    <p className="flex-tip">
                      {
                        allowCourseBag?<><ExclamationCircleFilled style={{fontSize:'16px',color:'#faad14',marginRight:'7px',marginTop:'3px'}}/><span>提示：你给{curEmployee.name}分配的团队中有正在进行授课的团队，下方呈现的课程清单是你没有给{curEmployee.name}分配使用权限但是团队需要的。</span></>:<p>你给{curEmployee.name}分配的团队已绑定部分课程，其中以下课程是你未给此教师分配的。</p>
                      }
                      </p>
                    <div className='course_list'>
                      {checkCourseList.map(m => <p key={m.id}>{m.title}</p>)}
                    </div>
                    <div className='tips'>
                      {
                        allowCourseBag ? <>
                          为保证教学工作的正常开展，点击【完成】，{curEmployee.name}将会临时获得上述全部课程的使用权限。当将{curEmployee.name}不再是相关团队的授课教师时，由这些临时赋予给他的权限也会被同步收回。<br></br>
                          若你还是不希望{curEmployee.name}能浏览上述课程，请点击【上一步】，调整分配给此教师的团队名单。
                        </> : <>为保证教学工作的正常开展，点击【完成】，则自动给此教师赋予上述课程的浏览权限。<br></br>
                          若不希望此教师浏览上述课程，请点击【上一步】，调整分配给此教师的团队名单。</>
                      }
                    </div>
                  </div>
                }
            </div>
        )}
      </div>
    </Modal>
  );
};

export default forwardRef(CreateEmployee)
