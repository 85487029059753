import React, { useEffect } from 'react';
import { Layout, message, notification } from 'antd';
import moment from 'moment'
import Header from '../components/header';
import Footer from '../components/footer';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getUnRead, /*logout,*/ setToken } from '../store/actions';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { markTopNotification } from '../utils/request'
import Note from '../page/note';

import './index.scss'
const { Content } = Layout;
const AppLayout = props => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.userInfo);
  const token = useSelector(state => state.token);
  const topNotifications = useSelector(state => state.topNotifications);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    message.destroy();
  }, [location.pathname]);
  useEffect(() => {
    // notification.config({
    //   top:100
    // })
    if (Array.isArray(topNotifications) && topNotifications.length > 0) {
      let noticeObj = {}
      topNotifications.forEach(m => {
        if (JSON.stringify(m.top_data) !== "{}") {
          noticeObj[`message_${m.id}`] = document.querySelector(`#message_${m.id}`) || false
          // console.log('noticeObj[m.id]', noticeObj[`message_${m.id}`]);
          // 是否在指定的时间段内
          const { top_end_time, top_start_time } = m.top_data
          const timeToCheck = moment();
          const startTime = moment(top_start_time);
          const endTime = moment(top_end_time);
          // console.log('timeToCheck', timeToCheck);
          // console.log(' m.description', m.description);
          // console.log('startTime',startTime);
          // console.log('endTime',endTime);
          // console.log('res---',timeToCheck.isBetween(startTime, endTime));
          // console.log('--------------------------------------------------------------');

          if (timeToCheck.isBetween(startTime, endTime) && !noticeObj[`message_${m.id}`]) {
            notification['info']({
              message: '系统通知',
              description: <span id={`message_${m.id}`}>{m.description}</span>,
              duration: null,
              className: 'notification',
              // style: {
              //   right: '50%',
              //   marginRight:'-192px'
              // },
              key: m.id,
              onClose: () => {
                markTopNotification(m.id).then(res => {
                  dispatch(getUnRead());
                })
              }
            });
            noticeObj[`message_${m.id}`] = document.querySelector(`#message_${m.id}`)
          }
        }
      })

    } else {
      notification.destroy()
    }
  }, [topNotifications, dispatch])

  // notification.close(key: String)

  message.config({ maxCount: 1 });
  useEffect(() => {
    let timer;
    if (token) {
      dispatch(getUser(token));
    } else {
      timer = setInterval(() => {
        if (Cookies.get('token')) {
          dispatch(setToken(Cookies.get('token')));
        }
      }, 500);
    }
    return () => {
      timer && clearInterval(timer);
    };
  }, [token, dispatch]);

  useEffect(() => {
    let timer;
    if (token) {
      dispatch(getUnRead());
      timer = setInterval(() => {
        if (!token) return;

        if (!window.navigator.onLine && !document.querySelector('#network_error')) {
          notification['error']({
            message: <span id="network_error">网络连接已断开，请检查您的网络设置。</span>,
            description: null,
            duration: null
          });
          return;
        }
        dispatch(getUnRead());
      }, 30 * 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [dispatch, token]);
  useEffect(() => {
    if (window.location.hostname.indexOf('longan.link') !== -1) {
      history.push({
        pathname: `/note`
      });
    }
  }, [history]);

  const _isShowFooter =
    location.pathname.includes('coursePreview') ||
    location.pathname.includes('projectPreview') ||
    location.pathname.includes('successPage')||
    location.pathname.includes('playerPage')||
    location.pathname.includes('coursePPT');

    const _isShowHeader = 
    location.pathname.includes('/successPage')||
    location.pathname.includes('playerPage')||
    location.pathname.includes('coursePPT');


  if (window.location.href.indexOf('note') !== -1) {
    return <Note></Note>;
  } else {
    return (
      <Layout>
        {!_isShowHeader && <Header></Header>}
        <Content>
          {token ? (userInfo ? props.children : null) : props.children}
        </Content>
        {!_isShowFooter && <Footer></Footer>}
      </Layout>
    );
  }
};

export default AppLayout;
