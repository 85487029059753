import {
  login,
  getUserInfo,
  getPersonalInfo,
  getUnreadCount,
  requestLogout,
  getTeachPlanFiles,
  getCampus,
  campusSelect
} from './../utils/request';
import { saveToken, removeToken, normalizeData, dateDiffer } from './../utils';
import Cookies from 'js-cookie';
export const SET_USERINFO = 'SET_USERINFO';
export const SET_USERCAMPUS = 'SET_USERCAMPUS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_ISTEACHER = 'SET_ISTEACHER';
export const SET_UNREADCOUND = 'SET_UNREADCOUND';
export const SET_SUPTEAMCOUNT = 'SET_SUPTEAMCOUNT';
export const FETCH_TEACH_PLAN = 'FETCH_TEACH_PLAN';
export const SET_TEAMCOUNT = 'SET_TEAMCOUNT';
export const SET_PORTFOLIOFLAG = 'SET_PORTFOLIOFLAG';
export const SET_STUDENTSFLAG = 'SET_STUDENTSFLAG';
export const SET_STUDENTNUM = 'SET_STUDENTNUM';
// export const SET_STUDENTCOUNT = "SET_STUDENTCOUNT";

// 我的管理
// 可创建的作品集数
export const SET_MANAGER_CANCREATESTUDIOS = 'SET_MANAGER_CANCREATESTUDIOS';
export const SET_TEACHER_CANCREATESTUDIOS = 'SET_TEACHER_CANCREATESTUDIOS';
// 可创建的团队数
export const SET_MANAGER_CANCREATETEAM = 'SET_MANAGER_CANCREATETEAM';
export const SET_TEACHER_CANCREATETEAM = 'SET_TEACHER_CANCREATETEAM';
// 可创建的学员数
export const SET_MANAGER_CANCREATESTUDENT = 'SET_MANAGER_CANCREATESTUDENT';
export const SET_TEACHER_CANCREATESTUDENT = 'SET_TEACHER_CANCREATESTUDENT';
// ===
export const SET_SERVICE_VALIDITY = 'SET_SERVICE_VALIDITY'; // 服务有效期状态
export const SET_SERVICE_BEFORE10 = 'SET_SERVICE_BEFORE10'; // 是否在服务有效期前10天内
export const SET_SERVICETIP = 'SET_SERVICETIP'; // 是否显示登录弹窗
export const SET_ORGNUM = 'SET_ORGNUM';
export const SET_WEBSOCKET_OPEN = 'SET_WEBSOCKET_OPEN'; // 是否触发websocket打开
export const SET_OFFLINENOTIFICATION_SHOW = 'SET_OFFLINENOTIFICATION_SHOW'; // 下线通知是否显示
export const SET_ALL_CAMPUS = 'SET_ALL_CAMPUS'; // 校区列表

export function setUserInfo(userinfo) {
  return { type: SET_USERINFO, userinfo };
}

export function setUserCampus(campus) {
  return { type: SET_USERCAMPUS, campus };
}

export function setToken(token) {
  return { type: SET_TOKEN, token };
}
export function setServiceTip(boolean) {
  return { type: SET_SERVICETIP, boolean };
}
export function setIsTeacher(boolean) {
  return { type: SET_ISTEACHER, boolean };
}

export function setUnreadCount(data) {
  return { type: SET_UNREADCOUND, data };
}

export function setUserSupTeamCount(count) {
  return { type: SET_SUPTEAMCOUNT, count };
}
export function setStudentNum(count) {
  return { type: SET_STUDENTNUM, count };
}
export function setTeamCount(boolean) {
  return { type: SET_TEAMCOUNT, boolean };
}
// export function setStudentCount(boolean) {
//   return { type: SET_STUDENTCOUNT, boolean };
// }
export function setPortfolioFlag(boolean) {
  return { type: SET_PORTFOLIOFLAG, boolean };
}
// 学员数是否达上限
export function setStudentsFlag(boolean) {
  return { type: SET_STUDENTSFLAG, boolean };
}

let tipTimer;

export function loginAndSetToken(params) {
  return dispatch => {
    return login(params).then(res => {
      if (res.data.token) {
        // 服务有效期弹窗
        dispatch(setServiceTip(true));
        saveToken(res.data.token, params.remember);
        dispatch(setToken(res.data.token));

        if (tipTimer) {
          clearTimeout(tipTimer);
        }

        // 10秒后关闭服务有效期弹窗
        tipTimer = setTimeout(() => {
          dispatch(setServiceTip(false));
        }, 10000);
        // return Promise.resolve(res.data.token); // brfore the change
        return Promise.resolve(res.data);
      }
      return Promise.reject();
    });
  };
}
// 登录页微信扫码成功后
export function loginAndSetTokenByWechat(token) {
  return dispatch => {
    if (token) {
      // 服务有效期弹窗
      dispatch(setServiceTip(true));
      saveToken(token, false);
      dispatch(setToken(token));

      if (tipTimer) {
        clearTimeout(tipTimer);
      }

      // 10秒后关闭服务有效期弹窗
      tipTimer = setTimeout(() => {
        dispatch(setServiceTip(false));
      }, 10000);

      return Promise.resolve('true');
    }
    return Promise.reject();
  };
}

//  我的管理
export function setManager_CanCreateStudios(count) {
  return { type: SET_MANAGER_CANCREATESTUDIOS, count };
}
export function setTeacher_CanCreateStudios(count) {
  return { type: SET_TEACHER_CANCREATESTUDIOS, count };
}
export function setManager_CanCreateTeam(count) {
  return { type: SET_MANAGER_CANCREATETEAM, count };
}
export function setTeacher_CanCreateTeam(count) {
  return { type: SET_TEACHER_CANCREATETEAM, count };
}
export function setManager_CanCreateStudent(count) {
  return { type: SET_MANAGER_CANCREATESTUDENT, count };
}
export function setTeacher_CanCreateStudent(count) {
  return { type: SET_TEACHER_CANCREATESTUDENT, count };
}

export function setOrgNum(orgNum) {
  return { type: SET_ORGNUM, orgNum };
}
// ===
export function setService_validity(category) {
  return { type: SET_SERVICE_VALIDITY, category };
}
export function setService_before10(boolean) {
  return { type: SET_SERVICE_BEFORE10, boolean };
}
export function setWebsocket_open(boolean) {
  return { type: SET_WEBSOCKET_OPEN, boolean };
}
export function setOfflineNotification_show(boolean) {
  return { type: SET_OFFLINENOTIFICATION_SHOW, boolean };
}
export function setAllCampus(allCampus) {
  return { type: SET_ALL_CAMPUS, allCampus };
}


export function getUser(token) {
  let res1,res2;
  return async dispatch => {
    try {
      [res1,res2] = await Promise.all([getUserInfo(),getPersonalInfo()])
      if (token && Cookies.get('token')) {
        saveToken(token);
      }
    } catch (e) {
      // debugger;
      removeToken();
    }

    if (!res1?.data) return Promise.reject();
    await dispatch(getUserCampus(res1.data.id))
    dispatch(
      setUserInfo({
        ...res1.data,
        ...res2.data,
        // avatar_url: res1.data.avatar_url + `?t=${new Date().getTime()}`,
        avatar_url: res1.data.avatar_url
      })
    );
    const _isTeacher = res1.data.user_type === 'teacher' ||
    res1.data.user_type === 'manager' ||
    res1.data.user_type === 'manager|teacher'
    dispatch(
      setIsTeacher(_isTeacher)
    );
    service_validity(res1.data)(dispatch);
    manage_num(res1.data)(dispatch);
    // 校区管理员
    if (Object.keys(res1.data.organization_data).length > 0) {
      const { organization_data } = res1.data;
      const remaining_students =
        organization_data.max_student -
        organization_data.allot_student_nums -
        organization_data.teacher_used_student_nums -
        organization_data.used_student_nums;
      const remaining_team =
        organization_data.max_team -
        organization_data.allot_team_nums -
        organization_data.teacher_used_team_nums -
        organization_data.used_team_nums;
      const remaining_portfolio =
        organization_data.max_team_studios_num -
        organization_data.allot_team_studios_nums -
        organization_data.teacher_team_studios_nums -
        organization_data.used_team_studios_nums;
      dispatch(
        setOrgNum({
          remaining_students,
          remaining_team,
          remaining_portfolio
        })
      );
    }
    return Promise.resolve();
  };
}
export function getUserCampus(user_id) {
  let res;
  return async dispatch => {
    try {
      res = await getCampus(user_id);
      dispatch(setAllCampus(res.data))
      // if(res?.data?.length===0){
        // window.location.replace('/');
      // }
    } catch (e) {
      
    }
    if (!res.data) return Promise.reject();
    if(res.data.length === 1 && !res.data[0].is_select){
      const id = res.data[0].id
      campusSelect({user_id,campus_id:id}).then(res=>{
        localStorage.setItem('curCampus',id)
        dispatch(getUser());
      })
    }
    dispatch(setUserCampus(res.data));
    return Promise.resolve(res.data);
  };
}
// 服务有效期
export function service_validity(userInfo) {
  let nowDate = new Date().toLocaleDateString(); // 格式： 年/月/日
  let differStartDay = dateDiffer(userInfo.courses_server_start, nowDate);
  let differEndDay = dateDiffer(nowDate, userInfo.courses_server_end_date);
  /*
   * 服务有效期内： differStartDay 为0或正数 && differEndDay 正数且> 29，service_validity 为 1
   * 有效期结束前30天内： differStartDay 为0或正数 && differEndDay 为0或正数且< 31，service_validity 为 2
   * 服务有效期前： differStartDay < 0，service_validity 为 3
   * 服务有效期后：differEndDay < 0，service_validity 为 4
   * 即将到期，前10天内： differStartDay 为0或正数 && differEndDay < 10，setService_before10 为true
   */
  let service_validity = 3;
  let tag = false;
  if (
    (differStartDay > 0 || differStartDay === 0) &&
    differEndDay > 0 &&
    differEndDay > 29
  ) {
    service_validity = 1;
  } else if (
    (differStartDay === 0 || differStartDay > 0) &&
    (differEndDay === 0 || differEndDay > 0) &&
    differEndDay < 30
  ) {
    if (differEndDay < 10) {
      tag = true;
    }
    service_validity = 2;
  } else if (differStartDay < 0) {
    service_validity = 3;
  } else if (differEndDay < 0) {
    service_validity = 4;
  }

  return dispatch => {
    dispatch(setService_validity(service_validity));
    dispatch(setService_before10(tag));
  };
}
// 我的管理, 触发可创建的作品集数、团队数的修改
export function manage_num(userInfo) {
  const max_team_studios_num = userInfo?.team_data?.max_team_studios_num;
  const used_team_studios_nums = userInfo?.team_data?.used_team_studios_nums;
  const max_team = userInfo?.team_data?.max_team;
  const used_team_nums = userInfo?.team_data?.used_team_nums;
  const max_team_people = userInfo?.team_data?.max_team_people;
  const used_student_nums = userInfo?.team_data?.used_student_nums;
  return dispatch => {
    dispatch(
      setManager_CanCreateStudios(
        max_team_studios_num -
        used_team_studios_nums 
      )
    );
    dispatch(
      setTeacher_CanCreateStudios(max_team_studios_num - used_team_studios_nums)
    );
    dispatch(
      setManager_CanCreateTeam(max_team - used_team_nums)
    );
    dispatch(setTeacher_CanCreateTeam(max_team - used_team_nums));
    dispatch(
      setManager_CanCreateStudent(
        max_team_people -
        used_student_nums 
      )
    );
    dispatch(setTeacher_CanCreateStudent(max_team_people - used_student_nums));
  };
}
export function getUnRead() {
  return dispatch => {
    return getUnreadCount().then(res => {
      if (!res.data) return Promise.reject();
      const {count,top_notifications} = res.data
      dispatch(setUnreadCount({count,top_notifications}));
      return Promise.resolve();
    });
  };
}

export function logout() {
  return dispatch => {
    return requestLogout().then(res => {
      dispatch(setAllCampus([]));
      dispatch(setUserInfo(null));
      dispatch(setToken(''));
      dispatch(setUnreadCount({ count: 0, top_notifications: [] }));
      removeToken();
      localStorage.setItem('needToCourseDetail', '')
    }).catch(err => {
      console.log('退出登录失败');
    })
  };
}
export const loadTeachPlan = () => {
  const schema = {
    id: 'id',
    name: 'plan'
  };
  return dispatch => {
    getTeachPlanFiles().then(res => {
      if (Object.is(res.status, 200)) {
        const data = res.data.results;
        const { plan, ids } = normalizeData(data, schema);
        dispatch({
          type: FETCH_TEACH_PLAN,
          plan,
          ids
        });
      }
    });
  };
};